<template>
  <div
    class="login-container flex py-20 items-center justify-center w-full max-w-[600px] max-w-[ 70%] px-5 mx-auto"
  >
    <div
      class="rounded-md flex flex-col justify-center items-center pb-14 px-10 pt-5 bg-white w-full"
    >
      <div
        class="h idden flex flex-col w-full items-center justify-center p-4 relative z-[1]"
      >
        <img
          src="@/assets/flohh-logo.svg"
          class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
        />
      </div>
      <Divider />
      <template v-if="!isLoading">
        <div v-if="!studentLoginView" class="max-w-[500px] mx-auto">
          <div
            v-if="isAuth && isStudentJoined"
            class="pt-2 text-center flex flex-col"
          >
            <p
              class="text-flohh-text-title color-flohh-neutral-20 font-bold mb-1"
            >
              Looks like you're already a member of this&nbsp;class
            </p>
            <label
              class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
              >You can go to your dashboard by clicking the button&nbsp;below
            </label>
            <AppButton
              type="submit"
              class="mt-4 max-w-[200px] mx-auto"
              @click="
              (e: Event) => {
                e.preventDefault();
                $router.push('/student/dashboard');
              }
            "
            >
              Go To My Dashboard
            </AppButton>
          </div>
          <div v-else>
            <template v-if="!studentLoginView">
              <div class="pt-2 pb-6 text-center max-w-2xl">
                <p class="font-bold text-[18px] text-center text-[#333]">
                  Your teacher has shared an assignment with you
                </p>
              </div>

              <section class="pb-8 w-full">
                <div
                  class="p-5 border border-solid border-flohh-neutral-85 rounded-lg mt-5 bg-[#F2F2F2] flex flex-col"
                >
                  <div class="flex justify-between w-full gap-2">
                    <p
                      class="text-flohh-text-body text-flohh-neutral-35 w-[30%]"
                    >
                      Teacher
                    </p>
                    <p
                      class="text-flohh-text-body font-flohh-font-bold text-flohh-neutral-35 w-[70%] text-right"
                    >
                      {{ classDetails?.teacher }}
                    </p>
                  </div>
                  <Divider />
                  <div class="flex justify-between w-full gap-2">
                    <p
                      class="text-flohh-text-body text-flohh-neutral-35 w-[30%]"
                    >
                      Class Name
                    </p>
                    <p
                      class="text-flohh-text-body font-flohh-font-bold text-flohh-neutral-35 w-[70%] text-right"
                    >
                      {{ classDetails?.class }}
                    </p>
                  </div>
                  <Divider />
                  <div class="flex justify-between w-full" gap-2>
                    <p
                      class="text-flohh-text-body text-flohh-neutral-35 w-[30%]"
                    >
                      Assignment
                    </p>
                    <p
                      class="text-flohh-text-body font-flohh-font-bold text-flohh-neutral-35 w-[70%] text-right"
                    >
                      {{ classDetails?.assignment }}
                    </p>
                  </div>
                </div>
              </section>

              <section
                class="w-full flex items-center justify-center flex-col gap -5"
                v-if="!isAuth && inviteCode"
              >
                <AppButton
                  @click="handleGoogleAuth"
                  type="transparent"
                  class="flex w-full !gap-4 text-flohh-text-body font-flohh-font-medium leading-[1.2] text-center !justify-start text-[#333] h-10 mb-4"
                >
                  <i><img src="@/assets/icon-google.svg" /></i>
                  Continue with Google
                </AppButton>
                <AppButton
                  @click="handleMicrosoftAuth"
                  type="transparent"
                  class="flex w-full !gap-4 text-flohh-text-body font-flohh-font-medium leading-[1.2] text-center !justify-start text-[#333] h-10"
                >
                  <i><img src="@/assets/icon-microsoft.svg" /></i>
                  Continue with Microsoft
                </AppButton>

                <Divider align="center" type="solid">
                  <span>or</span>
                </Divider>
                <AppButton
                  type="transparent"
                  @click="handleView"
                  class="flex w-full !gap-4 text-flohh-text-body font-flohh-font-medium leading-[1.2] text-center !justify-start text-[#333] h-10 mb-4"
                >
                  <i><img src="@/assets/icon-email.svg" /></i>
                  Continue with Email
                </AppButton>
              </section>
              <!-- <template v-if="isAuth && inviteCode">
                <div class="w-full flex justify-center">
                  <AppButton
                    type="submit"
                    v-bind="$attrs"
                    @click="handleClickJoin(inviteCode)"
                  >
                    <template #icon_left>
                      <span v-html="icons.checkBlack" class="pr-2"></span>
                    </template>
                    Join Now
                  </AppButton>
                </div>
              </template> -->
            </template>
            <template v-if="studentLoginView">
              <div class="max-w-[400px] mx-auto w-full">
                <div class="pt-2 pb-6 text-center max-w-2xl">
                  <p class="font-bold text-[18px] text-center text-[#333]">
                    Login with email
                  </p>
                </div>
                <div>
                  <form
                    @submit.prevent="handleStudentLogin"
                    autocomplete="off"
                    class="w-full pb-3"
                  >
                    <div
                      class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
                    >
                      <p
                        class="font-flohh-font-medium text-flohh-text-body color-flohh-neutral-20 mb-1"
                      >
                        Email
                      </p>
                      <FormTextInput
                        type="email"
                        v-model="studentEmail"
                        name="email"
                        placeholder="Enter Email"
                      />
                    </div>

                    <div class="flex justify-end gap-2 mt-[100px]">
                      <AppButton
                        type="transparent"
                        @click="(e: Event) => {
                  e.preventDefault()
                  studentLoginView = false;                  
                }"
                      >
                        <span v-html="icons.arrowBackBlack" class="mr-2"></span>
                        Go Back
                      </AppButton>
                      <AppButton
                        type="submit"
                        v-bind="$attrs"
                        :loading="isLoading"
                      >
                        <template #icon_left>
                          <span v-html="icons.checkBlack" class="pr-2"></span>
                        </template>
                        {{ isLoading ? "Loading" : "Submit" }}
                      </AppButton>
                    </div>
                  </form>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="max-w-[400px] mx-auto w-full" v-else>
          <div class="pt-2 pb-6 text-center max-w-2xl">
            <p class="font-bold text-[18px] text-center text-[#333]">
              Login with email
            </p>
          </div>
          <div>
            <form
              @submit.prevent="handleStudentLogin"
              autocomplete="off"
              class="w-full pb-3"
            >
              <div
                class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
              >
                <p
                  class="font-flohh-font-medium text-flohh-text-body color-flohh-neutral-20 mb-1"
                >
                  Email
                </p>
                <FormTextInput
                  type="email"
                  v-model="studentEmail"
                  name="email"
                  placeholder="Enter Email"
                />
              </div>
              <div
                class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
              >
                <p
                  class="font-flohh-font-medium text-flohh-text-body color-flohh-neutral-20 mb-1"
                >
                  Password
                </p>
                <FormTextInput
                  type="password"
                  v-model="studentPassword"
                  name="password"
                  placeholder="Enter Password"
                />
              </div>

              <div class="flex justify-end gap-2 mt-[100px]">
                <AppButton
                  type="transparent"
                  @click="(e: Event) => {
            e.preventDefault()
            studentLoginView = false;                  
          }"
                >
                  <span v-html="icons.arrowBackBlack" class="mr-2"></span>
                  Go Back
                </AppButton>
                <AppButton type="submit" v-bind="$attrs" :loading="isLoading">
                  <template #icon_left>
                    <span v-html="icons.checkBlack" class="pr-2"></span>
                  </template>
                  {{ isLoading ? "Loading" : "Submit" }}
                </AppButton>
              </div>
            </form>
          </div>
        </div>
      </template>

      <div v-if="isLoading" class="flex justify-center items-center">
        <ProgressLoader />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import ModalCardComponent from "@/components/Modal/ModalCardComponent.vue";
import Divider from "primevue/divider";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import axios, { AxiosResponse } from "axios";
import { icons as AppIcons } from "@/utils/icons";
import { environment } from "@/environments/environment";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import AssignmentService from "@/services/AssignmentService";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
interface IClass {
  class: string;
  teacher: string;
  uuid: string;
  assignmentUuid: string;
  assignment: string;
  inviteCode?: string;
}

@Component({
  components: {
    ModalCardComponent,
    AppButton,
    Divider,
    FormTextInput,
    ProgressLoader,
  },
})
export default class StudentShareAssignmentView extends Vue {
  //
  private assignmentService = new AssignmentService();
  isTrue = true;
  classDetails!: IClass;
  inviteCode = "";
  tokenCode = "";

  isLoading = true;
  isAuth = false;
  studentUuid = null;
  isStudentJoined = false;
  icons = AppIcons;
  studentInfo = {
    name: "",
    email: "",
    status: "",
  };

  studentEmail = "";
  studentPassword = "";

  studentLoginView = false;

  alreadyExist = false;

  BASE_URL = environment.apiEndpoint;
  REDIRECT_BASE_URL = "";
  googleCallbackUrl: string | null = "";
  microsoftCallbackUrl: string | null = "";

  // classDetails: any = {
  //   uuid: "",
  //   name: "",
  //   teacher: "",
  //   assignment: "",
  //   assignmentUuid: "",
  // };

  async handleClickJoin(inviteCode: string) {
    try {
      const response = await axios.post(`student/class/invite/${inviteCode}`);
      if (response.data.ok) {
        //
      }
    } catch (error: any) {
      console.error(error);
      if (error.response.data?.details?.info === "Student already in class") {
        this.isStudentJoined = true;
      }
      //
    } finally {
      //
    }

    // this.$router.push({
    //   path: "/student/dashboard",
    // });
  }

  handleSignInRedirect() {
    // if (this.inviteCode) {
    //   this.$router.push({
    //     path: "/student/login",
    //     query: { code: this.inviteCode },
    //   });
    // }
  }

  async mounted() {
    await this.handleShareCode();
    const userDetails = localStorage.getItem("auth");
    if (userDetails) {
      this.isAuth = true;
      await this.handleClickJoin(this.inviteCode);
    }

    this.isLoading = false;
    this.REDIRECT_BASE_URL = `${this.BASE_URL}/authentication/social`;
  }

  async handleShareCode() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const shareCode = urlParams.get("share-code");

      if (shareCode) {
        const res = await this.assignmentService.getAssignmentShareCode(
          shareCode
        );
        if (res.data.ok) {
          const data = res.data.data.data;

          const classYearLevelText =
            data.classYearLevel === "--" ? "" : data.classYearLevel;
          const classSubjectText =
            data.classSubject === "--" ? "" : data.classSubject;
          const subjectText =
            classYearLevelText && classSubjectText
              ? `${data.classYearLevel} - ${data.classSubject}`
              : "";
          this.classDetails = {
            class: `${data.className} ${subjectText}`,
            teacher: data.teacherName,
            assignment: data.assignmentTitle,
            assignmentUuid: data.assignmentUuid,
            uuid: data.classUuid,
            inviteCode: data.classInviteCode,
          };
          this.inviteCode = data.classInviteCode;
          const CALLBACK_BASE_URL = `${window.location.protocol}//${window.location.host}/authenticate`;
          this.googleCallbackUrl = `${CALLBACK_BASE_URL}/google?authenticatedAs=student`;
          this.microsoftCallbackUrl = `${CALLBACK_BASE_URL}/microsoft?authenticatedAs=student`;
          //
        }
      }
    } catch (e) {
      //
    } finally {
      // this.isLoading = false;
    }
  }

  handleGoogleAuth() {
    window.location.href = `${this.REDIRECT_BASE_URL}/google?authenticatingAs=student&inviteCode=${this.classDetails.inviteCode}&successRedirectUrl=${this.googleCallbackUrl}&failedRedirectUrl=${this.googleCallbackUrl}`;
  }

  handleMicrosoftAuth() {
    window.location.href = `${this.REDIRECT_BASE_URL}/microsoft?authenticatingAs=student&inviteCode=${this.classDetails.inviteCode}&successRedirectUrl=${this.microsoftCallbackUrl}&failedRedirectUrl=${this.microsoftCallbackUrl}`;
  }

  async getClassDetails() {
    let endpoint = "";
    if (this.inviteCode) {
      endpoint = `class/invite-code/${this.inviteCode}`;
    } else {
      endpoint = `users/activate?activationToken=${this.tokenCode}&role=student`;
    }

    try {
      //
      const response = await axios.get(endpoint);
      let className = "";
      let teacherName = "";
      if (response.data.ok) {
        if (this.inviteCode) {
          if (
            this.studentUuid &&
            response.data.data.studentUuids.includes(this.studentUuid)
          ) {
            this.isStudentJoined = true;
          }
          const { code, teacher } = response.data.data;
          teacherName = teacher
            ? teacher.profile.firstName && teacher.profile.lastName
              ? `${teacher.profile.firstName} ${teacher.profile.lastName}`
              : ""
            : "";
          className = code;
        } else {
          if (response.data.data.status === "active") {
            this.$router.push({
              path: "/student/login",
            });
            return;
          }
          const data = response.data.data;
          className = data.class.code;
          teacherName =
            data.class.teacher.profile.firstName +
            " " +
            data.class.teacher.profile.lastName;
        }

        // this.classDetails = {
        //   class: className,
        //   teacher: teacherName,
        // };
      }
      this.isLoading = false;
    } catch (e) {
      //
      this.isLoading = false;
    } finally {
      //
      this.isLoading = false;
    }
  }

  handleView() {
    if (this.classDetails.inviteCode) {
      this.$router.push({
        path: "/student/login",
        query: this.classDetails.inviteCode
          ? { "share-code": this.classDetails.inviteCode }
          : undefined,
      });
    }

    // if (this.tokenCode) {
    //   this.$router.push({
    //     path: "/student/registration",
    //     query: this.tokenCode ? { token: this.tokenCode } : undefined,
    //   });
    // }
  }

  handleStudentLogin() {
    //
  }

  handleStudentLoginView() {
    //
    this.studentLoginView = true;
  }
}
</script>

<style scoped lang="scss"></style>
