<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-facing-decorator";
import { icons as AppIcons } from "@/utils/icons";
import { Navigation } from "@/utils/navigations";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import NavigationClassList from "@/components/Classes/navigation/NavigationClassList.vue";
import emitter from "@/config/emitter";
import AppTypographyText from "../Typhography/AppTypographyText.vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import TeacherService from "@/services/TeacherService";

interface ICredit {
  available: number | string;
  total: number;
  used: number;
}
@Component({
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    NavigationClassList,
    AppTypographyText,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
})
export default class SidePanelMenus extends Vue {
  teacherService = new TeacherService();
  @Prop({
    type: Array,
    required: true,
  })
  navigation!: Navigation[];

  @Prop({
    type: Array,
    required: true,
  })
  roles!: string[];

  @Prop({
    type: Boolean,
    required: true,
  })
  expanded!: boolean;

  icons = AppIcons;

  selectedNavigation!: string;
  visible = false;
  navigateTo = "#";
  classNavOpen = false;
  eventBus = emitter;

  navItemHovered = "";
  selectedNavItem = "";

  isTeacher = true;
  showCredits = false;
  credits: ICredit = {
    available: 0,
    total: 0,
    used: 0,
  };

  handleClickDropdown(navigation: string) {
    this.visible = !this.visible;
    this.selectedNavigation = navigation;
  }

  @Watch("$route")
  routerWatcher(value: any) {
    if (value) {
      this.selectedNavigation = value.name;
      this.visible = !this.visible;
    }
  }

  handleSelected(name: string) {
    this.handleCloseAllSubMenu();
    this.selectedNavItem = name;
  }

  redirectToVersionPage() {
    this.$router.push({
      name: "Versioning",
    });
  }

  handleNavigate(
    routeName: string,
    param: Record<string, any>,
    query: Record<string, any>
  ) {
    this.handleTelemetryEvent(routeName);
    this.handleSelected(routeName);
    this.$router.push({
      name: routeName,
      params: param,
      query: query,
    });
  }

  handleTelemetryEvent(routeName: string) {
    if (routeName === "Dashboard") {
      this.eventBus.emit("EVENT_TRIGGER", "G013");
    }
  }

  handleCloseAllSubMenu() {
    const subMenu = document.querySelectorAll(".hidden-sub-menu");
    subMenu.forEach((item: Element) => {
      item.classList.remove("expanded");
    });

    const subMenuExpanded = document.querySelectorAll(".animate-height");
    subMenuExpanded.forEach((item: Element) => {
      item.classList.remove("expanded");
    });
  }

  handleClassSelect(routeName: string) {
    this.handleSelected(routeName);
    this.navItemHovered = "";
    this.handleCloseAllSubMenu();
    this.removeClickListener();
  }

  handleHoverNavItem(e: Event, index: number, name: string) {
    const parent = e.target as HTMLElement;
    if (parent) {
      let subNav = document.getElementById(`child-${index}`);
      if (subNav) {
        this.navItemHovered = name;
        subNav.classList.add("expanded");
      }
    }
  }

  handleUnhoverNavItem(e: Event, index: number) {
    const parent = e.target as HTMLElement;
    if (parent) {
      let subNav = document.getElementById(`child-${index}`);
      if (subNav) {
        this.navItemHovered = "";
        subNav.classList.remove("expanded");
      }
    }
  }

  handleToggleNavItems(e: Event, index: number, name: string) {
    const parent = e.target;
    if (parent) {
      let subNav = document.getElementById(`child-${index}`);
      if (subNav) {
        if (subNav.classList.contains("expanded")) {
          this.navItemHovered = "";
          subNav.classList.remove("expanded");
        } else {
          this.handleCloseAllSubMenu();

          this.navItemHovered = name;
          subNav.classList.add("expanded");
          this.addClickListener();
        }
      }
    }
  }

  redirectToHelpPage() {
    this.eventBus.emit("EVENT_TRIGGER", "G014");
    this.$router.push({ name: "HelpCenter" });
  }

  openCredit() {
    //

    this.eventBus.emit("ACCOUNT_CREDIT_MODAL", true);
  }

  redirectToAppInfoPage() {
    this.eventBus.emit("EVENT_TRIGGER", "G015");
    this.$router.push({ name: "Versioning" });
  }

  async mounted() {
    const authenticatedAs = localStorage.getItem("authenticatedAs");
    this.isTeacher = authenticatedAs === "teacher";
    if (this.isTeacher) {
      await this.getTeacherCredits();

      this.eventBus.on("REFRESH_CREDITS", async () => {
        await this.getTeacherCredits();
      });
    }

    this.addClickListener();
  }

  async getTeacherCredits() {
    try {
      const res = await this.teacherService.getCredits();
      console.log("getTeacherCredits");
      console.log(res);
      if (res.data.ok && res.data.data) {
        //
        this.credits = res.data.data.credits;
        this.showCredits = res.data.data.subscription.plan.slug === "free";
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleClickOutside(event: any) {
    const subMenu = document.querySelector(".hidden-sub-menu");
    if (subMenu) {
      if (
        !subMenu.contains(event.target) &&
        !event.target.closest("#menu-item")
      ) {
        this.navItemHovered = "";
        const subMenu = document.querySelectorAll(".hidden-sub-menu");
        subMenu.forEach((item: Element) => {
          item.classList.remove("expanded");
        });
        this.removeClickListener();
      }
    }
  }

  addClickListener() {
    document.addEventListener("click", this.handleClickOutside);
  }

  removeClickListener() {
    document.removeEventListener("click", this.handleClickOutside);
  }
}
</script>
<template>
  <nav v-if="expanded" class="px-0 pt-[16px] h-full">
    <ul
      role="list"
      class="flex flex-1 flex-col gap-[2px] w-full relative mb-[32px]"
    >
      <li v-for="(navItem, i) in navigation" :key="i" class="w-full relative">
        <div
          v-if="!navItem.isHidden"
          :class="[
            navItem.path === $route.path ||
            navItemHovered === navItem.name ||
            selectedNavItem === navItem.name
              ? 'bg-flohh-primary-pink'
              : 'text-gray-700 hover:text-gray-600 hover:bg-flohh-primary-pink',
            'cursor-pointer w-full group flex gap-x-3 items-center rounded-md py-[6px] px-[12px] font-flohh-font-medium text-flohh-text-body',
          ]"
          @click="handleNavigate(navItem.route, {}, {})"
          @mouseenter="handleHoverNavItem($event, i, navItem.name)"
          @mouseleave="handleUnhoverNavItem($event, i)"
        >
          <span
            v-html="navItem.icon"
            aria-hidden="true"
            class="[&>svg]:w-[24px] [&>svg]:h-[auto]"
          />
          {{ navItem.name }}
          <span
            v-if="
              (navItem.children && navItem.children.length !== 0) ||
              navItem.name === 'Classes'
            "
            v-html="icons.dropdownIconBlack"
            aria-hidden="true"
            class="ml-auto"
          />
        </div>
        <!-- Sub navigation -->
        <div
          v-if="navItem.name === 'Classes'"
          class="bg-white animate-height absolute rounded-bl-lg rounded-br-lg w-full"
          :id="`child-${i}`"
          @mouseenter="handleHoverNavItem($event, i, navItem.name)"
          @mouseleave="handleUnhoverNavItem($event, i)"
        >
          <div
            class="p-[8px] border border-flohh-neutral-85 border-solid rounded-lg"
          >
            <NavigationClassList @onClassSelect="handleClassSelect" />
          </div>
        </div>
        <div
          v-else-if="navItem.children.length > 0"
          class="bg-white animate-height absolute rounded-bl-lg rounded-br-lg w-full"
          :id="`child-${i}`"
          @mouseenter="handleHoverNavItem($event, i, navItem.name)"
          @mouseleave="handleUnhoverNavItem($event, i)"
        >
          <div
            class="flex flex-col gap-1 p-[8px] border border-flohh-neutral-85 border-solid rounded-lg"
          >
            <router-link
              :to="subNav.path"
              :class="[
                subNav.path === $route.path
                  ? 'bg-flohh-primary-pink rounded-[8px]'
                  : 'text-black-700 hover:text-black-600 hover:bg-flohh-primary-pink rounded-[8px]',
                'cursor-pointer group flex gap-x-3 justify-start px-[10px] py-[10px] items-center text-sm font-flohh-font-medium text-flohh-text-caption',
              ]"
              v-for="(subNav, index) in navItem.children"
              :key="index"
              @click="handleSelected(subNav.parent)"
            >
              {{ subNav.name }}
            </router-link>
          </div>
        </div>
      </li>

      <li
        class="w-full relative overflow-hidden"
        v-if="showCredits && isTeacher"
      >
        <div
          :class="[
            'text-gray-700 hover:text-gray-600 hover:bg-flohh-primary-pink',
            'text-nowrap cursor-pointer w-full group flex gap-x-3 items-center rounded-md py-[6px] px-[12px] font-flohh-font-medium text-flohh-text-body capitalize',
          ]"
          @click="openCredit"
          v-tooltip="{
            value: `${
              credits.available === 'unlimited'
                ? 'Unlimited'
                : credits.available
            } Credits Remaining`,
            autoHide: false,
          }"
        >
          <span v-html="icons.iconCoin" aria-hidden="true" />
          {{ credits.available }} Credits Remaining
        </div>
      </li>
    </ul>

    <ul role="list" class="flex flex-1 flex-col gap-[2px] w-full relative">
      <li class="w-full relative overflow-hidden">
        <div
          :class="[
            $route.path === '/help'
              ? 'bg-flohh-primary-pink'
              : 'text-gray-700 hover:text-gray-600 hover:bg-flohh-primary-pink',
            'cursor-pointer w-full group flex items-center gap-[15px] rounded-md py-[6px] px-[14px] font-flohh-font-medium text-flohh-text-body whitespace-nowrap min-h-[37px]',
          ]"
          @click="redirectToHelpPage"
          v-tooltip="{
            value: 'Help Center',
            autoHide: false,
          }"
        >
          <span v-html="icons.navHelpBlack" aria-hidden="true" />
          Help Center
        </div>
      </li>
      <li class="w-full relative overflow-hidden">
        <div
          :class="[
            $route.path === '/app/version'
              ? 'bg-flohh-primary-pink'
              : 'text-gray-700 hover:text-gray-600 hover:bg-flohh-primary-pink',
            'cursor-pointer w-full group flex items-center gap-[15px] rounded-md py-[6px] px-[14px] font-flohh-font-medium text-flohh-text-body whitespace-nowrap min-h-[37px]',
          ]"
          @click="redirectToAppInfoPage"
          v-tooltip="{
            value: 'About App',
            autoHide: false,
          }"
        >
          <span v-html="icons.navInfoCircleBlack" />About App
        </div>
      </li>
    </ul>
  </nav>

  <nav v-else class="flex flex-col items-center gap-[32px] px-0 pt-[16px]">
    <ul role="list" class="flex flex-1 flex-col gap-[2px] w-full relative">
      <!-- <div id="sub-menu-overlay" class="relative"></div> -->
      <li v-for="(navItem, i) in navigation" :key="i" class="w-full relative">
        <div
          v-if="!navItem.isHidden"
          :class="[
            navItem.path === $route.path ||
            navItemHovered === navItem.name ||
            selectedNavItem === navItem.name
              ? 'bg-flohh-primary-pink'
              : 'hover:bg-flohh-primary-pink',
            'cursor-pointer w-full group flex justify-start py-[6px] px-[12px] rounded-lg',
          ]"
          @click="
            ($event) => {
              if (navItem.name === 'Classes' || navItem.children.length > 0) {
                handleToggleNavItems($event, i, navItem.name);
                return;
              }
              handleNavigate(navItem.route, {}, {});
            }
          "
          id="menu-item"
          v-tooltip="{
            value: navItem.name,
            autoHide: false,
          }"
        >
          <span
            v-html="navItem.icon"
            aria-hidden="true"
            class="[&>svg]:w-[24px] [&>svg]:h-[auto]"
          />
        </div>

        <!-- Sub navigation -->
        <div
          v-if="navItem.name === 'Classes'"
          class="flex flex-col gap-1 p-2 bg-white w-[200px] h-auto fixed ml-[50px] border border-flohh-neutral-85 border-solid rounded-lg hidden-sub-menu"
          :id="`child-${i}`"
        >
          <NavigationClassList
            v-if="navItemHovered === navItem.name"
            @onClassSelect="handleClassSelect"
          />
        </div>

        <div
          v-else-if="navItem.children.length > 0"
          class="flex flex-col gap-2 p-2 bg-white w-[200px] h-auto fixed ml-[50px] border border-flohh-neutral-85 border-solid rounded-lg hidden-sub-menu"
          :id="`child-${i}`"
        >
          <router-link
            :to="subNav.path"
            :class="[
              subNav.path === $route.path
                ? 'bg-flohh-primary-pink rounded-[8px]'
                : 'text-black-700 hover:text-white hover:bg-flohh-primary-pink',
              'cursor-pointer group flex gap-x-3 justify-start p-[10px] items-center text-sm font-flohh-font-medium text-flohh-text-caption rounded-lg',
            ]"
            v-for="(subNav, index) in navItem.children"
            :key="index"
            @click="handleSelected(subNav.parent)"
          >
            {{ subNav.name }}
          </router-link>
        </div>
      </li>

      <li class="w-full relative" v-if="showCredits && isTeacher">
        <div
          :class="[
            'cursor-pointer w-full group flex justify-start py-[6px] px-[12px] rounded-lg capitalize hover:bg-flohh-primary-pink min-h-[37px]',
          ]"
          @click="openCredit"
          v-tooltip="{
            value: `${
              credits.available === 'unlimited'
                ? 'Unlimited'
                : credits.available
            } Credits Remaining`,
            autoHide: false,
          }"
        >
          <span v-html="icons.iconCoin" aria-hidden="true" />
        </div>
      </li>
    </ul>
    <ul class="flex flex-1 flex-col gap-[2px] w-full">
      <li class="w-full relative">
        <div
          :class="[
            $route.path === '/help' ? 'bg-flohh-primary-pink' : '',
            'cursor-pointer hover:bg-flohh-primary-pink flex rounded-lg justify-start items-center py-[6px] px-[14px] p-0 min-h-[37px]',
          ]"
          @click="redirectToHelpPage"
          v-tooltip="{
            value: 'Help Center',
            autoHide: false,
          }"
        >
          <span v-html="icons.navHelpBlack" aria-hidden="true" />
        </div>
      </li>
      <li class="w-full relative">
        <div
          :class="[
            $route.path === '/app/version' ? 'bg-flohh-primary-pink' : '',
            'cursor-pointer hover:bg-flohh-primary-pink flex rounded-lg justify-start items-center py-[6px] px-[14px] p-0 min-h-[37px]',
          ]"
          @click="redirectToAppInfoPage"
          v-tooltip="{
            value: 'About App',
            autoHide: false,
          }"
        >
          <span v-html="icons.navInfoCircleBlack" />
        </div>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.app-info {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 3px;
  padding-left: 11px;
  p {
    font-size: 10px;
  }
}
.animate-height {
  max-height: 0;
  transition: max-height 0.4s ease-out;
  overflow: hidden;
  z-index: 2;
  border: none;
  &.expanded {
    max-height: 400px;
    z-index: 50;
  }
}

.hidden-sub-menu {
  display: none;

  &.expanded {
    display: flex;
  }
}

.no-bottom-radius {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
</style>
