<script lang="ts">
import { Vue, Component, Model, Prop } from "vue-facing-decorator";
import { States } from "@/utils/coutries/type";
import Dropdown from "primevue/dropdown";

@Component({ components: { Dropdown } })
export default class SelectState extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Model({
    type: String,
    required: true,
  })
  value!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  required!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  @Prop({
    type: Array,
    required: true,
  })
  states!: States[];
}
</script>
<template>
  <Dropdown
    v-model="value"
    :options="states"
    optionLabel="name"
    optionValue="name"
    :filter="true"
    :virtualScrollerOptions="{ itemSize: 46 }"
    :placeholder="states.length ? 'Select a State' : 'Select a country first'"
    filterPlaceholder="Search state"
    :class="['w-full', { 'p-invalid': invalid && required }]"
  />
</template>
