<script lang="ts">
import { Vue, Prop, Model, Component, Watch } from "vue-facing-decorator";
import FormInputDropdown from "../Layout/Forms/FormInputDropdown.vue";
import DashboardService from "@/services/DashboardService";
import { AssignmentDashboard } from "@/store/dashboard/dashboardTypes";
import emitter from "@/config/emitter";
import { setTeacherDashboard } from "@/store/dashboard/dashboard-dispatch";

@Component({ components: { FormInputDropdown } })
export default class SelectClass extends Vue {
  eventBus = emitter;
  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  publishedOnly!: boolean;

  @Prop({
    type: Function,
    required: false,
  })
  refreshList!: (callback?: () => void) => void;

  @Model({
    type: String,
    default: "",
    required: true,
  })
  value!: string;

  @Prop({
    type: String,
    default: "",
    required: false,
  })
  assignmentLabel!: string;

  @Prop({
    type: String,
    default: "",
    required: false,
  })
  classUuid!: string;

  @Prop({
    type: String,
    default: "",
    required: false,
  })
  iconLeft!: string;

  @Prop({
    type: String,
    default: "Select an assignment",
    required: false,
  })
  placeholder!: string;

  @Prop({
    type: String,
    default: "",
    required: false,
  })
  defaultValue!: string;

  private dashboardService = new DashboardService();

  emptyAssignment = {
    class: {
      code: "",
      numberOfStudents: 0,
      subject: "",
      uuid: "",
    },
    submissionCounts: {
      completedSubmissions: 0,
      markedSubmissions: 0,
      totalSubmissions: 0,
      learningGoalsRequested: 0,
      learningGoalsToReview: 0,
    },
    title: "",
    uuid: "",
    assignmentStatus: "",
    dueDate: "",
    elapsedTime: "",
    timeRemaining: "",
    visible: true,
  };

  assignments: AssignmentDashboard[] = [];
  assignmentsClone: AssignmentDashboard[] = [];
  loadingAssignments = true;

  async onFetchAssigments() {
    try {
      this.loadingAssignments = true;

      await this.$store.dispatch(setTeacherDashboard);
      const data = structuredClone(
        this.$store.getters["dashboard/getTeacherAssignments"]
      );
      let filteredData;
      if (this.publishedOnly) {
        filteredData = data.filter(
          (o: AssignmentDashboard) => o.visible === true
        );
      } else {
        filteredData = data;
      }

      this.assignments = filteredData;
      this.assignmentsClone = filteredData;
      if (this.assignmentLabel) {
        this.insertCreateAssignmentOption(filteredData);
      } else {
        this.removeCreateAssignmentOption();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingAssignments = false;
      this.setSelectedAssignments(
        this.classUuid ? this.classUuid : this.defaultValue
      );
    }
  }

  @Watch("assignmentLabel")
  assignmentLabelWatcher(value: string) {
    if (!this.loadingAssignments) {
      if (value) {
        this.insertCreateAssignmentOption(this.assignments);
      } else {
        this.removeCreateAssignmentOption();
      }
    }
  }

  @Watch("classUuid")
  classUuidWatcher(value: string) {
    if (!this.loadingAssignments) {
      this.setSelectedAssignments(value);
      // this.value = "create";
    }
  }

  setSelectedAssignments(value: string) {
    if (value === "create") {
      this.assignmentsClone = this.assignments;
    } else {
      const assignmentsList = this.assignments;
      const selectedClassAssignments = assignmentsList.filter(
        (item: AssignmentDashboard) =>
          item.class.uuid === value || !item.class.uuid
      );
      this.assignmentsClone = selectedClassAssignments;
    }
  }

  insertCreateAssignmentOption(assignmentsList: AssignmentDashboard[]) {
    const allAssignment = assignmentsList;
    this.emptyAssignment.title = this.assignmentLabel;
    this.emptyAssignment.uuid = "create";
    allAssignment.unshift(this.emptyAssignment);
    this.assignments = allAssignment;
    this.assignmentsClone = allAssignment;
  }

  removeCreateAssignmentOption() {
    const assignments = this.assignments.filter(
      (item: AssignmentDashboard) => item.uuid !== "create"
    );
    this.assignments = assignments;
    this.assignmentsClone = assignments;
  }

  @Watch("value")
  valueWatcher(value: string) {
    const assignmentsList = this.assignments;
    const selectedAssignment = assignmentsList.find(
      (item: AssignmentDashboard) => value === item.uuid
    );
    if (selectedAssignment) {
      this.$emit("onAssignmentSelect", selectedAssignment);
    } else {
      this.$emit("onAssignmentSelect", this.emptyAssignment);
    }
  }

  async mounted() {
    await this.onFetchAssigments();
    this.value = this.defaultValue;

    this.eventBus.on(
      "REFRESH_ASSIGNMENTS_DROPDOWN",
      async (assignmentUuid: any) => {
        await this.onFetchAssigments();
        if (assignmentUuid) {
          this.value = assignmentUuid;
        }
      }
    );
  }
}
</script>
<template>
  <FormInputDropdown
    v-model="value"
    :filter="true"
    :disabled="disabled"
    :options="assignmentsClone"
    optionLabel="title"
    optionValue="uuid"
    :loading="loadingAssignments"
    :placeholder="loadingAssignments ? 'Loading assignments...' : placeholder"
    :background="false"
    :invalid="invalid"
    :iconLeft="iconLeft"
  />
</template>
