import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-5" }
const _hoisted_2 = { class: "flex gap-5" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "p-7" }
const _hoisted_5 = { class: "text-flohh-text-body" }
const _hoisted_6 = { class: "pt-5 border-t border-solid border-flohh-neutral-85 flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_ModalUtility = _resolveComponent("ModalUtility")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AppTypographyText, {
        label: "Download and print class coversheets and worksheets for automatic splitting",
        variant: "bd",
        type: "title",
        class: "mb-5"
      }),
      (!_ctx.hasStudents)
        ? (_openBlock(), _createBlock(_component_AppTypographyText, {
            key: 0,
            label: "How many students are in your class?",
            variant: "bd",
            type: "body",
            class: "mb-1"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.hasStudents)
          ? (_openBlock(), _createBlock(_component_FormField, {
              key: 0,
              modelValue: _ctx.studentCount,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.studentCount) = $event)),
              options: _ctx.options,
              optionLabel: "label",
              "option-value": "value",
              type: "select",
              required: true,
              background: false
            }, null, 8, ["modelValue", "options"]))
          : _createCommentVNode("", true),
        (_ctx.assignmentData)
          ? (_openBlock(), _createBlock(_component_AppButton, {
              key: 1,
              class: _normalizeClass(_ctx.hasStudents ? 'w-full' : 'min-w-[350px]'),
              type: "primary",
              loading: _ctx.type === 'generate' && _ctx.downloadingCoversheets,
              disabled: _ctx.type === 'generate' && _ctx.downloadingCoversheets,
              onClick: _withModifiers(_ctx.handleGenerateCoversheets, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Generate " + _toDisplayString(_ctx.hasStudents ? "" : _ctx.studentCount) + " " + _toDisplayString(_ctx.studentCount === 1 ? "Coversheet/Worksheet" : "Coversheets/Worksheets"), 1)
              ]),
              _: 1
            }, 8, ["class", "loading", "disabled", "onClick"]))
          : _createCommentVNode("", true),
        (_ctx.assignmentData)
          ? (_openBlock(), _createBlock(_component_AppButton, {
              key: 2,
              class: _normalizeClass(_ctx.hasStudents ? 'w-full' : 'min-w-[350px]'),
              type: "submit",
              loading: _ctx.type === 'download' && _ctx.downloadingCoversheets,
              disabled: 
          (_ctx.type === 'download' && _ctx.downloadingCoversheets) ||
          !_ctx.assignmentData.coversheet ||
          (_ctx.assignmentData.coversheet && !_ctx.assignmentData.coversheet.media)
        ,
              onClick: _withModifiers(_ctx.handleDownloadCoversheets, ["prevent"])
            }, {
              icon_left: _withCtx(() => [
                _createElementVNode("span", {
                  innerHTML: _ctx.icon.iconDownload
                }, null, 8, _hoisted_3)
              ]),
              default: _withCtx(() => [
                _createTextVNode(" Download Coversheets/Worksheets ")
              ]),
              _: 1
            }, 8, ["class", "loading", "disabled", "onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_ModalUtility, {
      modelValue: _ctx.isInfoModalOpen,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isInfoModalOpen) = $event)),
      width: "35vw",
      title: "Generating Coversheets/Worksheets",
      onOnClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isInfoModalOpen = false))
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.modalContent), 1)
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_AppButton, {
            type: "submit",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.isInfoModalOpen = false), ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Okay ")
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}