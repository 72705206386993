<template>
  <ModalUtility
    v-model="openModal"
    width="45vw"
    title="Add Teachers"
    @onClose="handleCloseModal"
  >
    <template #content>
      <div v-if="failedEmails.length > 0" class="px-8 py-4">
        <AppTypographyText
          label="Unable to import the following teachers"
          variant="md"
          type="body"
          class="mb-2"
        />
        <AppTypographyText
          label="Reason: Email address already exists"
          variant="md"
          type="caption"
          class="mb-5"
        />
        <AppTypographyText
          v-for="(account, index) in failedEmails"
          :key="index"
          :label="`• ${account.name} (${account.email})`"
          variant="rg"
          type="caption"
          class="pl-2"
        />
      </div>
      <div v-else class="px-4">
        <FormField label="School Information">
          <div class="flex flex-col gap-5 px-3">
            <div class="col-span-1 flex flex-col gap-1">
              <AppTypographyText label="School Name" variant="bd" type="body" />
              <FormField
                v-model="schoolName"
                type="text"
                :required="true"
                :background="false"
                :error="error.schoolName"
              />
            </div>
            <div class="col-span-1 flex flex-col gap-1">
              <AppTypographyText label="Country" variant="bd" type="body" />

              <Dropdown
                v-model="country"
                :options="countries"
                optionLabel="name"
                placeholder="Select your country"
                class="w-full h-[49px] bg-flohh-neutral-95"
                @change="
                  () => {
                    getCities();
                  }
                "
              />
            </div>
            <div class="col-span-1 flex flex-col gap-1">
              <AppTypographyText
                label="State/province"
                variant="bd"
                type="body"
              />

              <Dropdown
                placeholder="Select your state"
                v-model="state"
                :options="states"
                optionLabel="name"
                class="w-full h-[49px] bg-flohh-neutral-95"
                style="font-size: 14px !important"
                v-if="showStateDropdown"
              />

              <FormTextInput
                type="text"
                name="state"
                placeholder="Enter your state"
                v-model="state"
                v-if="!showStateDropdown"
              />
              <!-- <FormField :required="true" :error="error.state">
                <SelectState
                  v-model="state"
                  :country="country"
                  required
                  :invalid="error.state !== ''"
              /></FormField>
              <FormField
                v-model="state"
                type="text"
                :required="true"
                :background="false"
                :error="error.state !== ''"
              /> -->
            </div>
          </div>
        </FormField>
        <div class="w-full mt-7">
          <AppTypographyText
            variant="bd"
            type="title"
            label="Upload Teachers List"
            class="my-[8px]"
          />
          <div class="w-full mt-3">
            <AppTypographyText variant="md" type="subtitle">
              Your teachers list should have the following columns: First name,
              Last name, and Email Address. You can download a template by
              clicking the button below.
            </AppTypographyText>
            <a
              class="flex justify-center items-center gap-x-[8px] rounded-[8px] font-flohh-font-medium px-[16px] py-[8px] bg-flohh-secondary-green hover:bg-flohh-secondary-green-dark text-flohh-text-caption h-[34px] mt-2 w-64"
              href="/template/teachers-list-template.csv"
              download="Flohh Teachers List Template.csv"
              type="csv"
            >
              Click here to start the download
            </a>
            <div class="my-2 mb-5">
              <DefaultFileUploader
                v-model:selectedFiles="selectedFileModel"
                :multiple="false"
                :showFileList="true"
                acceptedFiles=".csv,.xlsx"
                supportedFileTypes="Support file types: CSV, Microsoft Excel"
                @onRemoveFile="handleRemoveFile"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="flex flex-row pt-5 border-t border-solid border-flohh-neutral-85 justify-end"
      >
        <AppButton
          v-if="failedEmails.length > 0"
          type="primary"
          label="Close"
          @click="openModal = false"
        >
          <template #icon_left>
            <span v-html="icon.closeBlack"></span>
          </template>
        </AppButton>
        <AppButton
          v-else
          type="submit"
          :loading="loadingImport"
          label="Save"
          @click="handleClickSave"
          :disabled="
            loadingImport ||
            !country ||
            (!state && showStateDropdown) ||
            !schoolName
          "
        >
          <template #icon_left>
            <span v-html="icon.checkBlack"></span>
          </template>
        </AppButton>
      </div>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { Vue, Component, Model, Emit, Watch } from "vue-facing-decorator";
import FormField from "../Layout/Forms/FormField.vue";
import ModalUtility from "../utilities/ModalUtility.vue";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import FileUploader from "../utilities/FileUploader.vue";
import { Icon, icons } from "@/utils/icons";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { useToast } from "primevue/usetoast";
import SelectCountry from "@/components/utilities/SelectCountry.vue";
import SelectState from "@/components/utilities/SelectState.vue";
import DefaultFileUploader from "@/components/utilities/DefaultFileUploader.vue";
import * as XLSX from "xlsx";
import SuperAdminService from "@/services/SuperAdminService";
import emitter from "@/config/emitter";
import Dropdown from "primevue/dropdown";
import axios, { AxiosResponse } from "axios";
import Countries from "@/utils/coutries/countriesStates.json";
import FormTextInput from "../Layout/Forms/FormTextInput.vue";
interface FailedEmails {
  error: string;
  name: string;
  email: string;
}

export interface IState {
  code: string;
  name: string;
  subdivision: null | string;
}

export interface ICountry {
  code2: string;
  code3: string;
  name: string;
  capital: string;
  subregion: string;
  states: IState[];
}

@Component({
  components: {
    FormField,
    DefaultFileUploader,
    ModalUtility,
    AppTypographyText,
    FileUploader,
    AppButton,
    Dropdown,
    FormTextInput,
  },
})
export default class ImportUsersComponent extends Vue {
  private superAdminService: SuperAdminService = new SuperAdminService();
  eventBus = emitter;

  @Model({
    type: Boolean,
  })
  openModal!: boolean;

  selectedFileModel: File[] = [];
  icon: Icon = icons;
  loadingImport = false;
  toast = useToast();
  country!: ICountry | null;
  state!: IState | null;
  schoolName!: string;

  failedEmails: FailedEmails[] = [];

  error = {
    country: "",
    state: "",
    schoolName: "",
  };

  countries: ICountry[] = Countries as ICountry[];

  states!: IState[];
  showStateDropdown = true;

  @Watch("schoolName")
  schoolNameWatcher(newValue: string) {
    if (newValue) {
      this.error.schoolName = "";
    } else {
      this.error.schoolName = "School name is required";
    }
  }

  @Watch("country")
  countryWatcher(newValue: string) {
    if (newValue) {
      this.error.country = "";
    } else {
      this.error.country = "Country is required";
    }
  }

  @Watch("state")
  stateWatcher(newValue: string) {
    if (newValue) {
      this.error.state = "";
    } else {
      this.error.state = "State/province is required";
    }
  }

  async getCities() {
    // Define the URL
    if (this.country && this.country.name) {
      this.state = null;
      // this.statesLoaded = true;
      const country = this.countries.find(
        (o: any) => o.name === this.country?.name
      );
      if (country) {
        this.states = country.states;
        this.showStateDropdown = this.states.length > 0;
      }
    }
  }

  handleCloseModal() {
    //
  }

  handleRemoveFile() {
    //
  }

  checkFormValidity() {
    let isValid = true;
    if (!this.schoolName) {
      this.error.schoolName = "School name is required";
      isValid = false;
    }

    if (!this.country) {
      this.error.country = "Country is required";
      isValid = false;
    }

    if (!this.state) {
      this.error.state = "State/province is required";
      isValid = false;
    }

    return isValid;
  }

  async handleClickSave() {
    // To make sure the columns are firstName, lastName, and email
    await this.updateColumnHeadings();

    const isValid = this.checkFormValidity();
    if (!isValid) {
      return;
    }
    if (this.selectedFileModel.length === 0) {
      this.showMessage(
        "error",
        "CSV File Not Found",
        "Attach your list of student CSV file"
      );
      return;
    }
    if (
      this.selectedFileModel[0].type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      //
    } else if (
      this.selectedFileModel[0].type === "text/csv" ||
      this.selectedFileModel[0].type === "application/vnd.ms-excel"
    ) {
      const content = await this.readCSV(this.selectedFileModel[0]);
      const parseStatus = this.parseCSV(content);
      if (!parseStatus) {
        return;
      }
    } else {
      this.showMessage(
        "error",
        "File Error",
        "Please select CSV or Excel file"
      );
      return;
    }
    await this.onSaveFileUploadStudent();
  }

  async onSaveFileUploadStudent() {
    this.loadingImport = true;

    const payload = {
      file: this.selectedFileModel[0],
      schoolName: this.schoolName,
      state: this.state?.name,
      country: this.country?.name,
    };
    try {
      const response = await this.superAdminService.uploadTeachers(payload);
      if (response.data.ok) {
        const failedEmails = response.data.data.filter(
          (item: Record<string, unknown>) => !item.ok
        );
        if (failedEmails.length > 0) {
          this.showMessage(
            "success",
            "Teachers Added",
            "Teachers have been added to Flohh"
          );
          const failedEmailsArray: FailedEmails[] = [];
          failedEmails.forEach((item: Record<string, any>) => {
            if (item.data.error.includes("already in use")) {
              failedEmailsArray.push({
                error: item.data.error,
                name: `${item.data.payload.firstName} ${item.data.payload.lastName}`,
                email: item.data.payload.email,
              });
            }
          });
          this.failedEmails = failedEmailsArray;
        } else {
          this.showMessage(
            "success",
            "Teachers Added",
            "Teachers have been added to Flohh"
          );
          this.openModal = false;
        }
      }
      this.resetFields();
      this.eventBus.emit("REFRESH_TEACHERS_LIST");
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingImport = false;
    }
  }

  readFile(file: File): Promise<ArrayBuffer | string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && event.target.result) {
          resolve(event.target.result as ArrayBuffer | string);
        }
      };
      reader.onerror = reject;

      if (file.type === "text/csv") {
        reader.readAsText(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    });
  }

  async updateColumnHeadings() {
    for (const file of this.selectedFileModel) {
      try {
        const content = await this.readFile(file);
        let workbook: XLSX.WorkBook;
        if (typeof content === "string" && file.type === "text/csv") {
          workbook = XLSX.read(content, { type: "string" });
        } else {
          workbook = XLSX.read(content, { type: "array" });
        }

        const sheetName: string = workbook.SheetNames[0];
        const sheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
        const data: any[][] = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
        }) as any[][];

        data[0][0] = "firstName";
        data[0][1] = "lastName";
        data[0][2] = "email";

        const newSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
        workbook.Sheets[sheetName] = newSheet;

        let blob: Blob;
        if (file.type === "text/csv") {
          const csv = XLSX.utils.sheet_to_csv(newSheet);
          blob = new Blob([csv], { type: "text/csv" });
        } else {
          const wbout = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });
          blob = new Blob([wbout], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
        }

        const modifiedFile = new File([blob], file.name, {
          type: file.type,
        });
        const modifiedFiles: File[] = [];
        modifiedFiles.push(modifiedFile);
        this.selectedFileModel = modifiedFiles;
      } catch (error) {
        console.error(error);
      }
    }
  }

  resetFields() {
    this.country = null;
    this.state = null;
    this.schoolName = "";
    this.selectedFileModel = [];
    this.error = {
      country: "",
      state: "",
      schoolName: "",
    };
  }

  readCSV(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target) {
          const content: string = e.target.result as string;
          resolve(content);
        }
      };

      reader.onerror = (e) => {
        reject(e);
      };

      reader.readAsText(file);
    });
  }

  parseCSV(content: string) {
    const rows = content.split("\n");
    const emails: string[] = [];

    try {
      const invalidColumnsError =
        "Please make sure you have the following columns in order: firstName, lastName, email";
      rows.forEach((row, index) => {
        if (row !== "") {
          const columns = row.split(",");
          if (columns.length > 2) {
            if (index === 0) {
              if (!columns.includes("lastName")) {
                throw new Error("Missing column 'lastName'");
              } else if (!columns.includes("firstName")) {
                throw new Error("Missing column 'firstName'");
              } else if (!columns[2].includes("email")) {
                throw new Error("Missing column 'email'");
              }
              if (
                columns[0] !== "firstName" ||
                columns[1] !== "lastName" ||
                !columns[2].includes("email")
              ) {
                throw new Error(invalidColumnsError);
              }
            }
            const email = columns[2].trim();
            emails.push(email);
          } else {
            throw new Error(invalidColumnsError);
          }
        }
      });
    } catch (error: any) {
      this.toast.add({
        severity: "error",
        summary: "Invalid column",
        detail: error.message,
        life: 3000,
      });
      return false;
    }
    return true;
  }

  @Watch("openModal")
  openModalWatcher(value: boolean) {
    if (!value) {
      this.failedEmails = [];
    }
  }

  showMessage(type: "success" | "error", title: string, message: string) {
    this.toast.add({
      severity: type,
      summary: title,
      detail: message,
      life: 3000,
    });
  }
}
</script>
