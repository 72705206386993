import { RouteRecordRaw } from "vue-router";
const SubscriptionsModule = () => import("./SubscriptionsModule.vue");

const moduleRoute: RouteRecordRaw = {
  path: "/superadmin/subscriptions",
  component: SubscriptionsModule,
  props: true,
  children: [
    {
      path: "/superadmin/subscriptions",
      name: "ManageSubscriptionsView",
      component: () => import("./Views/ManageSubscriptionsView.vue"),
      meta: {
        title: "Manage Subscriptions",
        restriction: ["super admin"],
      },
    },
    {
      path: "/superadmin/subscriptions/:id",
      name: "SpecificSubscriptionView",
      component: () => import("./Views/SpecificSubscriptionView.vue"),
      meta: {
        title: "Specific Subscription",
        restriction: ["super admin"],
      },
    },
  ],
};

export default moduleRoute;
