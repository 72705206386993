<template>
  <ModalUtility
    v-model="visible"
    width="100%"
    title="Upgrade Plan"
    @onClose="toggleModal"
    containerStyle="!w-full max-w-[1075px]"
    :draggable="false"
  >
    <template #header>
      <div
        class="w-full h-[76px] bg-flohh-primary-pink rounded-t-[4px] sm:py-0 flex flex-col justify-center items-start bg-[url('@/assets/modal-header-bg.png')] bg-repeat !bg-contain z-[1]"
      >
        <div
          class="absolute top-50 right-50 px-4 flex flex-col items-start justify-center w-full"
        >
          <h1 class="text-flohh-h6 font-flohh-font-bold text-flohh-neutral-20">
            Upgrade Plan
          </h1>
          <!-- Use for teleportation don't remove this tag -->
          <p id="modal-subtitle-teleport" class="font-semibold"></p>
          <!-- end of teleportation -->
        </div>
        <div class="btn-close-wrap absolute top-[10px] right-[10px]">
          <button class="p-2 text-white rounded-full" @click="handleClickClose">
            <img src="@/assets/close.svg" class="w-4" />
          </button>
        </div>
      </div>
    </template>
    <template #content>
      <div class="px-[24px] py-[24px]">
        <div class="flex flex-col">
          <h6 class="text-flohh-text-title font-flohh-font-bold mb-2">
            Upgrade Plan
          </h6>
          <p
            class="text-flohh-text-caption font-flohh-font-medium text-flohh-neutral-35"
          >
            Explore our plans to elevate your teaching experience and boost
            student engagement. <br />Upgrade now to unlock advanced features
            and maximise your educational impact.
          </p>
        </div>
        <div class="mb-[40px] mt-[40px]">
          <CalculateToolsComponent
            @onPlanSelected="handlePlanSelected"
            @onSeatChange="handleSeatChange"
          />
        </div>
        <div class="grid grid-cols-4 gap-4 items-stretch">
          <div v-for="(plan, index) in plans" :key="index">
            <PlanCard
              :plan="plan"
              :activePlan="activePlan"
              :planType="planType"
              :seatCount="seatCount"
              :plans="plans"
              @handleUpgrade="handleUpgrade"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer> </template>
  </ModalUtility>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model } from "vue-facing-decorator";

import CalculateToolsComponent from "./CalculateToolsComponent.vue";
import PlanCard from "./PlanCard.vue";
import ProgressLoader from "../utilities/ProgressLoader.vue";
import ModalUtility from "../utilities/ModalUtility.vue";
import { icons } from "../../../../accounts-webapp/src/const/icons";
import AppButton from "../Layout/Buttons/AppButton.vue";

import { Plan, ActivePlan } from "./type";

@Component({
  components: {
    CalculateToolsComponent,
    ProgressLoader,
    PlanCard,
    ModalUtility,
    AppButton,
  },
})
export default class UpgradePlanComponent extends Vue {
  @Model({
    type: Boolean,
    required: true,
  })
  visible!: boolean;

  @Prop({
    required: true,
  })
  plans!: Plan[];

  @Prop({
    required: true,
  })
  activePlan!: ActivePlan | undefined;

  planType = "monthly";
  seatCount = 0;
  icon = icons;

  @Prop({
    type: String,
    required: false,
  })
  isRedirectBack!: string;

  handleClickClose() {
    this.visible = false;
    if (this.activePlan?.credits?.available === 0 && this.isRedirectBack) {
      this.$router.push(this.isRedirectBack);
    }
  }

  handlePlanSelected(value: "monthly" | "yearly") {
    this.planType = value;
  }

  handleSeatChange(quantity: number) {
    this.seatCount = quantity;
  }

  toggleModal() {
    this.visible = !this.visible;
  }

  handleUpgrade(plan: Plan) {
    //
    this.visible = false;
    window.open(
      `${process.env.VUE_APP_ACCOUNTS_URL}/manage?quantity=${this.seatCount}&billingSchedule=${this.planType}&plan=${plan.slug}`,
      "_blank"
    );
  }
}
</script>

<style scoped lang="scss"></style>
