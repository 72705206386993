import { CreateSubscriptionPayload } from "@/modules/Subscriptions/types";
import axios from "axios";

const BASE_URL = "/accounts";
const SUPER_ADMIN_BASE_URL = "/super-admin/accounts";

export default class AccountsPortalService {
  updateInvitation(uuid: string, action: "accept" | "decline") {
    return axios.post(
      `${BASE_URL}/subscription/pending-actions/${uuid}/${action}`
    );
  }

  fetchactiveSubscription(ownerUuid: string) {
    return axios.get(
      `${SUPER_ADMIN_BASE_URL}/subscription/active/${ownerUuid}`
    );
  }

  resendMemberInvitation(ownerUuid: string, email: string) {
    return axios.post(
      `${SUPER_ADMIN_BASE_URL}/subscription/consumer/resend-invite/${ownerUuid}`,
      { email }
    );
  }

  removeUserFromSubscription(ownerUuid: string, email: string) {
    return axios.put(
      `${SUPER_ADMIN_BASE_URL}/subscription/consumer/remove/${ownerUuid}`,
      { email }
    );
  }

  inviteTeachersAsConsumers(ownerUuid: string, teachersEmails: string[]) {
    return axios.post(
      `${SUPER_ADMIN_BASE_URL}/subscription/consumer/invite/${ownerUuid}`,
      { teachers: teachersEmails }
    );
  }

  fetchTeachersWithSubscriptions() {
    return axios.get(`${SUPER_ADMIN_BASE_URL}/subscriptions`);
  }

  createSubscription(teacherUuid: string, payload: CreateSubscriptionPayload) {
    return axios.post(
      `${SUPER_ADMIN_BASE_URL}/subscription/create/${teacherUuid}`,
      { ...payload }
    );
  }

  updateSubscription(
    subscriptionUuid: string,
    payload: CreateSubscriptionPayload
  ) {
    return axios.put(
      `${SUPER_ADMIN_BASE_URL}/subscription/${subscriptionUuid}`,
      { ...payload }
    );
  }
}
