import axios, { AxiosResponse } from "axios";

export const version = {
  releaseDate: "Wed Mar 5 4:16 PM",
  commit: "38e3ec4f23bb89fcaabb42f609792b50ec1f48c5",
  environment: process.env.VUE_APP_NODE_ENV,
  number: "support/2501.2.0",
};

export const getAPIVersion = async () => {
  try {
    const appURL = process.env.VUE_APP_API_URL;
    const splitURL = appURL ? appURL.split("/api/v1").join("") : "";
    const response = await fetch(`${splitURL}/version`);

    if (response) {
      return await response.json();
    }
  } catch (err) {
    console.error(err);
  }
};
