<template>
  <ModalUtility
    v-model="isOpen"
    :draggable="true"
    title="Printable Worksheet Builder"
    width="30vw"
  >
    <template #content>
      <div class="p-10 flex flex-col gap-10">
        <p>
          Build a printable worksheet for your assignment. Each copy contains a
          unique QR code for each student making uploading submissions simple
          with auto-splitting. Add questions, lined response areas and
          instructions in one simple to print document.
        </p>
        <div class="flex flex-col gap-3">
          <AppButton
            type="transparent"
            label="Open Worksheet Builder"
            @click.prevent="toggleCoversheetBuilder"
            textColor="text-flohh-primary-pink"
            borderColor="border-flohh-primary-pink"
            size="md"
          /><AppButton
            type="transparent"
            label="Preview worksheet"
            @click.prevent="handlePreviewCoversheet"
            :textColor="
              isPreviewDisabled
                ? 'text-flohh-neutral-85'
                : 'text-flohh-primary-pink'
            "
            :borderColor="
              isPreviewDisabled
                ? 'border-flohh-neutral-85'
                : 'border-flohh-primary-pink'
            "
            size="md"
            :disabled="isPreviewDisabled"
          /><AppButton
            type="transparent"
            label="Download worksheets"
            @click.prevent="handleDownloadCoversheet"
            :disabled="isDownloading || isDownloadDisabled"
            :loading="isDownloading"
            :textColor="
              isDownloadDisabled
                ? 'text-flohh-neutral-85'
                : 'text-flohh-primary-pink'
            "
            :borderColor="
              isDownloadDisabled
                ? 'border-flohh-neutral-85'
                : 'border-flohh-primary-pink'
            "
            size="md"
          />
        </div></div></template
  ></ModalUtility>
  <CoverSheetBuilderModal
    v-model="coversheetBuilderOpen"
    :coversheetData="coversheetData"
    :className="assignmentData.title"
    :assignmentName="assignmentData.class.code"
    :classUuid="assignmentData.class.uuid"
    :assignmentUuid="assignmentData.uuid"
  />
</template>

<script lang="ts">
import { Component, Model, Vue, Prop } from "vue-facing-decorator";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import CoverSheetBuilderModal from "./CoversheetBuilderModal.vue";
import { AssignmentCoverSheet } from "@/store/assignment/assignmentTypes";
import { AssignmentData } from "@/store/assignment/assignmentTypes";
import emitter from "@/config/emitter";
import MediaService from "@/services/MediaService";
import { dataURLtoFile } from "@/utils/helper";

@Component({
  components: {
    AppButton,
    ModalUtility,
    CoverSheetBuilderModal,
  },
})
export default class CoversheetBuilderUtilityModal extends Vue {
  mediaService: MediaService = new MediaService();

  @Model({
    default: false,
    type: Boolean,
  })
  isOpen!: boolean;

  @Prop({
    type: Array,
    default: [],
  })
  coversheetData!: AssignmentCoverSheet;

  @Prop({
    type: Object,
    required: true,
  })
  assignmentData!: AssignmentData;

  coversheetBuilderOpen = false;
  isPreviewDisabled = true;
  isDownloadDisabled = true;
  eventBus = emitter;
  isDownloading = false;

  mounted() {
    this.isPreviewDisabled =
      !this.coversheetData || !this.coversheetData.settings;
    this.isDownloadDisabled =
      !this.coversheetData || !this.coversheetData.media;
  }

  toggleCoversheetBuilder() {
    this.coversheetBuilderOpen = !this.coversheetBuilderOpen;
  }

  handlePreviewCoversheet() {
    this.coversheetBuilderOpen = !this.coversheetBuilderOpen;
    setTimeout(() => {
      this.eventBus.emit("PREVIEW_COVERSHEET");
    }, 100);
  }

  async handleDownloadCoversheet() {
    try {
      this.isDownloading = true;
      const mediaID = this.coversheetData.media;

      const response = await this.mediaService.getMedia(mediaID);
      let fileType = "application/pdf";

      if (response.data.ok) {
        const responseData = response.data.data;
        const linkSource = `data:${fileType};base64,${responseData.data}`;
        const fileName = `${responseData.name.split(".")[0]}.pdf`;

        const file = dataURLtoFile(linkSource, fileName);
        const blob = new Blob([file], { type: fileType });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isDownloading = false;
    }
  }
}
</script>

<style scoped></style>
