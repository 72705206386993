import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex items-center justify-between px-4 py-4 mx-4 mt-4 rounded-lg bg-flohh-secondary-blue"
}
const _hoisted_2 = { class: "flex items-center gap-2 custom-banner" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "flex gap-3" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", {
            innerHTML: _ctx.icons[_ctx.icon]
          }, null, 8, _hoisted_3),
          _renderSlot(_ctx.$slots, "default")
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.allowRemove)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.removeBanner && _ctx.removeBanner(...args)))
              }, [
                _createElementVNode("span", {
                  innerHTML: _ctx.icons.trashBlack
                }, null, 8, _hoisted_5)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hideBanner && _ctx.hideBanner(...args)))
          }, [
            _createElementVNode("span", {
              innerHTML: _ctx.icons.closeBlack
            }, null, 8, _hoisted_6)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}