<template>
  <div>
    <div
      class="h-full bg-flohh-neutral-95 pt-3 pb-10 px-5 border-flohh-neutral-85 border-dashed border rounded-lg mb-5"
    >
      <AppTypographyText
        :label="title"
        variant="bd"
        type="subtitle"
        class="mb-3"
      />
      <div class="flex gap-9 justify-center mb-3">
        <DefaultFileUploader
          v-if="visibility.localUpload"
          v-model:selectedFiles="selectedFilesModel"
          v-bind="{ ...localFileUploaderConfig }"
          :useSlot="true"
          :multiple="multiple"
          ><div
            class="cursor-pointer flex items-center flex-col hover:scale-110 active:scale-100 transition-all"
          >
            <div
              class="mb-1 rounded-[50px] bg-flohh-neutral-100 w-[40px] h-[40px] flex justify-center items-center border-flohh-neutral-85 border border-solid"
            >
              <span v-html="icons.uploadBlack"></span>
            </div>
            <AppTypographyText label="Upload" type="small" /></div
        ></DefaultFileUploader>

        <GoogleDriveFileUploader
          v-if="visibility.gDriveUpload"
          v-model:selectedFiles="selectedFilesModel"
          v-bind="{ ...gDriveFileUploaderConfig }"
          :useSlot="true"
          :multiple="multiple"
        >
          <div
            class="cursor-pointer flex items-center flex-col hover:scale-110 active:scale-100 transition-all"
          >
            <div
              class="mb-1 rounded-[50px] bg-flohh-neutral-100 w-[40px] h-[40px] flex justify-center items-center border-flohh-neutral-85 border border-solid"
            >
              <span v-html="icons.googleDrive"></span>
            </div>
            <AppTypographyText label="Drive" type="small" /></div
        ></GoogleDriveFileUploader>

        <div
          v-if="visibility.youtubeUpload"
          class="cursor-pointer flex items-center flex-col hover:scale-110 active:scale-100 transition-all"
          @click="handleClickYoutubeUpload"
        >
          <div
            class="mb-1 rounded-[50px] bg-flohh-neutral-100 w-[40px] h-[40px] flex justify-center items-center border-flohh-neutral-85 border border-solid"
          >
            <span v-html="icons.youtubeIcon"></span>
          </div>
          <AppTypographyText label="Youtube" type="small" />
        </div>

        <div
          v-if="visibility.linkUpload"
          class="cursor-pointer flex items-center flex-col hover:scale-110 active:scale-100 transition-all"
          @click="handleClickUploadLink"
        >
          <div
            class="mb-1 rounded-[50px] bg-flohh-neutral-100 w-[40px] h-[40px] flex justify-center items-center border-flohh-neutral-85 border border-solid"
          >
            <span v-html="icons.iconLink"></span>
          </div>
          <AppTypographyText label="Link" type="small" />
        </div>
      </div>
      <div v-if="supportedFileTypes" class="flex justify-center">
        <AppTypographyText
          :label="supportedFileTypes"
          type="caption"
          class="mb-3"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Model } from "vue-facing-decorator";
import { icons } from "@/utils/icons";
import {
  LocalFileUploaderConfig,
  GDriveFileUploaderConfig,
} from "./utilitiesTypes";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import DefaultFileUploader from "@/components/utilities/DefaultFileUploader.vue";
import GoogleDriveFileUploader from "@/components/utilities/GoogleDriveFileUploader.vue";

@Component({
  components: {
    AppTypographyText,
    DefaultFileUploader,
    GoogleDriveFileUploader,
  },
})
export default class InstructionsAttachmentsComponent extends Vue {
  icons = icons;

  @Model({
    type: Object,
    required: true,
    default: [],
  })
  selectedFilesModel!: File[];

  @Prop({
    type: String,
    required: true,
  })
  title!: string;

  @Prop({
    type: Object,
    required: false,
  })
  localFileUploaderConfig!: LocalFileUploaderConfig;

  @Prop({
    type: Object,
    required: false,
  })
  gDriveFileUploaderConfig!: GDriveFileUploaderConfig;

  @Prop({
    type: Boolean,
    default: false,
  })
  multiple!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  supportedFileTypes!: string;

  @Prop({
    type: Object,
    default: {
      localUpload: true,
      gDriveUpload: true,
      linkUpload: false,
    },
  })
  visibility!: {
    localUpload: boolean;
    gDriveUpload: boolean;
    linkUpload: boolean;
    youtubeUpload: boolean;
  };

  handleClickUploadLink() {
    this.$emit("onClickUploadLink");
  }

  handleClickYoutubeUpload() {
    this.$emit("onClickYoutubeUpload");
  }
}
</script>

<style scoped lang="scss"></style>
