<template>
  <div class="p-3">
    <div
      class="p-5 w-full rounded-lg border border-flohh-neutral-85 border-solid bg-white flex flex-col gap-5 no-drag"
    >
      <p class="text-flohh-text-title font-bold">
        Unique Student Identification Block
      </p>
      <p>
        This Block is automatically added to page 1 and will contain a unique QR
        Identification code for each student, the student name and assignment
        and class details. This content will be generated dynamically for each
        student when you download the coversheets for your class.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { icons } from "@/utils/icons";

@Component({
  components: {},
})
export default class IdentificationBlock extends Vue {
  icons = icons;

  mounted() {
    //
  }
}
</script>

<style scoped></style>
