export interface ResponsiveBreakpoints {
  [key: string]: string;
}

export const defaultModalBreakPoints: ResponsiveBreakpoints = {
  "960px": "75vw",
  "641px": "94vw",
};

export interface UploadProgress {
  fileName: string;
  progress: number;
}

export interface LocalFileUploaderConfig {
  acceptedFiles?: string;
  placeholder?: string;
  supportedFileTypes?: string;
  showFileList?: boolean;
  draggingPlaceholder?: string;
  disabled?: boolean;
  uploadProgress?: UploadProgress;
  startUploading?: boolean;
  fileSizeLimit?: number;
  fileSizeLimitPlaceholder?: string;
  maxNumberOfFiles?: number;
}

export interface GDriveFileUploaderConfig extends LocalFileUploaderConfig {
  fetchOnSubmit?: boolean;
}
