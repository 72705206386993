import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    options: _ctx.states,
    optionLabel: "name",
    optionValue: "name",
    filter: true,
    virtualScrollerOptions: { itemSize: 46 },
    placeholder: _ctx.states.length ? 'Select a State' : 'Select a country first',
    filterPlaceholder: "Search state",
    class: _normalizeClass(['w-full', { 'p-invalid': _ctx.invalid && _ctx.required }])
  }, null, 8, ["modelValue", "options", "placeholder", "class"]))
}