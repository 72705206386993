import { registerModules } from "./registerModules";

import RubricksBank from "./modules/RubricsBanks";
import CommentBanks from "./modules/CommentBanks";
import AnnotationBanks from "./modules/AnnotationBanks";
import GradeSchemaBank from "./modules/GradeSchemaBank";
import Subscriptions from "./modules/Subscriptions";
import UserNotifications from "./modules/UserNotifications";

registerModules({
  RubricksBank,
  CommentBanks,
  AnnotationBanks,
  GradeSchemaBank,
  Subscriptions,
  UserNotifications,
});
