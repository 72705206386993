<template>
  <div v-show="!isPreview" class="p-10 flex flex-col gap-10">
    <div class="flex justify-between">
      <AppButton
        @click="handleAddPage"
        type="submit"
        label="Add Page"
        iconLeft="plusBlack"
        size="md"
      />
      <AppButton @click="handlePreview" type="submit"
        >Preview Coversheet</AppButton
      >
    </div>
    <div class="w-full flex flex-col gap-5">
      <div
        v-for="(page, index) in layouts"
        :key="index"
        class="rounded-lg border border-flohh-neutral-85 border-solid p-5"
      >
        <div class="flex justify-between mb-2">
          <strong
            ><h5 class="text-flohh-h6">PAGE {{ index + 1 }}</h5></strong
          >
          <button
            v-if="index !== 0"
            @click="
              () => {
                removePageNumber = index + 1;
                removePageOpen = true;
              }
            "
            class="hover:scale-110"
          >
            <span v-html="icons.trashSmallRedIcon" />
          </button>
        </div>
        <div v-if="page.length" class="flex flex-col gap-5">
          <IdentificationBlock v-if="index === 0" />
          <GridLayout
            :layout="layouts[index]"
            :col-num="1"
            :is-draggable="isDraggable"
            :is-resizable="isResizable"
            :is-bounded="isBounded"
            :vertical-compact="isVerticalCompact"
          >
            <BuilderBlock
              :pageIndex="index"
              :layouts="layouts"
              :pageRemainingSpace="pageRemainingSpaces[index]"
              @onBlockMoved="handleBlockMoved"
              @onStartDrag="handleStartDrag"
              @onRemoveBlock="handleRemoveBlock"
              @onBlockContentUpdate="handleUpdateBlockContent"
            />
          </GridLayout>
          <div class="flex justify-between items-center">
            <p
              class="text-flohh-text-body"
              :class="[
                {
                  'text-flohh-secondary-red':
                    Math.floor(
                      pageRemainingSpaces[index] / itemVerticalSpacing
                    ) < 0,
                },
              ]"
            >
              Remaining space left on this page:
              {{ Math.floor(pageRemainingSpaces[index] / itemVerticalSpacing) }}
              lines
            </p>
            <div class="ml-auto flex gap-2">
              <AppButton
                v-for="(objKey, i) in Object.keys(blocksBP)"
                :key="i"
                @click="() => handleAddBlock(index, blocksBP[objKey])"
                type="primary"
                >{{ blocksBP[objKey].name }}</AppButton
              >
            </div>
          </div>
        </div>
        <div v-else class="flex flex-col gap-5">
          <IdentificationBlock v-if="index === 0" />

          <p v-else>Please add block to display here</p>

          <div class="ml-auto flex gap-2">
            <AppButton
              v-for="(objKey, i) in Object.keys(blocksBP)"
              :key="i"
              @click="() => handleAddBlock(index, blocksBP[objKey])"
              type="primary"
              >{{ blocksBP[objKey].name }}</AppButton
            >
          </div>
        </div>
      </div>
      <AppButton
        v-if="layouts.length < 5"
        type="transparent"
        label="Add Page"
        @click="handleAddPage"
        iconLeft="plusPink"
        textColor="text-flohh-primary-pink"
        borderColor="border-flohh-primary-pink"
        size="md"
      />
    </div>
  </div>
  <div v-show="isPreview">
    <div class="px-5 pt-5">
      <AppButton
        @click.prevent="handleClosePreview"
        type="submit"
        class="ml-auto"
        >Close Preview</AppButton
      >
    </div>
    <PreviewCoversheet v-if="previewBase64" :base64="previewBase64" />
    <div v-else class="flex justify-center items-center">
      <ProgressLoader label="Loading..." />
    </div>
  </div>
  <Dialog
    :visible="removePageOpen"
    modal
    :closable="false"
    :close-on-escape="true"
    :style="{ width: '20vw', minWidth: '300px' }"
    :pt="{
      header: { class: '!py-4 !pl-6' },
      content: { class: '!p-0' },
      footer: { class: '!p-0' },
    }"
  >
    <template #header>
      <h5
        class="text-default text-neutral-gray-500 flex font-bold items-center justify-start"
      >
        <i v-html="icons.trashBlack" class="mr-2"></i>Remove Page
      </h5>
    </template>
    <div class="border-t border-solid border-neutral-gray-500 py-4 px-6">
      <p class="text-sm text-neutral-gray-500">
        Are you sure you want to remove page {{ removePageNumber }}?
      </p>
    </div>
    <template #footer>
      <div class="py-2 px-6 flex items-center justify-end">
        <Button
          label="Cancel"
          @click.prevent="removePageOpen = false"
          class="border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
        />
        <Button
          label="Remove"
          @click.prevent="() => handleRemovePage(removePageNumber - 1)"
          class="border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
        />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { Component, Vue, Prop } from "vue-facing-decorator";
import { GridLayout } from "grid-layout-plus";
import BulkUploadService from "@/services/BulkUploadService";
import { CoversheetPayload as ICoversheet } from "@/models/BulkUpload";
import { icons } from "@/utils/icons";
import { CoversheetPage, Block } from "../type";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import BuilderBlock from "./BuilderBlock.vue";
import PreviewCoversheet from "./PreviewCoversheet.vue";
import {
  COVERSHEET_BLOCKS,
  FIRST_PAGE_DEFAULT_REMAINING_SPACE,
  OTHER_PAGE_DEFAULT_REMAINING_SPACE,
  PER_BLOCK_SPACING,
  FIRST_PAGE_MARGIN_TOP,
  OTHER_PAGE_MARGIN_TOP,
  ITEM_VERTICAL_SPACING,
} from "../data";
import emitter from "@/config/emitter";
import { useToast } from "primevue/usetoast";
import { AssignmentCoverSheet } from "@/store/assignment/assignmentTypes";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import IdentificationBlock from "./Blocks/IdentificationBlock.vue";

export interface ILayoutItem {
  id: number;
  x: number;
  y: number;
  w: number;
  h: number;
  i: string | number;
  o: Block;
}

@Component({
  components: {
    AppButton,
    GridLayout,
    Dialog,
    Button,
    BuilderBlock,
    PreviewCoversheet,
    ProgressLoader,
    IdentificationBlock,
  },
})
export default class CoverSheetBuilderModal extends Vue {
  bulkUploadService: BulkUploadService = new BulkUploadService();
  icons = icons;
  blocksBP = COVERSHEET_BLOCKS;
  toast = useToast();

  @Prop({
    type: String,
    default: "",
  })
  className!: string;

  @Prop({
    type: String,
    default: "",
  })
  assignmentName!: string;

  @Prop({
    type: String,
    default: "",
  })
  classUuid!: string;

  @Prop({
    type: String,
    default: "",
  })
  assignmentUuid!: string;

  @Prop({
    type: Array,
    default: [],
  })
  coversheetData!: AssignmentCoverSheet;

  isDraggable = true;
  isResizable = false;
  isBounded = true;
  isVerticalCompact = true;

  pageIndex!: number;
  removePageOpen = false;
  removePageNumber!: number;

  previewBase64 = "";
  isPreview = false;
  generatingPreview = false;
  eventBus = emitter;
  preventUpdate = false;

  pages: number[] = [];

  itemVerticalSpacing = ITEM_VERTICAL_SPACING;
  firstPageMarginTop = FIRST_PAGE_MARGIN_TOP;
  otherPageMarginTop = OTHER_PAGE_MARGIN_TOP;
  blockSpacing = PER_BLOCK_SPACING;
  firstPageDefaultRemainingSpace = FIRST_PAGE_DEFAULT_REMAINING_SPACE;
  otherPageDefaultRemainingSpace = OTHER_PAGE_DEFAULT_REMAINING_SPACE;
  pageRemainingSpaces: number[] = [];

  layouts: ILayoutItem[][] = [];

  mounted() {
    this.eventBus.on("SAVE_COVERSHEET", () => {
      this.handleSaveCoversheet();
    });
    this.eventBus.on("PREVIEW_COVERSHEET", () => {
      this.handlePreview();
    });

    if (
      this.coversheetData.settings &&
      this.coversheetData.settings.length !== 0
    ) {
      this.layouts = this.coversheetData.settings.map((page: CoversheetPage) =>
        page.blocks.map((block, i) => ({
          id: this.getRandomFourDigitNumber(),
          x: 0,
          y: i,
          w: 1,
          h: 1,
          i: i.toString(),
          o: block,
        }))
      );
    } else {
      this.layouts = [[]];
    }

    this.setPageSpaceLimits();

    setTimeout(() => {
      this.$emit("onEdited", false);
    }, 700);
  }

  beforeUnmount() {
    this.eventBus.off("SAVE_COVERSHEET");
    this.eventBus.off("PREVIEW_COVERSHEET");
  }

  handleUpdateBlockContent(
    block: Block,
    pageIndex: number,
    blockIndex: number,
    type: "line" | "text",
    previousSpace: number,
    spaceConsumed: number
  ) {
    if (!this.preventUpdate) {
      const layouts = structuredClone(this.layouts);
      if (type === "line") {
        let total = 0;
        const currentSpace = this.pageRemainingSpaces[pageIndex];

        if (previousSpace === spaceConsumed) {
          if (currentSpace > previousSpace) {
            total = currentSpace - previousSpace;
          }
        } else {
          total = currentSpace - (spaceConsumed - previousSpace);
        }

        this.pageRemainingSpaces[pageIndex] = total;
      }

      layouts[pageIndex][blockIndex].o = block;
      this.layouts = layouts;
    }
    this.$emit("onEdited", true);
    this.preventUpdate = false;
  }

  setPageSpaceLimits() {
    // const spaceAvailableArr = structuredClone(this.pageRemainingSpaces);
    // if (this.data.length) {
    //   this.data.forEach((page: CoversheetPage, index: number) => {
    //     let totalSpaceUsed = 0;
    //     page.blocks.forEach((block: Block) => {
    //       totalSpaceUsed += block.numberOfItems * block.itemVerticalSpacing;
    //     });
    //     const defaultAvailableSpace =
    //       index === 0
    //         ? this.firstPageDefaultRemainingSpace
    //         : this.otherPageDefaultRemainingSpace;
    //     spaceAvailableArr.push(defaultAvailableSpace - totalSpaceUsed);
    //   });
    // } else {
    //   spaceAvailableArr.push(this.firstPageDefaultRemainingSpace);
    // }
    // this.pageRemainingSpaces = spaceAvailableArr;

    const spaceAvailableArr = structuredClone(this.pageRemainingSpaces);
    if (this.layouts.length) {
      this.layouts.forEach((page: ILayoutItem[], index: number) => {
        const totalBlockSpacing = this.blockSpacing * page.length;

        const defaultAvailableSpace =
          index === 0
            ? this.firstPageDefaultRemainingSpace
            : this.otherPageDefaultRemainingSpace;
        spaceAvailableArr.push(defaultAvailableSpace - totalBlockSpacing);
      });
    } else {
      spaceAvailableArr.push(this.firstPageDefaultRemainingSpace);
    }
    this.pageRemainingSpaces = spaceAvailableArr;
  }

  handleStartDrag(pageIndex: number) {
    this.pageIndex = pageIndex;
  }

  handleClosePreview() {
    this.isPreview = false;
    this.previewBase64 = "";
  }

  handleBlockMoved(pageIndex: number, newLayout: ILayoutItem[][]) {
    this.layouts[pageIndex] = newLayout[pageIndex];
  }

  handleRemovePage(index: number) {
    if (index > -1 && index < this.layouts.length) {
      this.preventUpdate = true;
      const layouts = structuredClone(this.layouts);
      layouts.splice(index, 1);
      this.layouts = layouts;

      const pageRemainingSpaces = structuredClone(this.pageRemainingSpaces);
      pageRemainingSpaces.splice(index, 1);
      this.pageRemainingSpaces = pageRemainingSpaces;

      this.removePageOpen = false;
    }
  }

  handleRemoveBlock(pageIndex: number, blockID: number, spaceToRemove: number) {
    if (blockID) {
      this.preventUpdate = true;
      const layouts = structuredClone(this.layouts);
      const page = layouts[pageIndex].filter(
        (item: ILayoutItem) => item.id != blockID
      );
      layouts[pageIndex] = page;
      this.layouts = layouts;

      const pageRemainingSpaces = structuredClone(this.pageRemainingSpaces);
      pageRemainingSpaces[pageIndex] += spaceToRemove;
      this.pageRemainingSpaces = pageRemainingSpaces;
    }
  }

  async handlePreview() {
    try {
      this.isPreview = true;
      this.generatingPreview = true;

      const pages = this.handleComposePageData();
      const payload: ICoversheet = {
        className: this.className,
        assignmentName: this.assignmentName,
        numberOfStudents: 1,
        pages,
      };

      const response = await this.bulkUploadService.previewCoversheetTemplate(
        payload
      );

      if (response.data.data) {
        const responseData = response.data.data;
        this.previewBase64 = responseData.coversheet;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.generatingPreview = false;
    }
  }

  handleAddPage(e: Event) {
    e.preventDefault();
    if (this.layouts.length < 5) {
      this.pageRemainingSpaces.push(this.otherPageDefaultRemainingSpace);
      this.layouts.push([]);
    }
  }

  async handleSaveCoversheet() {
    try {
      const invalid = this.pageRemainingSpaces.find((num: number) => num < 0);
      if (invalid) {
        this.showMessage(
          "error",
          "No Space Available",
          "There are some elements that are overflowing the page, please reduce text or lines"
        );
        return;
      }

      this.$emit("onSaving", true);
      const pages = this.handleComposePageData();
      const payload: ICoversheet = {
        className: this.className,
        assignmentName: this.assignmentName,
        classUuid: this.classUuid,
        assignmentUuid: this.assignmentUuid,
        pages,
      };

      const response = await this.bulkUploadService.saveCoversheetTemplate(
        payload
      );

      if (response.data.data) {
        this.showMessage(
          "success",
          "Worksheet Saved",
          "Your worksheet has been saved successfully"
        );
        this.$emit("onEdited", false);
        this.$emit("onCloseModal");
        this.eventBus.emit("REFRESH_ASSIGNMENT", this.assignmentUuid);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.$emit("onSaving", false);
    }
  }

  handleAddBlock(pageIndex: number, block: Block) {
    const remainingSpace = this.pageRemainingSpaces[pageIndex];
    const blockInfo = structuredClone(block);

    const spaceConsumed =
      blockInfo.itemVerticalSpacing * blockInfo.numberOfItems;
    const total = remainingSpace - (spaceConsumed + this.blockSpacing);

    if (total < 0) {
      this.showMessage(
        "error",
        "No space available",
        "There isn't any remaining space on this page for another block. Please update your existing blocks or add a new page."
      );
      return;
    }
    this.layouts[pageIndex].push({
      id: this.getRandomFourDigitNumber(),
      x: 0,
      y: this.layouts[pageIndex].length + 1,
      w: 1,
      h: 1,
      i: this.layouts[pageIndex].length.toString(),
      o: blockInfo,
    });

    const spaceArray = structuredClone(this.pageRemainingSpaces);
    spaceArray[pageIndex] = remainingSpace - this.blockSpacing;
    this.pageRemainingSpaces = spaceArray;
  }

  handleComposePageData() {
    const data: CoversheetPage[] = [];
    this.layouts.forEach((pageItem: ILayoutItem[], index: number) => {
      let pageData!: CoversheetPage;
      const blocks: Block[] = [];
      const sorted = pageItem.sort((a, b) => a.y - b.y);

      sorted.forEach((item: ILayoutItem) => blocks.push(item.o));
      pageData = {
        topMargin:
          index === 0 ? this.firstPageMarginTop : this.otherPageMarginTop,
        blockVerticalSpacing: this.blockSpacing,
        blocks: blocks,
      };
      data.push(pageData);
    });
    return data;
  }

  showMessage(type: "error" | "success", summary: string, message: string) {
    this.toast.add({
      severity: type,
      summary: summary,
      detail: message,
      life: 5000,
    });
  }

  getRandomFourDigitNumber() {
    return Math.floor(1000 + Math.random() * 9000);
  }
}
</script>

<style scoped></style>
