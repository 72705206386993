<script lang="ts">
import Calendar from "primevue/calendar";
import { Vue, Component, Model, Prop } from "vue-facing-decorator";
@Component({ components: { Calendar } })
export default class InputDateComponent extends Vue {
  @Prop({
    type: Boolean,
    default: true,
  })
  showButtonBar!: boolean;

  @Prop({
    type: String,
    default: "single",
  })
  selectionMode!: "single" | "multiple" | "range";

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  touchUI!: boolean;

  @Prop({
    type: String,
    default: "Select a date",
  })
  placeholder!: string;

  @Model({
    type: [Date, String],
    default: "",
  })
  value!: string | Date;

  @Prop({
    type: Date,
  })
  maxDate!: Date;
}
</script>
<template>
  <Calendar
    v-model="value"
    :showButtonBar="showButtonBar"
    :selection-mode="selectionMode"
    :disabled="disabled"
    :touch-u-i="touchUI"
    :placeholder="placeholder"
    :maxDate="maxDate ? maxDate : undefined"
    :class="[
      'w-full',
      {
        'p-invalid': invalid,
      },
    ]"
    v-bind="$attrs"
  />
</template>
