<template>
  <div class="flex gap-5 no-drag">
    <div class="w-[80%]">
      <p class="text-flohh-text-body font-bold mb-1 flex gap-2">
        Text (Optional)
        <span
          v-html="icons.infoCircleBlack"
          class="cursor-pointer !w-[20px] !h-[20px]"
          v-tooltip="{ value: 'Leave this blank to show lines only' }"
        />
      </p>
      <input
        class="p-inputtext p-component p-inputtext-sm font-medium bg-flohh-neutral-95 w-full"
        v-model="textModel"
        placeholder="Type your text here"
        type="text"
      />
    </div>
    <div class="w-[20%] min-w-[200px]">
      <p class="text-flohh-text-body font-bold mb-1 flex gap-2">
        Lines (Optional)
        <span
          v-html="icons.infoCircleBlack"
          class="cursor-pointer"
          v-tooltip="{ value: 'Make this 0 to show text only' }"
        />
      </p>
      <div class="flex gap-1 items-stretch">
        <input
          class="p-inputtext p-component p-inputtext-sm font-medium bg-flohh-neutral-95"
          :class="[
            numberOfLinesModel > maxSpace ? '!border-flohh-secondary-red' : '',
          ]"
          v-model="numberOfLinesModel"
          type="number"
          min="0"
          :max="maxSpace"
        />
        <AppButton
          type="transparent"
          label="Fill Page"
          @click="handleSetToMax"
          textColor="text-flohh-primary-pink"
          borderColor="border-flohh-primary-pink"
          size="md"
          class="flex-1"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import { icons } from "@/utils/icons";
import { Block } from "../../type";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { debounceApply } from "@/utils/helper";
import { COVERSHEET_BLOCKS } from "../../data";

@Component({
  components: {
    AppButton,
    FormTextInput,
  },
})
export default class TextAndLinesBlock extends Vue {
  icons = icons;

  @Prop({
    type: Array,
    default: [],
  })
  blockData!: Block;

  @Prop({
    type: Number,
    required: true,
  })
  blockIndex!: number;

  @Prop({
    type: Number,
    required: true,
  })
  pageIndex!: number;

  @Prop({
    type: Number,
    default: 0,
  })
  pageRemainingSpace!: number;

  elementSpacing = COVERSHEET_BLOCKS.space.itemVerticalSpacing;
  textModel = "";
  numberOfLinesModel = 0;
  maxSpace = 0;
  previousSpace = 0;
  type!: "line" | "text";

  @Watch("pageRemainingSpace")
  pageRemainingSpaceWatcher(value: number) {
    this.maxSpace =
      Math.floor(value / this.elementSpacing) + this.numberOfLinesModel;

    const total = this.numberOfLinesModel * this.elementSpacing;
    this.previousSpace = total;
  }

  handleSetToMax() {
    if (this.maxSpace === this.numberOfLinesModel) return;
    const value = this.maxSpace < 0 ? 0 : this.maxSpace;
    this.blockData.numberOfItems = value;
    this.numberOfLinesModel = value;
    this.type = "line";
    this.debouncedFunction();
  }

  debouncedFunction = debounceApply(this.handleUpdateBlockContent, 300);

  handleUpdateBlockContent() {
    const spaceConsumed = this.numberOfLinesModel * this.elementSpacing;

    this.$emit(
      "onBlockContentUpdate",
      this.blockData,
      this.blockIndex,
      this.type,
      this.previousSpace,
      spaceConsumed
    );
  }

  @Watch("numberOfLinesModel")
  numberOfLinesModelWatcher(value: number) {
    this.blockData.numberOfItems = value;
    this.type = "line";
    this.debouncedFunction();
  }

  @Watch("textModel")
  handleInputTextUpdate(value: string) {
    this.blockData.heading = value;
    this.type = "text";
    this.debouncedFunction();
  }

  mounted() {
    this.textModel = this.blockData.heading;
    this.numberOfLinesModel = this.blockData.numberOfItems;
    this.pageRemainingSpaceWatcher(this.pageRemainingSpace);
  }
}
</script>

<style scoped></style>
