<template>
  <div v-if="!routesExcluded.includes(routeName)" class="flex flex-col">
    <Banner :show="getStartedBannerShow" @onHide="handleHideGetStartedBanner">
      <p class="text-flohh-text-subtitle">
        New around here? Learn how to get the most out of Flohh with our
        <button
          class="underline hover:text-black"
          @click="handleOpenQuickStartGuide"
        >
          quick-start guide</button
        >.
      </p></Banner
    >

    <Banner
      v-for="(item, index) in bulkUploads"
      :key="index"
      :show="!getBulkUploadBanner(item.data.assignment)"
      @onHide="() => handleHideBulkUploadBanner(item.data.assignment)"
    >
      <p v-if="item.data.status === 'pending'" class="text-flohh-text-subtitle">
        You have pending bulk upload for
        <button
          class="underline hover:text-black"
          @click="() => handleGoToSplittingPage(item.data.assignment)"
        >
          {{ item.data.assignmentName }}</button
        >.
      </p>
      <p v-else class="text-flohh-text-subtitle">
        Processing bulk upload for
        <button
          class="underline hover:text-black"
          @click="() => handleGoToSplittingPage(item.data.assignment)"
        >
          {{ item.data.assignmentName }}</button
        >.
      </p>
    </Banner>

    <div
      v-for="item in coversheets"
      :key="item.uuid"
      v-show="!getCoversheetBanner(item.uuid)"
      class="flex items-center justify-between px-4 py-4 mx-4 mt-4 rounded-lg bg-flohh-secondary-blue"
    >
      <div class="flex items-center gap-2">
        <span v-html="icons.infoCircleBlack" />
        <p v-if="item.status === 'fulfilled'" class="text-flohh-text-subtitle">
          Your worksheet for
          {{ item.description.split("assignment")[1].trimStart() }}
          is ready to
          <button
            class="underline hover:text-black"
            @click="() => handleDownloadPDF(item.data.media)"
          >
            download</button
          >.
        </p>
      </div>
      <div class="flex gap-3">
        <button
          @click="removeBanner(item.uuid)"
          v-if="item.status === 'fulfilled'"
          v-tooltip.left="{ value: 'Remove banner' }"
        >
          <span v-html="icons.trashBlack" />
        </button>
        <button @click="handleHideCoversheetBanner(item.uuid)">
          <span v-html="icons.closeBlack" />
        </button>
      </div>
    </div>

    <Banner
      v-for="item in announcements"
      :key="item.uuid"
      :show="!getAnnouncementBanner(item.uuid)"
      :allowRemove="true"
      @onHide="() => handleHideAnnouncementBanner(item.uuid)"
      @onRemove="() => removeBanner(item.uuid)"
    >
      <p class="text-flohh-text-subtitle">
        <span v-html="item.data.body" />
      </p>
    </Banner>
    <div
      v-for="item in pdfDocuments"
      :key="item.uuid"
      v-show="!getPDFDownloadsBanner(item.uuid)"
      class="flex items-center justify-between px-4 py-4 mx-4 mt-4 rounded-lg bg-flohh-secondary-blue"
    >
      <div class="flex items-center gap-2">
        <span v-html="icons.infoCircleBlack" />
        <p v-if="item.status === 'pending'" class="text-flohh-text-subtitle">
          The combined submissions file is now processing for download.
        </p>
        <p v-else class="text-flohh-text-subtitle">
          The combined submissions file is ready for download.
          <button
            class="underline hover:text-black"
            @click="() => handleDownloadPDF(item.data.mediaResult)"
          >
            Download now</button
          >.
        </p>
      </div>
      <div class="flex gap-3">
        <button
          @click="removeBanner(item.uuid)"
          v-if="item.status === 'fulfilled'"
          v-tooltip.left="{ value: 'Remove banner' }"
        >
          <span v-html="icons.trashBlack" />
        </button>
        <button
          @click="handleHidePDFDownload(item.uuid)"
          v-tooltip.left="{ value: 'Hide banner' }"
        >
          <span v-html="icons.closeBlack" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from "vue-facing-decorator";
import { PendingActions } from "@/store/dashboard/dashboardTypes";
import { icons } from "@/utils/icons";
import Banner from "./Banner.vue";
import MediaService from "@/services/MediaService";
import DashboardService from "@/services/DashboardService";
import { dataURLtoFile } from "@/utils/helper";

@Component({ components: { Banner } })
export default class Banners extends Vue {
  private mediaService: MediaService = new MediaService();
  private dashboardService: DashboardService = new DashboardService();
  icons = icons;
  getStartedBannerShow = false;
  bulkUploadBannerShow = false;

  @Prop({
    type: Array,
    required: true,
  })
  pendingActions!: PendingActions[];

  bulkUploads: PendingActions[] = [];
  coversheets: PendingActions[] = [];
  announcements: PendingActions[] = [];
  pdfDocuments: PendingActions[] = [];

  routeName = "";
  routesExcluded = ["Marking"];

  async mounted() {
    await this.getPendingActions();
    this.getBulkUploads();
    this.getCoversheets();
    this.getPDFDownloads();
    this.getAnnouncements();

    this.routeName = this.$route.name as string;
  }

  @Watch("pendingActions")
  async pendingActionsWatcher() {
    await this.getPendingActions();
    this.getBulkUploads();
    this.getCoversheets();
    this.getPDFDownloads();
    this.getAnnouncements();

    this.routeName = this.$route.name as string;
  }

  @Watch("$route.name")
  routeNameChanged(value: string) {
    this.routeName = value as string;
  }

  async getPendingActions() {
    const pendingActions = this.pendingActions;

    let getStartedBannerShow = false;

    const getStartedBannerClosed = localStorage.getItem(
      "getStartedBannerClosed"
    );

    const checklistExist = pendingActions.some(
      (item: PendingActions) =>
        item.type === "onboardingDemo" ||
        item.type === "onboardingUploadSubmission" ||
        item.type === "onboardingMarkSubmission" ||
        item.type === "onboardingReturnSubmission"
    );

    if (checklistExist && !getStartedBannerClosed) {
      getStartedBannerShow = true;
    } else {
      getStartedBannerShow = false;
    }

    this.getStartedBannerShow = getStartedBannerShow;
  }

  getBulkUploadBanner(uuid: string) {
    return localStorage.getItem(`bulkUploadBanner-${uuid}`);
  }

  getBulkUploads() {
    const pendingActions = structuredClone(this.pendingActions);
    const bulkUploads = pendingActions.filter(
      (item: PendingActions) => item.type === "bulkUpload"
    );

    this.bulkUploads = bulkUploads;
  }

  handleGoToSplittingPage(uuid: string) {
    this.$router.push({
      name: "BulkUploadSplit",
      params: { type: "split", id: uuid },
    });
  }

  getCoversheetBanner(uuid: string) {
    return localStorage.getItem(`${uuid}-banner`);
  }

  getCoversheets() {
    const pendingActions = structuredClone(this.pendingActions);
    const coversheets = pendingActions.filter(
      (item: PendingActions) =>
        item.type === "coversheet" && item.status === "fulfilled"
    );

    this.coversheets = coversheets;
  }

  handleHideCoversheetBanner(uuid: string) {
    localStorage.setItem(`${uuid}-banner`, "true");
    const updatedCoversheets = this.coversheets.filter(
      (item: PendingActions) => item.uuid !== uuid
    );
    this.coversheets = updatedCoversheets;
  }

  handleGoToBulkUploadPage(classUuid: string, assignmentUuid: string) {
    this.$router.push({
      name: "BulkUpload",
      params: { type: "paper" },
      query: { class: classUuid, assignment: assignmentUuid },
    });
  }

  getAnnouncementBanner(uuid: string) {
    return localStorage.getItem(`${uuid}-banner`);
  }

  getAnnouncements() {
    const pendingActions = this.pendingActions;
    const announcements = pendingActions.filter(
      (item: PendingActions) => item.type === "metricMessageAnnouncement"
    );

    this.announcements = announcements;
  }

  handleHideAnnouncementBanner(uuid: string) {
    localStorage.setItem(`${uuid}-banner`, "true");
    const updatedAnnouncements = this.announcements.filter(
      (item: PendingActions) => item.uuid !== uuid
    );
    this.announcements = updatedAnnouncements;
  }

  handleOpenQuickStartGuide() {
    const publicSiteURL = process.env.VUE_APP_PUBLIC_SITE_URL;
    window.open(`${publicSiteURL}/docs-category/getting-started/`, "_blank");
  }

  handleHideGetStartedBanner() {
    localStorage.setItem("getStartedBannerClosed", "true");
    this.getStartedBannerShow = false;
  }

  handleHideBulkUploadBanner(uuid: string) {
    localStorage.setItem(`${uuid}-banner`, "true");
    const updatedBulkUploads = this.bulkUploads.filter(
      (item: PendingActions) => item.data.assignment !== uuid
    );
    this.bulkUploads = updatedBulkUploads;
  }

  getPDFDownloadsBanner(uuid: string) {
    return localStorage.getItem(`${uuid}-banner`);
  }

  getPDFDownloads() {
    const pendingActions = this.pendingActions;
    const pdfDocuments = pendingActions.filter(
      (item: PendingActions) => item.type === "pdfCombineDocument"
    );
    this.pdfDocuments = pdfDocuments;
  }

  handleHidePDFDownload(uuid: string) {
    localStorage.setItem(`${uuid}-banner`, "true");
    const updatedPdfDocuments = this.pdfDocuments.filter(
      (item: PendingActions) => item.uuid !== uuid
    );

    this.pdfDocuments = updatedPdfDocuments;
  }

  async handleDownloadPDF(mediaUuid: string) {
    try {
      const response = await this.mediaService.getMedia(mediaUuid);
      let fileType = "application/pdf";

      if (response.data.ok) {
        const responseData = response.data.data;
        const linkSource = `data:${fileType};base64,${responseData.data}`;
        const fileName = `${responseData.name.split(".")[0]}.pdf`;

        const file = dataURLtoFile(linkSource, fileName);
        const blob = new Blob([file], { type: fileType });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async removeBanner(pendingActionUuid: string) {
    try {
      const response = await this.dashboardService.removePendingAction(
        pendingActionUuid
      );

      if (response.data.ok) {
        this.handleHidePDFDownload(pendingActionUuid);
        this.handleHideCoversheetBanner(pendingActionUuid);
        this.handleHideAnnouncementBanner(pendingActionUuid);
      }
    } catch (error) {
      console.error(error);
    }
  }
}
</script>
