import axios, { AxiosRequestConfig } from "axios";
import { TELEMETRY_HEAD } from "@/utils/telemetry/data";

const MEDIA_BASE_URL = "/media";

export default class MediaService {
  postMedia(payload: FormData, config?: AxiosRequestConfig) {
    return axios.post(MEDIA_BASE_URL, payload, {
      ...config,
      headers: TELEMETRY_HEAD.upload,
    });
  }
  getMedia(id: string) {
    return axios.get(`${MEDIA_BASE_URL}/${id}`);
  }

  getSubmissionFile(mediaUuid: string, submissionUuid: string) {
    return axios.get(
      `/submissions/${submissionUuid}${MEDIA_BASE_URL}/${mediaUuid}/download`,
      {
        responseType: "blob",
        headers: TELEMETRY_HEAD.download,
      }
    );
  }

  downloadMetricReport(mediaUuid: string) {
    return axios.get(`${MEDIA_BASE_URL}/report/${mediaUuid}`, {
      responseType: "blob",
      headers: TELEMETRY_HEAD.download,
    });
  }
}
