import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d8a6f02e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pl-[25px] pr-[18px] py-[15px] sticky top-[80px]" }
const _hoisted_2 = { class: "flex flex-row gap-x-[8px] justify-center items-center" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectClass = _resolveComponent("SelectClass")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_FormDateTimePicker = _resolveComponent("FormDateTimePicker")!
  const _component_CommentBankListModal = _resolveComponent("CommentBankListModal")!
  const _component_CriteriaSheetListModal = _resolveComponent("CriteriaSheetListModal")!
  const _component_CriteriaSheetTemplateModal = _resolveComponent("CriteriaSheetTemplateModal")!
  const _component_CommentABankPopUp = _resolveComponent("CommentABankPopUp")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_FormField, {
        label: "Class",
        required: true,
        class: "mb-[15px]"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_SelectClass, {
              modelValue: _ctx.selectedClassModel,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedClassModel) = $event)),
              disabled: _ctx.isEdit,
              classLabel: "+ Create Class",
              defaultValue: _ctx.selectedClassModel ? _ctx.selectedClassModel : 'create',
              onOnClassSelect: _ctx.handleOnClassSelect
            }, null, 8, ["modelValue", "disabled", "defaultValue", "onOnClassSelect"])
          ])
        ]),
        _: 1
      }),
      (_ctx.selectedClassModel === 'create')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_AppTypographyText, {
              label: "What should we call your class?",
              variant: "bd",
              type: "small",
              class: "mb-4"
            }),
            _createVNode(_component_FormField, {
              placeholder: "i.e. 'Year 9 English'",
              modelValue: _ctx.classNameModel,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.classNameModel) = $event)),
              background: false,
              error: _ctx.error?.class,
              required: ""
            }, null, 8, ["modelValue", "error"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_FormField, {
        label: "Due",
        error: _ctx.error?.dueTimestamp,
        required: true,
        toolTip: "The due date of your assignment will be visible to both you and your students.",
        class: "mb-[15px]"
      }, {
        default: _withCtx(() => [
          (_ctx.selectedDate)
            ? (_openBlock(), _createBlock(_component_FormDateTimePicker, {
                key: 0,
                modelValue: _ctx.selectedDate,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedDate) = $event)),
                selectedTimezone: _ctx.selectedTimezone,
                onOnSelect: _ctx.handleDueDate,
                isEdit: _ctx.isEdit
              }, null, 8, ["modelValue", "selectedTimezone", "onOnSelect", "isEdit"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["error"]),
      _createVNode(_component_FormField, {
        label: "Duration",
        placeholder: "In Minutes",
        error: _ctx.error?.duration,
        modelValue: _ctx.timeSubmissionModel,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.timeSubmissionModel) = $event)),
        type: "text",
        required: true,
        isNumber: true,
        background: false,
        toolTip: "What is 'duration'? This is the average time it takes you to mark a single student submission. For example it may typically take you 20 minutes to mark a student submission.\n          \n          How does Flohh use your average marking time? Flohh uses the average marking time that you set to give you statistics on your overall time saved.",
        class: "mb-[15px]",
        unit: 
        _ctx.timeSubmissionModel == '1' && _ctx.timeSubmissionModel ? 'minute' : 'minutes'
      
      }, null, 8, ["error", "modelValue", "unit"]),
      _createVNode(_component_CommentBankListModal, {
        modelValue: _ctx.commentBankModalOpen,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.commentBankModalOpen) = $event)),
        currentCommentBank: _ctx.selectedCommentBank,
        handleCommentBankData: _ctx.handleCommentBankData,
        handleEditCommentBank: _ctx.handleEditCommentBank,
        handleCreateCommentBank: _ctx.handleCreateCommentBank,
        handleDetachCB: _ctx.handleDetachCommentBank,
        isEdit: _ctx.isEdit,
        isCopy: _ctx.commentBankCopy
      }, null, 8, ["modelValue", "currentCommentBank", "handleCommentBankData", "handleEditCommentBank", "handleCreateCommentBank", "handleDetachCB", "isEdit", "isCopy"]),
      _createVNode(_component_CriteriaSheetListModal, {
        modelValue: _ctx.criteriaSheetModalOpen,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.criteriaSheetModalOpen) = $event)),
        handleCriteriaSheetData: _ctx.handleCriteriaSheetData,
        currentCriteriaSheet: _ctx.criteriaSheetBankData,
        handleEditCriteriaSheet: _ctx.handleEditCriteriaSheet,
        handleDetachCS: _ctx.handleDetachCriteriaSheet
      }, null, 8, ["modelValue", "handleCriteriaSheetData", "currentCriteriaSheet", "handleEditCriteriaSheet", "handleDetachCS"])
    ]),
    (_ctx.showCriteriaTemplateOpen)
      ? (_openBlock(), _createBlock(_component_CriteriaSheetTemplateModal, {
          key: 0,
          modelValue: _ctx.showCriteriaTemplateOpen,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showCriteriaTemplateOpen) = $event)),
          criteriaSheetData: _ctx.criteriaSheetData,
          toggleModal: _ctx.handleCriteriaSheetTemplate,
          handleSelectedCriteriaSheet: _ctx.handleSelect,
          isEdit: _ctx.criteriaSheetEdit,
          preview: _ctx.criteriaSheetPreview,
          assignmentUuid: _ctx.assignmentUuid,
          isAttach: true
        }, null, 8, ["modelValue", "criteriaSheetData", "toggleModal", "handleSelectedCriteriaSheet", "isEdit", "preview", "assignmentUuid"]))
      : _createCommentVNode("", true),
    (_ctx.showCommentBankOpen)
      ? (_openBlock(), _createBlock(_component_CommentABankPopUp, {
          key: 1,
          modelValue: _ctx.showCommentBankOpen,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showCommentBankOpen) = $event)),
          commentDetails: _ctx.commentDetails,
          commentBankList: _ctx.commentBankList,
          isEdit: _ctx.commentBankEdit,
          isView: _ctx.commentBankView,
          isCopy: _ctx.commentBankCopy,
          isPreview: _ctx.commentBankPreview,
          isAttach: true,
          handleEditView: _ctx.handleEditView,
          handleSaveCommentBank: _ctx.handleSelectedCommentBank
        }, null, 8, ["modelValue", "commentDetails", "commentBankList", "isEdit", "isView", "isCopy", "isPreview", "handleEditView", "handleSaveCommentBank"]))
      : _createCommentVNode("", true)
  ], 64))
}