<script lang="ts">
import { icons as AppIcons } from "@/utils/icons";
import InputText from "primevue/inputtext";
import { Vue, Component, Model, Prop, Watch } from "vue-facing-decorator";

@Component({ components: { InputText } })
export default class FormTextInput extends Vue {
  @Model({
    type: [String, Number],
    default: "",
  })
  value!: string;

  @Prop({
    type: String,
    default: "text",
  })
  type!: string;

  @Prop({
    type: String,
    default: "small",
  })
  size!: "small" | "large";

  @Prop({
    type: String,
    default: "Input text",
  })
  placeholder!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  @Prop({
    type: String,
    default: "input",
  })
  name!: string;

  @Prop({
    type: String,
    default: "",
  })
  iconLeft!: string;

  @Prop({
    type: String,
    default: "",
  })
  iconRight!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  isNumber!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  background!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  unit!: string;

  @Prop({
    type: String,
    required: false,
  })
  inputClass!: string;

  toggleType = "password";
  eyeIcon = "";

  icon = AppIcons;

  @Watch("value")
  valueWachter(value: string) {
    if (this.isNumber) {
      if (!value) return "";
      this.value = value.replace(/[^0-9]/g, "");

      // if (this.value.startsWith("0")) {
      //   this.value = "";
      // }
      return;
    }
  }
  handleToggePass() {
    if (this.iconRight.includes("eye")) {
      if (this.toggleType === "name") {
        this.toggleType = "password";
        this.eyeIcon = "eyeActive";
      } else {
        this.toggleType = "name";
        this.eyeIcon = "eyeInActive";
      }
    }
  }
}
</script>
<template>
  <div class="w-full relative" v-bind="$attrs">
    <InputText
      :type="type === 'password' ? toggleType : type"
      :size="size"
      v-model="value"
      :readonly="readonly"
      :disabled="disabled"
      :placeholder="placeholder"
      v-bind="$attrs"
      :class="[
        'w-full h-[47px] text-flohh-text-body font-flohh-font-medium px-[24px] py-[24px]',
        {
          'p-invalid': invalid,
          'bg-flohh-neutral-95': background,
        },
        iconRight && iconLeft ? 'with-icon-left-right' : '',
        iconRight && !iconLeft ? 'with-icon-right' : '',
        !iconRight && iconLeft ? 'with-icon-left' : '',
        !iconRight && !iconLeft ? 'form-text-padding' : '',
        inputClass,
      ]"
    />
    <div
      class="absolute left-[24px] top-[50%] translate-y-[-50%] w-4"
      v-if="iconLeft"
    >
      <span v-html="icon[iconLeft]"></span>
    </div>
    <div
      @click="handleToggePass"
      class="absolute right-[24px] top-[12px] w-4 cursor-pointer opacity-80"
    >
      <span v-html="icon[eyeIcon ? eyeIcon : iconRight]"></span>
    </div>
    <div class="unit">
      <span class="invisible text-flohh-text-body">{{ value }}</span>
      <span
        v-if="value"
        class="units-value text-flohh-neutral-70 text-flohh-text-body font-poppins"
      >
        {{ unit }}</span
      >
    </div>
  </div>
</template>
<style lang="scss" scoped>
.with-icon-left {
  padding-left: 45px !important;
  padding-right: 24px !important;

  &.pl-50 {
    padding-left: 50px !important;
  }
}

.with-icon-right {
  padding-left: 24px !important;
  padding-right: 45px !important;
}

.with-icon-left-right {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.form-text-padding {
  padding: 16px 24px !important;
}

.unit {
  position: absolute;
  top: 0;
  left: 0;
  right: 10px;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
  display: flex;
}

.invisible {
  visibility: hidden;
  padding-left: 30px;
}

.units-value {
  white-space: pre;
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
