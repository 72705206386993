import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex gap-5 no-drag" }
const _hoisted_2 = { class: "w-[80%]" }
const _hoisted_3 = { class: "text-flohh-text-body font-bold mb-1 flex gap-2" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "w-[20%] min-w-[200px]" }
const _hoisted_6 = { class: "text-flohh-text-body font-bold mb-1 flex gap-2" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "flex gap-1 items-stretch" }
const _hoisted_9 = ["max"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, [
        _createTextVNode(" Text (Optional) "),
        _withDirectives(_createElementVNode("span", {
          innerHTML: _ctx.icons.infoCircleBlack,
          class: "cursor-pointer !w-[20px] !h-[20px]"
        }, null, 8, _hoisted_4), [
          [_directive_tooltip, { value: 'Leave this blank to show lines only' }]
        ])
      ]),
      _withDirectives(_createElementVNode("input", {
        class: "p-inputtext p-component p-inputtext-sm font-medium bg-flohh-neutral-95 w-full",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textModel) = $event)),
        placeholder: "Type your text here",
        type: "text"
      }, null, 512), [
        [_vModelText, _ctx.textModel]
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("p", _hoisted_6, [
        _createTextVNode(" Lines (Optional) "),
        _withDirectives(_createElementVNode("span", {
          innerHTML: _ctx.icons.infoCircleBlack,
          class: "cursor-pointer"
        }, null, 8, _hoisted_7), [
          [_directive_tooltip, { value: 'Make this 0 to show text only' }]
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _withDirectives(_createElementVNode("input", {
          class: _normalizeClass(["p-inputtext p-component p-inputtext-sm font-medium bg-flohh-neutral-95", [
            _ctx.numberOfLinesModel > _ctx.maxSpace ? '!border-flohh-secondary-red' : '',
          ]]),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.numberOfLinesModel) = $event)),
          type: "number",
          min: "0",
          max: _ctx.maxSpace
        }, null, 10, _hoisted_9), [
          [_vModelText, _ctx.numberOfLinesModel]
        ]),
        _createVNode(_component_AppButton, {
          type: "transparent",
          label: "Fill Page",
          onClick: _ctx.handleSetToMax,
          textColor: "text-flohh-primary-pink",
          borderColor: "border-flohh-primary-pink",
          size: "md",
          class: "flex-1"
        }, null, 8, ["onClick"])
      ])
    ])
  ]))
}