import { IUserNotifcation } from "@/modules/UserNotifications/Store/types";
import axios from "axios";
import { GenerateReportPayload } from "@/components/SuperAdmin/types";

const METRIC_MESSAGE_URL = "/super-admin/metric-messages";
const BASE_URL = "/super-admin";

export default class SuperAdminService {
  downloadTeachersList() {
    return axios.get(`/reports/teachers`);
  }

  uploadTeachers(payload: any) {
    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("schoolName", payload.schoolName);
    formData.append("country", payload.country);
    formData.append("state", payload.state);
    return axios.post(`${BASE_URL}/teachers/bulk`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  putStudent(payload: any, studentUuid: string) {
    return axios.put(`${BASE_URL}/students/${studentUuid}`, payload);
  }

  getStudents() {
    return axios.get(`${BASE_URL}/students`);
  }

  getTeacherActiveSubscription(uuid: string) {
    return axios.get(`${BASE_URL}/teachers/subscription/active/${uuid}`);
  }

  getMetricReports() {
    return axios.get("/super-admin/reports");
  }

  generateMetricReport(payload: GenerateReportPayload) {
    return axios.post("/super-admin/reports", payload);
  }

  deleteMetricReport(uuid: string) {
    return axios.delete(`/super-admin/reports/${uuid}`);
  }

  getAllMetricMessages() {
    return axios.get(METRIC_MESSAGE_URL);
  }

  getMetricMessages(uuid: string) {
    return axios.get(`${METRIC_MESSAGE_URL}/${uuid}`);
  }

  postMetricMessages(payload: IUserNotifcation) {
    return axios.post(METRIC_MESSAGE_URL, payload);
  }

  putMetricMessages(uuid: string, payload: IUserNotifcation) {
    return axios.put(`${METRIC_MESSAGE_URL}/${uuid}`, payload);
  }

  deleteMetricMessages(uuid: string) {
    return axios.delete(`${METRIC_MESSAGE_URL}/${uuid}`);
  }
}
