<template>
  <div class="p-10 flex flex-col gap-10">
    <div class="h-[500px]">
      <PdfViewComponent :pdfBase64="base64" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import PdfViewComponent from "@/components/BulkUpload/PdfViewComponent.vue";

@Component({
  components: {
    PdfViewComponent,
    AppButton,
  },
})
export default class PreviewCoversheet extends Vue {
  @Prop({
    type: String,
    default: "",
  })
  base64!: string;
}
</script>

<style scoped></style>
