import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Textarea = _resolveComponent("Textarea")!

  return (_openBlock(), _createBlock(_component_Textarea, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    placeholder: _ctx.placeholder,
    autoResize: _ctx.autoResize,
    rows: _ctx.rows,
    cols: _ctx.cols,
    readonly: _ctx.readonly,
    disabled: _ctx.disabled,
    class: _normalizeClass([
      'w-full text-flohh-text-body font-flohh-font-medium',
      {
        'p-invalid': _ctx.invalid,
        'bg-flohh-neutral-95': _ctx.background,
      },
      _ctx.customClass,
    ])
  }, null, 8, ["modelValue", "placeholder", "autoResize", "rows", "cols", "readonly", "disabled", "class"]))
}