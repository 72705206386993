import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!

  return (_openBlock(), _createBlock(_component_Calendar, _mergeProps({
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    showButtonBar: _ctx.showButtonBar,
    "selection-mode": _ctx.selectionMode,
    disabled: _ctx.disabled,
    "touch-u-i": _ctx.touchUI,
    placeholder: _ctx.placeholder,
    maxDate: _ctx.maxDate ? _ctx.maxDate : undefined,
    class: [
      'w-full',
      {
        'p-invalid': _ctx.invalid,
      },
    ]
  }, _ctx.$attrs), null, 16, ["modelValue", "showButtonBar", "selection-mode", "disabled", "touch-u-i", "placeholder", "maxDate", "class"]))
}