<template>
  <div
    v-if="show"
    class="flex items-center justify-between px-4 py-4 mx-4 mt-4 rounded-lg bg-flohh-secondary-blue"
  >
    <div class="flex items-center gap-2 custom-banner">
      <span v-html="icons[icon]" />
      <slot></slot>
    </div>
    <div class="flex gap-3">
      <button v-if="allowRemove" @click="removeBanner">
        <span v-html="icons.trashBlack" />
      </button>
      <button @click="hideBanner">
        <span v-html="icons.closeBlack" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-facing-decorator";
import { icons } from "@/utils/icons";

@Component({ components: {} })
export default class Banners extends Vue {
  icons = icons;

  @Prop({
    type: String,
    default: "infoCircleBlack",
  })
  icon!: string;

  @Prop({
    type: Boolean,
    required: true,
  })
  show!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  allowRemove!: boolean;

  hideBanner() {
    this.$emit("onHide");
  }

  removeBanner() {
    this.$emit("onRemove");
  }
}
</script>

<style lang="scss">
.custom-banner a {
  text-decoration: underline;
}
</style>
