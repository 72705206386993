<template>
  <div class="mb-5">
    <AppTypographyText
      label="Download and print class coversheets and worksheets for automatic splitting"
      variant="bd"
      type="title"
      class="mb-5"
    />
    <AppTypographyText
      v-if="!hasStudents"
      label="How many students are in your class?"
      variant="bd"
      type="body"
      class="mb-1"
    />
    <div class="flex gap-5">
      <FormField
        v-if="!hasStudents"
        v-model="studentCount"
        :options="options"
        optionLabel="label"
        option-value="value"
        type="select"
        :required="true"
        :background="false"
      />
      <AppButton
        v-if="assignmentData"
        :class="hasStudents ? 'w-full' : 'min-w-[350px]'"
        type="primary"
        :loading="type === 'generate' && downloadingCoversheets"
        :disabled="type === 'generate' && downloadingCoversheets"
        @click.prevent="handleGenerateCoversheets"
      >
        Generate {{ hasStudents ? "" : studentCount }}
        {{
          studentCount === 1 ? "Coversheet/Worksheet" : "Coversheets/Worksheets"
        }}
      </AppButton>
      <AppButton
        v-if="assignmentData"
        :class="hasStudents ? 'w-full' : 'min-w-[350px]'"
        type="submit"
        :loading="type === 'download' && downloadingCoversheets"
        :disabled="
          (type === 'download' && downloadingCoversheets) ||
          !assignmentData.coversheet ||
          (assignmentData.coversheet && !assignmentData.coversheet.media)
        "
        @click.prevent="handleDownloadCoversheets"
      >
        <template #icon_left>
          <span v-html="icon.iconDownload"></span>
        </template>
        Download Coversheets/Worksheets
      </AppButton>
    </div>
  </div>

  <ModalUtility
    v-model="isInfoModalOpen"
    width="35vw"
    title="Generating Coversheets/Worksheets"
    @onClose="isInfoModalOpen = false"
  >
    <template #content>
      <div class="p-7">
        <p class="text-flohh-text-body">
          {{ modalContent }}
        </p>
      </div>
    </template>
    <template #footer>
      <div
        class="pt-5 border-t border-solid border-flohh-neutral-85 flex justify-end"
      >
        <AppButton type="submit" @click.prevent="isInfoModalOpen = false">
          Okay
        </AppButton>
      </div>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import FormField from "@/components/Layout/Forms/FormField.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import emitter from "@/config/emitter";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import { ClassData } from "@/store/class/classTypes";
import { AssignmentDashboard } from "@/store/dashboard/dashboardTypes";

interface StudentCount {
  label: string;
  value: number;
}

@Component({
  components: {
    AppTypographyText,
    FormField,
    AppButton,
    ModalUtility,
  },
})
export default class DownloadCoversheetsComponent extends Vue {
  icon = icons;
  eventBus = emitter;

  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  hasStudents!: boolean;

  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  downloadingCoversheets!: boolean;

  @Prop({
    type: Object,
    required: true,
  })
  classData!: ClassData;

  @Prop({
    type: Object,
    required: true,
  })
  assignmentData!: AssignmentDashboard;

  @Watch("downloadingCoversheets")
  downloadingCoversheetsWatcher(value: boolean, oldValue: boolean) {
    if (oldValue && !value && this.type === "generate") {
      this.isInfoModalOpen = true;
    }
  }

  modalContent = "";

  studentCount = 1;
  options: StudentCount[] = [];

  type!: "generate" | "download";
  isInfoModalOpen = false;

  mounted() {
    this.populateOptions();
  }

  populateOptions() {
    const options: StudentCount[] = [];
    for (let i = 1; i <= 40; i++) {
      options.push({ label: i.toString(), value: i });
    }
    this.options = options;
  }

  async handleDownloadCoversheets() {
    this.modalContent =
      "Your worksheets are being processed. We'll add a banner at the top of the page when they are ready to download. Click refresh to check.";
    this.type = this.assignmentData.coversheet ? "download" : "generate";
    const studentCount = this.hasStudents ? -1 : this.studentCount;
    this.$emit("onDownloadCoversheets", studentCount);
  }

  handleGenerateCoversheets() {
    const assignmentName = this.assignmentData.title;
    const className = this.classData.code;
    this.modalContent = `Your coversheets/worksheets for the assignment ${assignmentName} in ${className} are currently being generated. This may take a few minutes. Refresh your page and we will display the download link here when it is done, we will also show a banner at the top of your screen so you can navigate away and be notified when it is ready.`;
    this.type = "generate";
    const studentCount = this.hasStudents ? -1 : this.studentCount;
    const isGenerate = true;
    this.$emit("onDownloadCoversheets", studentCount, isGenerate);
  }
}
</script>

<style scoped lang="scss"></style>
