import { AssignmentState } from "./assignmentTypes";

const state: AssignmentState = {
  assigmentsByClass: [],
  selectedAssignment: {
    assignmentType: "",
    class: {
      code: "",
      createdBy: "",
      inviteCode: "",
      students: [],
      studentUuids: [],
      subject: "",
      uuid: "",
      yearLevel: "",
      teacher: "",
      teacherUuid: "",
    },
    component: {
      fileName: "",
      media: "",
      mimeType: "",
    },
    createdBy: "",
    dueTimestamp: "",
    timezoneName: "",
    estimatedTimeToMark: {
      unit: "",
      value: 0,
    },
    title: "",
    uuid: "",
    visible: false,
    coversheet: {
      media: "",
      settings: [],
    },
  },
  students: [],
  assignmentSubmissionSummary: {
    assignment: {
      assignmentType: "",
      class: {
        code: "",
        createdBy: "",
        inviteCode: "",
        students: [],
        studentUuids: [],
        subject: "",
        uuid: "",
        yearLevel: "",
        teacher: "",
        teacherUuid: "",
      },
      classUuid: "",
      component: {
        fileName: "",
        media: "",
        mimeType: "",
      },
      createdBy: "",
      dueTimestamp: "",
      estimatedTimeToMark: {
        unit: "",
        value: 0,
      },
      title: "",
      uuid: "",
    },
    completedSubmissions: 0,
    students: [],
    submissionsMade: 0,
    totalStudents: 0,
  },
  selectedStudentSummary: {
    student: {
      email: "",
      assignments: [],
      profile: {
        email: "",
        firstName: "",
        lastName: "",
        status: "",
        user: "",
        uuid: "",
      },
      profileUuid: "",
      studentId: "",
      uuid: "",
    },
    submission: {
      assignment: "",
      assignmentUuid: "",
      createdBy: "",
      firstName: "",
      lastName: "",
      revisions: [],
      student: "",
      submittedBy: "",
      uuid: "",
      status: "",
      submittedAt: "",
    },
  },
  selectedAssignmentDetails: {
    title: "",
    fileInstruction: null,
    textInstruction: "",
    classUuid: "",
    className: "",
    dueTimestamp: "",
    duration: 0,
    timezoneName: "",
    isCommentBankEdited: false,
    isCriteriaSheetEdited: false,
  },
  warningModal: {
    redirectTo: "",
    modalType: "",
    isOpen: false,
    skipWarning: false,
  },
};

export default state;
