<template>
  <div>
    <div class="flex justify-between items-center">
      <h6 class="text-flohh-h6 font-flohh-font-bold">
        Scanned Paper Upload & Splitting
      </h6>
      <a href="#" @click="toggleInstructions" class="underline"
        ><AppTypographyText
          :label="
            instructionsVisible ? 'Hide instructions' : 'Show instructions'
          "
          variant="md"
          type="caption"
      /></a>
    </div>
    <div class="py-6">
      <hr />
    </div>
    <InstructionsComponent v-if="instructionsVisible" />
    <AppTypographyText
      label="Use the options below to download your coversheets and upload scanned submissions. If you have used the Flohh Worksheet Builder in your assignment to build a worksheet for your assignment task, the button below will automatically create the unqiue worksheet for each of your students. "
      variant="md"
      type="body"
      class="mb-5"
    />
    <div class="bulk-upload-tabs">
      <TabView v-model:activeIndex="activeTab">
        <TabPanel :header="item.heading" v-for="(item, key) in tabs" :key="key">
          <div class="h-full w-full">
            <component
              :is="item.component"
              :hasStudents="hasStudents"
              :submitting="submitting"
              :downloadingCoversheets="downloadingCoversheets"
              :classData="classData"
              :assignmentData="assignmentData"
              @onDownloadCoversheets="handleDownloadCoversheets"
              @onUploadAndSplit="handleUploadAndSplit"
            />
          </div>
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import { useToast } from "primevue/usetoast";
import InstructionsComponent from "./PaperSubmission/InstructionsComponent.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import DownloadCoversheetsComponent from "./PaperSubmission/DownloadCoversheetsComponent.vue";
import UploadSubmissionsComponent from "./PaperSubmission/UploadSubmissionsComponent.vue";
import { ClassData } from "@/store/class/classTypes";
import { AssignmentDashboard } from "@/store/dashboard/dashboardTypes";

@Component({
  components: {
    AppButton,
    AppTypographyText,
    ModalUtility,
    InstructionsComponent,
    TabView,
    TabPanel,
    DownloadCoversheetsComponent,
    UploadSubmissionsComponent,
  },
})
export default class PaperSubmission extends Vue {
  toast = useToast();
  icon = icons;

  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  hasStudents!: boolean;

  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  downloadingCoversheets!: boolean;

  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  submitting!: boolean;

  @Prop({
    type: Object,
    required: true,
  })
  classData!: ClassData;

  @Prop({
    type: Object,
    required: true,
  })
  assignmentData!: AssignmentDashboard;

  instructionsVisible = true;
  activeTab = 0;

  tabs = [
    {
      heading: "Download Class Coversheets",
      component: DownloadCoversheetsComponent,
    },
    {
      heading: "Upload Scanned Submissions",
      component: UploadSubmissionsComponent,
    },
  ];

  @Watch("activeTab")
  activeTabWatcher(value: number) {
    this.$emit("onTabChange", value);
  }

  toggleInstructions() {
    this.instructionsVisible = !this.instructionsVisible;
  }

  handleDownloadCoversheets(studentCount: number, isGenerate = false) {
    this.$emit("onDownloadCoversheets", studentCount, isGenerate);
  }

  handleUploadAndSplit(files: File[]) {
    this.$emit("onUploadAndSplit", files);
  }

  showToastMessage(severity: "error" | "success", message: string) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }
}
</script>

<style lang="scss">
$color: #f9b2ce;
.bulk-upload-tabs {
  .p-tabview {
    .p-tabview-nav {
      background: transparent;
      // exact width 530
      border-bottom-width: 4px;
      li {
        &:hover {
          .p-tabview-nav-link {
            border-color: $color !important;
            border-bottom-width: 4px;
          }
        }
        &.p-highlight {
          .p-tabview-nav-link {
            border-color: $color;
            color: $color;
            border-bottom-width: 4px;
          }
        }
        .p-tabview-nav-link {
          background: transparent !important;
          box-shadow: none !important;
          border-bottom-width: 4px;
        }
      }
    }
    .p-tabview-panels {
      background-color: transparent;
      padding: 25px 0 0 0 !important;
    }
  }
  .bordered {
    border: 1px solid #d9d9d9;
  }
}
</style>
