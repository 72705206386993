import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "p-10 flex flex-col gap-10" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "w-full flex flex-col gap-5" }
const _hoisted_4 = { class: "flex justify-between mb-2" }
const _hoisted_5 = { class: "text-flohh-h6" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 0,
  class: "flex flex-col gap-5"
}
const _hoisted_9 = { class: "flex justify-between items-center" }
const _hoisted_10 = { class: "ml-auto flex gap-2" }
const _hoisted_11 = {
  key: 1,
  class: "flex flex-col gap-5"
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "ml-auto flex gap-2" }
const _hoisted_14 = { class: "px-5 pt-5" }
const _hoisted_15 = {
  key: 1,
  class: "flex justify-center items-center"
}
const _hoisted_16 = { class: "text-default text-neutral-gray-500 flex font-bold items-center justify-start" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { class: "border-t border-solid border-neutral-gray-500 py-4 px-6" }
const _hoisted_19 = { class: "text-sm text-neutral-gray-500" }
const _hoisted_20 = { class: "py-2 px-6 flex items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_IdentificationBlock = _resolveComponent("IdentificationBlock")!
  const _component_BuilderBlock = _resolveComponent("BuilderBlock")!
  const _component_GridLayout = _resolveComponent("GridLayout")!
  const _component_PreviewCoversheet = _resolveComponent("PreviewCoversheet")!
  const _component_ProgressLoader = _resolveComponent("ProgressLoader")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_AppButton, {
          onClick: _ctx.handleAddPage,
          type: "submit",
          label: "Add Page",
          iconLeft: "plusBlack",
          size: "md"
        }, null, 8, ["onClick"]),
        _createVNode(_component_AppButton, {
          onClick: _ctx.handlePreview,
          type: "submit"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Preview Coversheet")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layouts, (page, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "rounded-lg border border-flohh-neutral-85 border-solid p-5"
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("strong", null, [
                _createElementVNode("h5", _hoisted_5, "PAGE " + _toDisplayString(index + 1), 1)
              ]),
              (index !== 0)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    onClick: 
              () => {
                _ctx.removePageNumber = index + 1;
                _ctx.removePageOpen = true;
              }
            ,
                    class: "hover:scale-110"
                  }, [
                    _createElementVNode("span", {
                      innerHTML: _ctx.icons.trashSmallRedIcon
                    }, null, 8, _hoisted_7)
                  ], 8, _hoisted_6))
                : _createCommentVNode("", true)
            ]),
            (page.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  (index === 0)
                    ? (_openBlock(), _createBlock(_component_IdentificationBlock, { key: 0 }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_GridLayout, {
                    layout: _ctx.layouts[index],
                    "col-num": 1,
                    "is-draggable": _ctx.isDraggable,
                    "is-resizable": _ctx.isResizable,
                    "is-bounded": _ctx.isBounded,
                    "vertical-compact": _ctx.isVerticalCompact
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BuilderBlock, {
                        pageIndex: index,
                        layouts: _ctx.layouts,
                        pageRemainingSpace: _ctx.pageRemainingSpaces[index],
                        onOnBlockMoved: _ctx.handleBlockMoved,
                        onOnStartDrag: _ctx.handleStartDrag,
                        onOnRemoveBlock: _ctx.handleRemoveBlock,
                        onOnBlockContentUpdate: _ctx.handleUpdateBlockContent
                      }, null, 8, ["pageIndex", "layouts", "pageRemainingSpace", "onOnBlockMoved", "onOnStartDrag", "onOnRemoveBlock", "onOnBlockContentUpdate"])
                    ]),
                    _: 2
                  }, 1032, ["layout", "is-draggable", "is-resizable", "is-bounded", "vertical-compact"]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("p", {
                      class: _normalizeClass(["text-flohh-text-body", [
                {
                  'text-flohh-secondary-red':
                    Math.floor(
                      _ctx.pageRemainingSpaces[index] / _ctx.itemVerticalSpacing
                    ) < 0,
                },
              ]])
                    }, " Remaining space left on this page: " + _toDisplayString(Math.floor(_ctx.pageRemainingSpaces[index] / _ctx.itemVerticalSpacing)) + " lines ", 3),
                    _createElementVNode("div", _hoisted_10, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.blocksBP), (objKey, i) => {
                        return (_openBlock(), _createBlock(_component_AppButton, {
                          key: i,
                          onClick: () => _ctx.handleAddBlock(index, _ctx.blocksBP[objKey]),
                          type: "primary"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.blocksBP[objKey].name), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      }), 128))
                    ])
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  (index === 0)
                    ? (_openBlock(), _createBlock(_component_IdentificationBlock, { key: 0 }))
                    : (_openBlock(), _createElementBlock("p", _hoisted_12, "Please add block to display here")),
                  _createElementVNode("div", _hoisted_13, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.blocksBP), (objKey, i) => {
                      return (_openBlock(), _createBlock(_component_AppButton, {
                        key: i,
                        onClick: () => _ctx.handleAddBlock(index, _ctx.blocksBP[objKey]),
                        type: "primary"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.blocksBP[objKey].name), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ])
                ]))
          ]))
        }), 128)),
        (_ctx.layouts.length < 5)
          ? (_openBlock(), _createBlock(_component_AppButton, {
              key: 0,
              type: "transparent",
              label: "Add Page",
              onClick: _ctx.handleAddPage,
              iconLeft: "plusPink",
              textColor: "text-flohh-primary-pink",
              borderColor: "border-flohh-primary-pink",
              size: "md"
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ], 512), [
      [_vShow, !_ctx.isPreview]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_AppButton, {
          onClick: _withModifiers(_ctx.handleClosePreview, ["prevent"]),
          type: "submit",
          class: "ml-auto"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Close Preview")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      (_ctx.previewBase64)
        ? (_openBlock(), _createBlock(_component_PreviewCoversheet, {
            key: 0,
            base64: _ctx.previewBase64
          }, null, 8, ["base64"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createVNode(_component_ProgressLoader, { label: "Loading..." })
          ]))
    ], 512), [
      [_vShow, _ctx.isPreview]
    ]),
    _createVNode(_component_Dialog, {
      visible: _ctx.removePageOpen,
      modal: "",
      closable: false,
      "close-on-escape": true,
      style: { width: '20vw', minWidth: '300px' },
      pt: {
      header: { class: '!py-4 !pl-6' },
      content: { class: '!p-0' },
      footer: { class: '!p-0' },
    }
    }, {
      header: _withCtx(() => [
        _createElementVNode("h5", _hoisted_16, [
          _createElementVNode("i", {
            innerHTML: _ctx.icons.trashBlack,
            class: "mr-2"
          }, null, 8, _hoisted_17),
          _createTextVNode("Remove Page ")
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_20, [
          _createVNode(_component_Button, {
            label: "Cancel",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.removePageOpen = false), ["prevent"])),
            class: "border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
          }),
          _createVNode(_component_Button, {
            label: "Remove",
            onClick: _cache[1] || (_cache[1] = _withModifiers(() => _ctx.handleRemovePage(_ctx.removePageNumber - 1), ["prevent"])),
            class: "border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
          })
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("p", _hoisted_19, " Are you sure you want to remove page " + _toDisplayString(_ctx.removePageNumber) + "? ", 1)
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}