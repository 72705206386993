<template>
  <div
    class="onboarding w-screen h-screen bg-[url('@/assets/pattern.png')] bg-fixed bg-flohh-green-600 bg-[length:100%_auto] bg-repeat flex items-center justify-center [&>..p-component-overlay]:!bg-transprent"
  >
    <div
      class="login-container flex py-20 items-center justify-center w-full max-w-[600px] max-w-[ 70%] px-5 mx-auto"
    >
      <div
        class="rounded-md flex flex-col justify-center items-center px-6 md:px-10 pt-5 bg-white w-full"
      >
        <div
          class="flex flex-col w-full items-center justify-center p-4 relative z-[1]"
        >
          <img
            src="@/assets/flohh-logo.svg"
            class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
          />
        </div>
        <Divider />
        <section class="w-full md:max-w-[90%] lg:max-w-[90%]">
          <template v-if="!showLoader && !redirectToDashboard">
            <section class="pb-3 w-full">
              <div class="pt-2 text-center">
                <p
                  class="text-flohh-text-title color-flohh-neutral-20 font-semibold"
                >
                  Help us to find your school
                </p>
              </div>
              <div class="w-full py-6 rounded-b-[4px]">
                <div class="pb-[24px]">
                  <p class="font-medium text-flohh-text-body mb-1">
                    What country do you live in?
                  </p>
                  <Dropdown
                    v-model="selectedCountry"
                    :options="countries"
                    optionLabel="name"
                    placeholder="Select your country"
                    class="w-full h-[49px] bg-flohh-neutral-95 pl-[14px] font-medium"
                    @change="
                      () => {
                        getCities();
                      }
                    "
                  />
                </div>
                <div class="pb-[24px]">
                  <p class="font-medium text-flohh-text-body mb-1">
                    What state do you live in?
                  </p>
                  <Dropdown
                    placeholder="Select your state"
                    v-model="selectState"
                    :options="states"
                    optionLabel="name"
                    class="w-full h-[49px] bg-flohh-neutral-95 pl-[14px] font-medium"
                    style="font-size: 14px !important"
                    v-if="showStateDropdown"
                  />

                  <FormTextInput
                    type="text"
                    name="selectState"
                    placeholder="Enter your state"
                    v-model="selectState"
                    v-if="!showStateDropdown"
                  />
                </div>
                <div class="pb-[24px]">
                  <p class="font-medium text-flohh-text-body mb-1">
                    What is the name of your school?
                  </p>
                  <FormTextInput
                    type="text"
                    name="school"
                    placeholder="Enter your school"
                    v-model="school"
                  />
                </div>
                <div class="pb-[24px]">
                  <p
                    class="font-medium text-flohh-text-body mb-1 flex gap-[5px]"
                  >
                    Enter your mobile number
                    <span
                      v-html="icons.infoCircleBlack"
                      class="[&>svg]:w-[14px] cursor-pointer"
                      v-tooltip.left="{
                        value: `Why do we need your mobile number? We’ll use your mobile number to contact you for customer support related activities only. We won’t use your mobile number or any marketing related activities or share your mobile with 3rd parties.`,
                        showDelay: 500,
                      }"
                    ></span>
                  </p>
                  <div class="flex gap-[15px] cls-contact">
                    <Dropdown
                      v-model="selectCountryCode"
                      :options="countryCodes"
                      optionLabel="dial_code"
                      optionValue="dial_code"
                      class="w-[110px] h-[49px] bg-flohh-neutral-95 pl-[14px] font-medium"
                      style="font-size: 14px !important"
                    />
                    <FormTextInput
                      type="text"
                      name="contactNumber"
                      v-model="contactNumber"
                      placeholder=""
                      :isNumber="true"
                    />
                  </div>
                </div>

                <div class="flex mt-4 justify-end items-center">
                  <AppButton
                    v-bind="$attrs"
                    type="submit"
                    :disabled="
                      !selectedCountry ||
                      (!selectState && showStateDropdown) ||
                      !school ||
                      !selectCountryCode ||
                      !contactNumber
                    "
                    @click="handleSubmit"
                  >
                    <span v-html="icons.checkBlack" class="mr-2"></span>
                    Take me to my dashboard
                  </AppButton>
                </div>
              </div>
            </section>
          </template>
          <template v-if="redirectToDashboard">
            <div
              class="flex-column align-center justify-center items-center text-center w-full bg-white py-6 px-10"
            >
              <p>Redirecting to dashboard...</p>
              <ProgressSpinner class="!w-[50px]" />
            </div>
          </template>
          <div
            v-if="showLoader && !redirectToDashboard"
            class="flex-column align-center justify-center items-center text-center w-full bg-white py-6 px-10"
          >
            <p>{{ loaderMesage }}</p>
            <ProgressLoader />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import RegistrationOnboardingComponent from "@/components/Authentication/Registration/RegistrationOnboardingComponent.vue";
import ModalCardComponent from "@/components/Modal/ModalCardComponent.vue";
import AutocompleteComponent from "@/components/Input/Autocomplete/AutocompleteComponent.vue";
import ButtonComponent from "@/components/Button/ButtonComponent.vue";
import TextInputComponent from "@/components/Input/TextInput/TextInputComponent.vue";
import COUNTRIES from "../../utils/countries.json";
import COUNTRY_CODES from "../../utils/countryCodes.json";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";

import Dropdown from "primevue/dropdown";
import Divider from "primevue/divider";
import InputText from "primevue/inputtext";

import axios, { AxiosResponse } from "axios";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";

interface ICountry {
  name: string;
  iso2: string;
}

interface IStates {
  name: string;
  state_code: string;
}

@Component({
  components: {
    RegistrationOnboardingComponent,
    ModalCardComponent,
    AutocompleteComponent,
    TextInputComponent,
    ButtonComponent,
    Dropdown,
    Divider,
    InputText,
    AppButton,
    FormTextInput,
    ProgressLoader,
  },
})
export default class RegistrationOnboardingView extends Vue {
  //
  toast = useToast();

  showStep1Modal = true;
  showStep2Modal = false;
  showCustomCloseButton = false;
  isModal = false;
  // countries = COUNTRIES;
  states: IStates[] = [];
  statesLoaded = false;

  selectedCountry!: ICountry;
  school!: string;
  selectState!: IStates | null;

  icons = AppIcons;

  showLoader = true;
  loaderMesage = "Loading required data";
  testCountries = null;
  countries: any = [];
  countryLoaded = false;
  redirectToDashboard = false;
  showStateDropdown = true;

  selectCountryCode!: string;
  countryCodes = COUNTRY_CODES;
  contactNumber = "";

  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams) {
      const urlParams = new URLSearchParams(window.location.search);
      this.school = urlParams.get("schoolName") || "";
    }
    this.getCountries();
  }

  async getCountries() {
    this.loaderMesage = `Loading required data`;
    const apiUrl = "/lookup/countries-and-states";
    const response: AxiosResponse = await axios.get(apiUrl);
    this.showLoader = false;
    this.countryLoaded = true;
    if (!response.data.error) {
      this.countries = response.data.data;
    } else {
      console.error("Failed to retrieve data");
    }
  }

  handleView() {
    this.selectState = null;
    this.school = "";
    // this.selectState = ""; // add this to reset selected state when user click back button : ERROR, dropdown value return [Object object]
    this.showStep1Modal = true;
    this.showStep2Modal = false;
    this.states = [];
  }

  formatCountries() {
    // this.countries = this.countries.sort((a, b) =>
    //   a.name.localeCompare(b.name)
    // );
    // return this.countries.map((o) => {
    //   return {
    //     id: o.iso2,
    //     value: `${o.name}`,
    //     display: `${o.name}`,
    //     isVisible: true,
    //   };
    // });
  }

  formateStates() {
    if (this.states) {
      this.states = this.states.sort((a, b) => a.name.localeCompare(b.name));
      return this.states.map((o) => {
        return {
          id: o.state_code,
          value: `${o.state_code}`,
          display: `${o.name}`,
          isVisible: true,
        };
      });
    }
  }

  // this.testCountries = this.formatCountries();

  handleAutocomplete(value: string) {
    // this.selectedCountry = value;
  }

  handleInput(event: Event) {
    const target = event.target as HTMLInputElement;
    this.school = target.value;
  }

  async handleSubmit() {
    try {
      this.loaderMesage = `Processing teacher. Please wait.`;
      this.showLoader = true;
      const payload = {
        schoolName: this.school ? this.school : undefined,
        schoolAddress: {
          country: this.selectedCountry?.name,
          state: this.states.length > 0 ? this.selectState?.state_code : "",
          address1: "",
          address2: "",
          suburb: "",
        },
        contactNumber: {
          number: this.contactNumber || "",
          countryCode: this.selectCountryCode || "",
        },
      };

      if (
        payload.schoolName === "" &&
        payload.schoolAddress.state === "" &&
        payload.schoolAddress.country === "" &&
        payload.contactNumber.number === "" &&
        payload.contactNumber.countryCode === ""
      ) {
        this.showLoader = false;
        this.showError("Please fill up all fields to proceed.");
        return;
      }

      //teachers/:teacherId
      const auth: any = localStorage.getItem("auth");
      // const teacherId = auth ? JSON.parse(auth).user.uuid : null;
      const responseTeacher: any = await axios.get("/teachers/me");
      if (responseTeacher.data.ok) {
        const teacherId = responseTeacher.data.data.uuid;
        const response: AxiosResponse = await axios.put(
          `/teachers/${teacherId}`,
          payload
        );
        if (response.data.ok) {
          this.redirectToDashboard = true;
          localStorage.setItem("onboarding", "hide");
          window.location.href = "/dashboard";
        } else {
          this.showLoader = false;
          console.error(response.data.message);
          this.showError(response.data.message);
        }
      } else {
        this.showLoader = false;
        console.error(responseTeacher.data.message);
        this.showError(responseTeacher.data.message);
      }
    } catch (e) {
      //
      this.showLoader = false;
      // this.showError(e.data.message);
    } finally {
      //
      // this.showLoader = false;
    }
  }

  async getCities() {
    // Define the URL
    if (this.selectedCountry && this.selectedCountry.name) {
      this.selectState = null;
      this.statesLoaded = true;
      const country = this.countries.find(
        (o: any) => o.name === this.selectedCountry?.name
      );
      if (country) {
        this.states = country.states;
        this.showStateDropdown = this.states.length > 0;
        const cc = COUNTRY_CODES.find((o: any) => o.code === country.iso2);
        console.log(cc);
        if (cc) {
          this.selectCountryCode = cc.dial_code;
        }
      }
    }
  }

  private showError(message: string) {
    this.toast.add({
      severity: "error",
      detail: message,
      life: 3000,
    });
  }
}
</script>

<style lang="scss">
/* .p-dropdown {
  :deep(.p-inputtext) {
    font-size: 14px !important;
  }
} */

/* DROPDOWN: force to use non-scoped style here, dropdown item is not scoped in this component */
.p-dropdown-item,
.p-inputtext {
  font-size: 14px !important;
}

.cls-contact {
  .p-dropdown {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .p-dropdown .p-dropdown-label {
    padding-right: 0 !important;
  }
}
</style>
