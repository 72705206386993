<script lang="ts">
import { Vue, Component, Prop, Model } from "vue-facing-decorator";
import AppTypographyText from "../Typhography/AppTypographyText.vue";
import FormTextInput from "./FormTextInput.vue";
import FormTextareaInput from "./FormTextareaInput.vue";
import FormErrorSpan from "@/components/Layout/Forms/FormErrorSpan.vue";
import FormDatePicker from "@/components/Layout/Forms/FormDatePicker.vue";
import FormInputDropdown from "./FormInputDropdown.vue";
import { icons as AppIcons } from "@/utils/icons";
@Component({
  components: {
    AppTypographyText,
    FormTextInput,
    FormTextareaInput,
    FormErrorSpan,
    FormDatePicker,
    FormInputDropdown,
  },
})
export default class FormField extends Vue {
  icons = AppIcons;
  @Prop({
    type: String,
    required: true,
  })
  label!: string;

  @Prop({
    type: String,
    required: false,
  })
  error!: string;

  @Prop({
    type: String,
    default: "text",
  })
  type!: string;

  @Prop({
    type: Boolean,
    required: false,
  })
  required!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  name!: string;

  @Prop({
    type: String,
    default: "",
  })
  placeholder!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  isNumber!: boolean;

  @Prop({
    type: Number,
    default: 5,
  })
  rows!: number;

  @Prop({
    type: Number,
    default: 30,
  })
  cols!: number;

  @Prop({
    type: Boolean,
    default: true,
  })
  autoResize!: boolean;

  @Prop({
    type: String,
    required: false,
    default: "",
  })
  caption!: string;

  @Prop({
    type: String,
    required: false,
    default: "",
  })
  subCaption!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  subLabel!: string;

  @Prop({
    type: Array,
    required: false,
  })
  options!: [];

  @Prop({
    type: String,
  })
  optionLabel!: string;

  @Prop({
    type: String,
  })
  optionValue!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  filter!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  loading!: boolean;

  @Prop({
    type: String,
    default: "",
    required: false,
  })
  iconLeft!: string;

  @Prop({
    type: String,
    default: "",
    required: false,
  })
  iconRight!: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  background!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disablePast!: boolean;

  @Prop({
    type: String,
    default: "",
    required: false,
  })
  toolTip!: string;

  @Model({
    type: [String, Date],
  })
  model!: string | Date;

  @Prop({
    type: String,
    default: "",
  })
  unit!: string;
}
</script>
<template>
  <div class="w-full" v-bind="$attrs">
    <div
      class="flex gap-x-2 my-[8px] justify-start items-center"
      v-if="label || subLabel"
    >
      <AppTypographyText
        variant="bd"
        type="title"
        v-if="label"
        :label="label"
        class="my-[8px]"
      >
        <slot name="label"></slot>
      </AppTypographyText>
      <AppTypographyText
        class="text-flohh-neutral-70"
        v-if="subLabel"
        variant="rg"
        type="caption"
      >
        {{ subLabel }}
      </AppTypographyText>
      <div
        v-if="toolTip"
        v-tooltip.top="{
          value: toolTip,
          showDelay: 500,
          pt: {
            text: 'font-normal text-sm w-[150%] left-[-40px] relative',
          },
        }"
        class="cursor-pointer"
      >
        <span v-html="icons.tooltipQuestionMark"></span>
      </div>
    </div>
    <AppTypographyText
      v-if="caption"
      class="my-[8px]"
      variant="rg"
      type="small"
    >
      {{ caption }}
    </AppTypographyText>
    <AppTypographyText
      v-if="subCaption"
      class="my-[8px]"
      variant="rg"
      type="small"
    >
      {{ subCaption }}
    </AppTypographyText>
    <slot>
      <FormTextInput
        v-model="model"
        v-if="type === 'text'"
        :placeholder="placeholder"
        :invalid="error && required ? true : false"
        :name="name"
        :readonly="readonly"
        :isNumber="isNumber"
        :disabled="disabled"
        :iconLeft="iconLeft"
        :iconRight="iconRight"
        :background="background"
        :unit="unit"
      />
      <FormTextareaInput
        v-model="model"
        :placeholder="placeholder"
        :invalid="error && required ? true : false"
        :name="name"
        :rows="rows"
        :cols="cols"
        :readonly="readonly"
        :autoResize="autoResize"
        v-if="type === 'textarea'"
        :background="background"
      />
      <FormDatePicker
        :invalid="error && required ? true : false"
        v-if="type === 'date'"
        :placeholder="placeholder"
        :readonly="readonly"
        v-model="model"
        :disablePast="disablePast"
        :background="background"
      />
      <FormInputDropdown
        v-if="type === 'select'"
        v-model="model"
        :options="options"
        :optionLabel="optionLabel"
        :option-value="optionValue"
        :invalid="error && required ? true : false"
        :placeholder="placeholder"
        :filter="filter"
        :loading="loading"
        :disabled="disabled"
        :background="background"
      >
        <template #option="slotProps">
          <slot name="option" :slotProps="slotProps" />
        </template>
        <template #value="slotProps" v-if="$slots.value">
          <slot name="value" :value="slotProps.value" />
        </template>
      </FormInputDropdown>
    </slot>
    <FormErrorSpan v-if="error">
      {{ error }}
    </FormErrorSpan>
  </div>
</template>
