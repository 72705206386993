<template>
  <template v-if="!isMarkingScreen">
    <FormField label="Grading">
      <div
        v-if="selectedCriteriaSheetName"
        class="bg-flohh-primary-pink rounded-md"
      >
        <div
          class="flex py-[12px] px-[20px] justify-between items-center gap-2"
        >
          <Ellipsis :content="selectedCriteriaSheetName" />
          <AppDropdownButton
            size="xs"
            @clickMenu="handleSelectMenu"
            :menus="statuses"
            width="w-25"
            text
            dropdownIcon="moreOptionBlack"
            :loading="detaching"
            :disabled="detaching"
            class="!px-0 !w-[15px]"
          />
        </div>
      </div>
      <AppButton
        v-else
        type="transparent"
        label="Criteria Sheet"
        @click="handleAddCriteriaSheet"
        iconLeft="plusPink"
        textColor="text-flohh-primary-pink"
        borderColor="border-flohh-primary-pink"
        size="md"
      />
    </FormField>
  </template>
  <template v-if="isMarkingScreen">
    <AppButton type="submit" @click="visible = true">
      <span v-html="icons.plusBlack"></span>
      Add criteria sheet
    </AppButton>
  </template>
  <ModalUtility
    v-model="visible"
    width="614px"
    title="Select a Criteria Sheet Bank"
    @onClose="visible = false"
    :draggable="false"
  >
    <template #content>
      <div class="px-[28px] py-[30px] h-[364px] overflow-auto">
        <div v-if="!listLoading" class="flex flex-col">
          <div
            class="flex gap-2 items-center w-full px-4 py-4 hover:bg-flohh-neutral-95 cursor-pointer"
            v-for="(item, index) in list"
            :key="index"
            :class="[
              {
                'bg-flohh-neutral-95': item.uuid === selectedCriteriaSheet,
              },
            ]"
            @click="handleCriteriaSheetClick(item.uuid)"
          >
            <FormRadioButton
              v-model="selectedCriteriaSheet"
              :name="index.toString()"
              :inputId="index.toString()"
              :value="item.uuid"
            />
            <label
              :for="item.name"
              class="font-flohh-font-medium text-flohh-text-body cursor-pointer"
              >{{ item.name }}</label
            >
          </div>
        </div>
        <div v-else>
          <ProgressLoader />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex pt-5 border-t border-solid border-flohh-neutral-85">
        <div class="flex-1 flex justify-start items-center">
          <AppButton @click="handleManageCriteriaSheets" text blackLabel>
            Manage Criteria Sheets
          </AppButton>
        </div>
        <div class="flex-1 flex justify-end items-center gap-x-4">
          <AppButton
            type="submit"
            @click="handleClickSelect"
            :disabled="!selectedCriteriaSheet"
            :class="!selectedCriteriaSheet ? 'opacity-[0.5]' : ''"
          >
            <template #icon_left>
              <span v-html="icons.checkBlack"></span>
            </template>
            Select
          </AppButton>
        </div>
      </div>
    </template>
  </ModalUtility>

  <DetachDialogComponent
    v-model="detachModalOpen"
    title="Detach Criteria Sheet"
    :body="`Are you sure you want to detach '${selectedCriteriaSheetName}' to this assignment?`"
    @onConfirmDetach="handleDetachCriteriaSheet"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Ref, Model, Watch } from "vue-facing-decorator";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import AppDropdownButton from "@/components/Layout/Buttons/AppDropdownButton.vue";
import Ellipsis from "@/components/utilities/Ellipsis.vue";
import { useToast } from "primevue/usetoast";
import CreateAssignment from "@/components/Assignment/CreateAssignment/CreateAssignment.vue";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import { icons } from "@/utils/icons";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import CriteriaSheetService from "@/services/CriteriaSheetService";
import FormRadioButton from "@/components/Layout/Forms/FormRadioButton.vue";
import SelectGrading from "@/components/utilities/SelectGrading.vue";
import CriteriaSheetComponent from "@/components/PSPDFKit/CriteriaSheetComponent.vue";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import FormField from "@/components/Layout/Forms/FormField.vue";
import emitter from "@/config/emitter";
import DetachDialogComponent from "./DetachDialogComponent.vue";
import { IAttachedResource } from "@/models/CriteriaSheet";
import { AxiosResponse } from "axios";

interface CriteriaSheetList {
  uuid: string;
  name: string;
}

@Component({
  components: {
    AppTypographyText,
    AppDropdownButton,
    Ellipsis,
    CreateAssignment,
    ModalUtility,
    AppButton,
    FormRadioButton,
    SelectGrading,
    CriteriaSheetComponent,
    ProgressLoader,
    FormField,
    DetachDialogComponent,
  },
})
export default class CriteriaSheetListModal extends Vue {
  private criteriaSheetService = new CriteriaSheetService();
  toast = useToast();
  eventBus = emitter;

  @Model({
    type: Boolean,
    required: true,
  })
  visible!: boolean;

  @Prop({
    type: Object,
  })
  currentCriteriaSheet: CriteriaSheetList = {
    uuid: "",
    name: "",
  };

  @Prop({
    type: Function,
  })
  handleCriteriaSheetData!: (uuid: string) => void;

  @Prop({
    type: Function,
  })
  handleEditCriteriaSheet!: (value: string) => void;

  @Prop({
    type: Function,
  })
  handleDetachCS!: (uuid: string, name: string) => void;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  isMarkingScreen!: boolean;

  icons = icons;
  list: CriteriaSheetList[] = [];
  listLoading = true;
  selectedCriteriaSheet = "";
  selectedCriteriaSheetName = "";
  templates = [];

  isSaveModalOpen = false;
  unsavedRedirectTo = "";

  detaching = false;
  detachModalOpen = false;
  statuses = [
    {
      name: "Edit",
    },
    {
      name: "Detach",
    },
  ];

  @Watch("currentCriteriaSheet")
  async currentCriteriaSheetWatcher(value: CriteriaSheetList) {
    if (value) {
      // await this.fetchCriteriaSheetList();
      const item = this.list.find(
        (o: CriteriaSheetList) => o.uuid === value.uuid
      );
      if (item) {
        item.name = value.name;
      } else {
        this.list.push({
          uuid: value.uuid,
          name: value.name,
        });
      }
      this.selectedCriteriaSheet = value.name;

      this.list = this.list.filter((o) => o.name);
      const selectedCriteriaSheetData = this.list.find(
        (item: CriteriaSheetList) =>
          item.uuid === value.uuid && item.name === value.name
      );
      if (selectedCriteriaSheetData) {
        this.selectedCriteriaSheetName = selectedCriteriaSheetData.name;
      } else {
        this.selectedCriteriaSheetName = "";
      }
      return;
    }
  }

  async fetchCriteriaSheetList() {
    try {
      this.listLoading = true;
      const res = await this.criteriaSheetService.getCriteriaSheets();
      this.list = [];
      if (res.data.ok) {
        const templates = res.data.data;
        this.templates = templates;
        templates.map((item: any) => {
          const obj: CriteriaSheetList = {
            uuid: item.sheetDetails.uuid,
            name: item.sheetDetails.name,
          };

          this.list.push(obj);
        });

        this.listLoading = false;
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleAddCriteriaSheet() {
    this.visible = true;
    this.eventBus.emit("EVENT_TRIGGER", "AM005");
  }

  mounted() {
    this.eventBus.on("CLOSE_CRITERIA_SHEET_LIST_MODAL", () => {
      this.visible = false;
    });
    this.eventBus.on("CLEAR_FIELDS", () => {
      this.handleUnselectCriteriaSheet();
    });

    this.eventBus.on("REFRESH_CRITERIA_SHEET_LIST", () => {
      this.fetchCriteriaSheetList();
    });
    this.fetchCriteriaSheetList();
  }

  handleEdit() {
    this.handleEditCriteriaSheet(this.currentCriteriaSheet.uuid);
    // this.handleCriteriaSheetData(this.currentCriteriaSheet.uuid);
  }

  handleCriteriaSheetClick(uuid: string) {
    this.selectedCriteriaSheet = uuid;
  }

  async handleManageCriteriaSheets() {
    if (this.isMarkingScreen) {
      await this.$emit("saveMarking");
    }
    this.$router.push({ path: "/criteria-sheet-bank" });
  }

  handleClickSelect() {
    if (this.selectedCriteriaSheet) {
      this.handleCriteriaSheetData(this.selectedCriteriaSheet);
    } else {
      this.showMessage(
        "Invalid",
        "Please select criteria sheet first",
        "error"
      );
    }
  }

  handleUnselectCriteriaSheet() {
    this.selectedCriteriaSheet = "";
    this.selectedCriteriaSheetName = "";
    this.handleCriteriaSheetData("");
  }

  handleDetach() {
    if (this.$route.name === "EditAssignment") {
      this.detachModalOpen = true;
    } else {
      this.setStateAfterDetach();
    }
  }

  async handleDetachCriteriaSheet() {
    try {
      this.detaching = true;
      const assignmentUuid = this.$route.params.id as string;

      const payload: IAttachedResource = {
        resourceId: this.currentCriteriaSheet.uuid,
        resourceType: "criteria sheet",
        targetId: assignmentUuid,
        targetType: "assignment",
      };

      const response: AxiosResponse =
        await this.criteriaSheetService.detachedCriteriaSheetAssignment(
          payload
        );
      if (response.data.ok) {
        this.setStateAfterDetach();
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.detaching = false;
    }
  }

  setStateAfterDetach() {
    this.selectedCriteriaSheetName = "";
    this.selectedCriteriaSheet = "";

    this.showMessage(
      "Success",
      "Criteria Sheet Detached from this assignment",
      "success"
    );
    this.handleDetachCS("", "");
  }

  handleSelectMenu(action: string) {
    if (action === "Edit") {
      this.handleEdit();
    } else if (action === "Detach") {
      this.handleDetach();
    }
  }

  showMessage(
    summary: string,
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      summary: summary,
      severity: severity,
      detail: message,
      life: 3000,
    });
  }
}
</script>

<style scoped lang="scss"></style>
