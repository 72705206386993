<script lang="ts">
import { Vue, Component, Prop, Emit } from "vue-facing-decorator";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import DefaultFileUploader from "../utilities/DefaultFileUploader.vue";
import { Icon, icons } from "@/utils/icons";
import AppButton from "../Layout/Buttons/AppButton.vue";
import ClassService from "@/services/ClassService";
import { ClassInfo } from "@/store/createClass/types";
import emitter from "@/config/emitter";
import { useToast } from "primevue/usetoast";
import * as XLSX from "xlsx";
import { AxiosResponse } from "axios";

interface FailedEmails {
  error: string;
  name: string;
  email: string;
}

@Component({
  components: {
    AppTypographyText,
    DefaultFileUploader,
    AppButton,
  },
})
export default class CreateClassUploadPopup extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  selectedClass!: ClassInfo;

  private classService = new ClassService();

  selectedFileModel: File[] = [];
  icon: Icon = icons;
  loadingImport = false;
  eventBus = emitter;
  duplicateEmails: string[] = [];
  hasDuplicates = false;
  toast = useToast();
  classUuid = "";
  failedEmails: FailedEmails[] = [];

  handleRemoveFile() {
    this.$store.dispatch("createClassPopup/removeFile");
    this.duplicateEmails = [];
    this.failedEmails = [];
    this.selectedFileModel = [];
  }

  async handleClickSave() {
    await this.updateColumnHeadings();
    if (this.selectedClass && this.selectedClass.uuid) {
      this.classUuid = this.selectedClass.uuid;
      if (this.selectedFileModel.length === 0) {
        this.showMessage(
          "error",
          "CSV file not found",
          "Attach your list of student CSV file"
        );
        return;
      }

      if (
        this.selectedFileModel[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        await this.checkForDuplicateEmails(this.selectedFileModel[0]);
      } else if (
        this.selectedFileModel[0].type === "text/csv" ||
        this.selectedFileModel[0].type === "application/vnd.ms-excel"
      ) {
        const content = await this.readCSV(this.selectedFileModel[0]);
        const parseStatus = this.parseCSV(content);
        if (!parseStatus) {
          return;
        }
      } else {
        this.showMessage(
          "error",
          "File Error",
          "Please select CSV or Excel file"
        );
        return;
      }

      if (this.duplicateEmails.length) {
        this.showMessage(
          "error",
          "Duplicate Email",
          "The system found a duplicate email"
        );
        return;
      }
      this.$store.dispatch("createClassPopup/setFile", this.selectedFileModel);
      await this.onSaveFileUploadStudent();
    } else {
      this.$emit("onClassError", true);
    }
  }

  async onSaveFileUploadStudent() {
    try {
      this.loadingImport = true;
      const formData = new FormData();
      const file = structuredClone(this.selectedFileModel[0]);
      formData.append("file", file);
      const response: AxiosResponse =
        await this.classService.postFileImportStudents(
          this.selectedClass.uuid as string,
          formData
        );
      if (response.data.ok) {
        this.eventBus.emit("EVENT_TRIGGER", "CM004");
        const failedEmails = response.data.data.filter(
          (item: Record<string, unknown>) => item.ok && item.ok === false
        );
        this.showMessage(
          "success",
          "Invitation Sent",
          "Students have been invited to the class"
        );
        if (failedEmails.length > 0) {
          const failedEmailsArray: FailedEmails[] = [];
          failedEmails.forEach((item: Record<string, any>) => {
            if (item.message.includes("Student already in class")) {
              failedEmailsArray.push({
                error: item.message,
                name: `${item.info.info.firstName} ${item.info.info.lastName}`,
                email: item.info.info.email,
              });
            }
          });
          this.failedEmails = failedEmailsArray;
        } else {
          this.$emit("onComplete", this.selectedClass.uuid);
          this.handleRemoveFile();
        }
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingImport = false;
    }
  }

  downloadTemplate() {
    const url = "/template/student-template-csv.csv";
    const fileName = "Flohh Class List";

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  readCSV(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target) {
          const content: string = e.target.result as string;
          resolve(content);
        }
      };

      reader.onerror = (e) => {
        reject(e);
      };

      reader.readAsText(file);
    });
  }

  parseCSV(content: string) {
    const rows = content.split("\n");
    const emails: string[] = [];

    try {
      const invalidColumnsError =
        "Please make sure you have the following columns in order: firstName, lastName, email";
      rows.forEach((row, index) => {
        if (row !== "") {
          const columns = row.split(",");
          if (columns.length > 2) {
            if (index === 0) {
              if (!columns.includes("lastName")) {
                throw new Error("Missing column lastName");
              } else if (!columns.includes("firstName")) {
                throw new Error("Missing column firstName");
              } else if (!columns[2].includes("email")) {
                throw new Error("Missing column email");
              }
              if (
                columns[0] !== "firstName" ||
                columns[1] !== "lastName" ||
                !columns[2].includes("email")
              ) {
                throw new Error(invalidColumnsError);
              }
            }
            const email = columns[2].trim();
            emails.push(email);
          } else {
            throw new Error(invalidColumnsError);
          }
        }
      });
    } catch (error: any) {
      this.showMessage("error", "Invalid column", error.message);
      return false;
    }

    // Check for duplicates
    const uniqueEmails: Set<string> = new Set();
    const duplicates: Set<string> = new Set();

    emails.forEach((email) => {
      if (email) {
        if (uniqueEmails.has(email)) {
          duplicates.add(email);
        } else {
          uniqueEmails.add(email);
        }
      }
    });
    // Update the component state with duplicate emails
    this.duplicateEmails = Array.from(duplicates);
    return true;
  }

  private readFileAsync(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        resolve(e.target?.result as ArrayBuffer);
      };

      reader.onerror = (e) => {
        reject(e);
      };

      reader.readAsArrayBuffer(file);
    });
  }

  private async checkForDuplicateEmails(file: File): Promise<void> {
    try {
      const data = await this.readFileAsync(file);
      const workbook = XLSX.read(data, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const rows: any = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const emails: string[] = [];
      const uniqueEmails: Set<string> = new Set();
      const duplicates: Set<string> = new Set();

      for (let i = 1; i < rows.length; i++) {
        const email = rows[i][2] as string;
        emails.push(email);
      }

      emails
        .filter((e) => !!e)
        .forEach((email) => {
          if (uniqueEmails.has(email)) {
            duplicates.add(email);
          } else {
            uniqueEmails.add(email);
          }
        });

      this.duplicateEmails = Array.from(duplicates);
    } catch (error) {
      console.error("Error reading file:", error);
    }
  }

  async updateColumnHeadings() {
    for (const file of this.selectedFileModel) {
      try {
        const content = await this.readFile(file);
        let workbook: XLSX.WorkBook;
        if (typeof content === "string" && file.type === "text/csv") {
          workbook = XLSX.read(content, { type: "string" });
        } else {
          workbook = XLSX.read(content, { type: "array" });
        }

        const sheetName: string = workbook.SheetNames[0];
        const sheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
        const data: any[][] = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
        }) as any[][];

        data[0][0] = "firstName";
        data[0][1] = "lastName";
        data[0][2] = "email";

        const newSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
        workbook.Sheets[sheetName] = newSheet;

        let blob: Blob;
        if (file.type === "text/csv") {
          const csv = XLSX.utils.sheet_to_csv(newSheet);
          blob = new Blob([csv], { type: "text/csv" });
        } else {
          const wbout = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });
          blob = new Blob([wbout], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
        }

        const modifiedFile = new File([blob], file.name, {
          type: file.type,
        });
        const modifiedFiles: File[] = [];
        modifiedFiles.push(modifiedFile);
        this.selectedFileModel = modifiedFiles;
      } catch (error) {
        console.error(error);
      }
    }
  }

  readFile(file: File): Promise<ArrayBuffer | string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && event.target.result) {
          resolve(event.target.result as ArrayBuffer | string);
        }
      };
      reader.onerror = reject;

      if (file.type === "text/csv") {
        reader.readAsText(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    });
  }

  mounted() {
    if (this.$store.state.createClassPopup.file) {
      this.selectedFileModel = structuredClone(
        this.$store.state.createClassPopup.file
      );
    }
  }

  showMessage(type: "error" | "success", summary: string, message: string) {
    this.toast.add({
      severity: type,
      summary: summary,
      detail: message,
      life: 5000,
    });
  }
}
</script>
<template>
  <div class="w-full mt-5">
    <AppTypographyText
      variant="bd"
      type="title"
      label="Upload your class list"
      class="my-[8px]"
    />
    <div v-if="failedEmails.length > 0" class="w-full mt-3">
      <AppTypographyText
        label="Unable to add the following students"
        variant="md"
        type="body"
        class="mb-2"
      />
      <AppTypographyText
        label="Reason: Student already in class"
        variant="md"
        type="caption"
        class="mb-5"
      />
      <AppTypographyText
        v-for="(account, index) in failedEmails"
        :key="index"
        :label="`• ${account.name} (${account.email})`"
        variant="rg"
        type="caption"
        class="pl-2"
      />
    </div>
    <div v-else class="w-full mt-3">
      <AppTypographyText variant="md" type="subtitle" class="mb-3">
        Your class list should have the following columns: First name, Last
        name, and email.
      </AppTypographyText>
      <a
        href="/template/student-template-csv.csv"
        download="Flohh Class List.csv"
        type="csv"
      >
        <AppButton type="primary">
          <template #icon_left>
            <span v-html="icon.iconDownload"></span>
          </template>
          Download a class list template
        </AppButton>
      </a>

      <div class="my-2 mb-5">
        <DefaultFileUploader
          v-model:selectedFiles="selectedFileModel"
          :multiple="false"
          :showFileList="true"
          acceptedFiles=".csv,.xlsx"
          supportedFileTypes="Supported file types: Microsoft excel & CSV"
          @onRemoveFile="handleRemoveFile"
        />
      </div>
      <div class="mb-2 gap-x-2 flex flex-row" v-if="duplicateEmails.length">
        <AppTypographyText
          label="Duplicate Emails:"
          type="caption"
          variant="bd"
        />
        <AppTypographyText
          v-for="(email, index) in duplicateEmails"
          :key="index"
          :label="email"
          variant="md"
          :isError="true"
          type="caption"
        />
      </div>
    </div>
  </div>
  <hr class="my-5" />
  <div class="w-full flex justify-end items-end pb-5">
    <div v-if="failedEmails.length > 0" class="flex justify-between w-full">
      <AppButton text @click="handleRemoveFile"> Go Back </AppButton>
      <AppButton
        type="primary"
        @click="$emit('onComplete', selectedClass.uuid)"
      >
        <template #icon_right>
          <span v-html="icon.arrowForwardBlackIcon"></span>
        </template>
        Go to class page
      </AppButton>
    </div>

    <AppButton
      v-else
      type="submit"
      @click="handleClickSave"
      :loading="loadingImport"
      :disabled="loadingImport"
    >
      <template #icon_left>
        <span v-html="icon.mailBlack"></span>
      </template>
      Send Invite
    </AppButton>
  </div>
</template>
