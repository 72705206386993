<template>
  <div class="pl-[25px] pr-[18px] py-[15px] sticky top-[80px]">
    <FormField label="Class" :required="true" class="mb-[15px]">
      <div class="flex flex-row gap-x-[8px] justify-center items-center">
        <SelectClass
          v-model="selectedClassModel"
          :disabled="isEdit"
          classLabel="+ Create Class"
          :defaultValue="selectedClassModel ? selectedClassModel : 'create'"
          @onClassSelect="handleOnClassSelect"
        />
      </div>
    </FormField>

    <div v-if="selectedClassModel === 'create'">
      <AppTypographyText
        label="What should we call your class?"
        variant="bd"
        type="small"
        class="mb-4"
      />
      <FormField
        placeholder="i.e. 'Year 9 English'"
        v-model="classNameModel"
        :background="false"
        :error="error?.class"
        required
      />
    </div>

    <FormField
      label="Due"
      :error="error?.dueTimestamp"
      :required="true"
      toolTip="The due date of your assignment will be visible to both you and your students."
      class="mb-[15px]"
    >
      <FormDateTimePicker
        v-if="selectedDate"
        v-model="selectedDate"
        :selectedTimezone="selectedTimezone"
        @onSelect="handleDueDate"
        :isEdit="isEdit"
      />
    </FormField>

    <FormField
      label="Duration"
      placeholder="In Minutes"
      :error="error?.duration"
      v-model="timeSubmissionModel"
      type="text"
      :required="true"
      :isNumber="true"
      :background="false"
      toolTip="What is 'duration'? This is the average time it takes you to mark a single student submission. For example it may typically take you 20 minutes to mark a student submission.
          
          How does Flohh use your average marking time? Flohh uses the average marking time that you set to give you statistics on your overall time saved."
      class="mb-[15px]"
      :unit="
        timeSubmissionModel == '1' && timeSubmissionModel ? 'minute' : 'minutes'
      "
    />

    <CommentBankListModal
      v-model="commentBankModalOpen"
      :currentCommentBank="selectedCommentBank"
      :handleCommentBankData="handleCommentBankData"
      :handleEditCommentBank="handleEditCommentBank"
      :handleCreateCommentBank="handleCreateCommentBank"
      :handleDetachCB="handleDetachCommentBank"
      :isEdit="isEdit"
      :isCopy="commentBankCopy"
    />

    <CriteriaSheetListModal
      v-model="criteriaSheetModalOpen"
      :handleCriteriaSheetData="handleCriteriaSheetData"
      :currentCriteriaSheet="criteriaSheetBankData"
      :handleEditCriteriaSheet="handleEditCriteriaSheet"
      :handleDetachCS="handleDetachCriteriaSheet"
    />
  </div>

  <CriteriaSheetTemplateModal
    v-if="showCriteriaTemplateOpen"
    v-model="showCriteriaTemplateOpen"
    :criteriaSheetData="criteriaSheetData"
    :toggleModal="handleCriteriaSheetTemplate"
    :handleSelectedCriteriaSheet="handleSelect"
    :isEdit="criteriaSheetEdit"
    :preview="criteriaSheetPreview"
    :assignmentUuid="assignmentUuid"
    :isAttach="true"
  />
  <CommentABankPopUp
    v-if="showCommentBankOpen"
    v-model="showCommentBankOpen"
    :commentDetails="commentDetails"
    :commentBankList="commentBankList"
    :isEdit="commentBankEdit"
    :isView="commentBankView"
    :isCopy="commentBankCopy"
    :isPreview="commentBankPreview"
    :isAttach="true"
    :handleEditView="handleEditView"
    :handleSaveCommentBank="handleSelectedCommentBank"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Ref, Watch } from "vue-facing-decorator";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import FormInputDropdown from "@/components/Layout/Forms/FormInputDropdown.vue";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import FormField from "@/components/Layout/Forms/FormField.vue";
import SelectClass from "@/components/utilities/SelectClass.vue";
import { ErrorProps } from "../type";
import FormDateTimePicker from "@/components/Layout/Forms/FormDateTimePicker.vue";
import { MoreDetails } from "../type";
import InputText from "primevue/inputtext";
import ErrorSpan from "@/components/utilities/ErrorSpan.vue";
import emitter from "@/config/emitter";
import CriteriaSheetListModal from "../utilities/CriteriaSheetListModal.vue";
import CriteriaSheetTemplateModal from "../utilities/CriteriaSheetTemplateModal.vue";
import { AssignmentData } from "@/store/assignment/assignmentTypes";
import CommentBankListModal from "../utilities/CommentBankListModal.vue";
import CommentABankPopUp from "@/components/CommentABank/CommentABankPopUp.vue";
import { CommentBankList as CBList } from "@/store/comments/types";
import { getCurrentISODateTime } from "@/utils/formatter";
import { ClassData } from "@/store/class/classTypes";

interface BankData {
  uuid: string;
  name: string;
}

@Component({
  components: {
    AppButton,
    FormInputDropdown,
    AppTypographyText,
    FormField,
    SelectClass,
    FormDateTimePicker,
    InputText,
    ErrorSpan,
    CriteriaSheetListModal,
    CriteriaSheetTemplateModal,
    CommentBankListModal,
    CommentABankPopUp,
  },
})
export default class SidePanelComponent extends Vue {
  icons = icons;
  eventBus = emitter;

  @Prop({
    type: Object,
    required: true,
  })
  error!: ErrorProps;

  @Prop({
    type: Function,
  })
  handleSelectedCriteriaSheet!: (uuid: string, name: string) => void;

  @Prop({
    type: Function,
  })
  handleSelectedCommentBank!: (uuid: string, name: string) => void;

  @Prop({
    type: Function,
  })
  handleDetachCommentBank!: (uuid: string, name: string) => void;

  @Prop({
    type: Function,
  })
  handleDetachCriteriaSheet!: (uuid: string, name: string) => void;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  @Prop({
    type: Object,
  })
  selectedCriteriaSheet: BankData = {
    uuid: "",
    name: "",
  };

  @Prop({
    type: Object,
  })
  selectedCommentBank: BankData = {
    uuid: "",
    name: "",
  };

  @Prop({
    type: String,
  })
  assignmentUuid!: string;

  selectedDate = "";
  selectedTimezone = "";

  selectedClassModel = "";
  classNameModel = "";
  timeSubmissionModel = "";

  commentBankModalOpen = false;
  criteriaSheetModalOpen = false;
  showCriteriaTemplateOpen = false;
  criteriaSheetEdit = false;
  criteriaSheetPreview = false;
  criteriaSheetData = {
    uuid: "",
  };
  criteriaSheetBankData: BankData = {
    uuid: "",
    name: "",
  };

  details: MoreDetails = {
    classUuid: "",
    className: "",
    dueTimestamp: "",
    duration: 0,
    timezoneName: "",
  };

  commentBankUuid = "";
  showCommentBankOpen = false;
  commentDetails!: any;
  commentBankList: CBList[] = [];
  commentBankEdit = false;
  commentBankView = true;
  commentBankCopy = false;
  commentBankPreview = false;

  refreshKey = 0;

  @Ref
  durationInput!: HTMLInputElement;

  @Watch("selectedCriteriaSheet")
  selectedCriteriaSheetWatcher(value: BankData) {
    this.criteriaSheetBankData = value;
  }

  @Watch("selectedClassModel")
  selectedClassModelWatcher(value: string) {
    this.details.classUuid = value;
    this.$emit("onDetailsUpdate", this.details);
  }

  @Watch("classNameModel")
  classNameModelWatcher(value: string) {
    this.details.className = value;
    this.$emit("onDetailsUpdate", this.details);
  }

  @Watch("timeSubmissionModel")
  timeSubmissionModelWatcher(value: number) {
    this.details.duration = value;
    this.$emit("onDetailsUpdate", this.details);
  }

  mounted() {
    this.criteriaSheetBankData = this.selectedCriteriaSheet;
    this.eventBus.on("CLEAR_FIELDS", () => {
      this.selectedClassModel = "";
      this.timeSubmissionModel = "";
      this.selectedDate = "";
      this.handleDueDate({ dateTime: "", timezoneName: "" });
    });

    if (this.isEdit) {
      // Fill up state model to update the parent component
      const selectedAssignment: AssignmentData = structuredClone(
        this.$store.state.assignment.selectedAssignment
      );
      this.selectedClassModel = selectedAssignment.class.uuid;
      this.timeSubmissionModel =
        selectedAssignment.estimatedTimeToMark.value.toString();

      const dueDateTime = {
        dateTime: selectedAssignment.dueTimestamp,
        timezoneName: selectedAssignment.timezoneName,
      };
      this.selectedDate = selectedAssignment.dueTimestamp;
      this.handleDueDate(dueDateTime);
    } else {
      this.selectedClassModel = this.$route.query.class as string;
      this.selectedDate = getCurrentISODateTime();
    }
    // this.handleEditCriteriaSheet(this.isEdit);
    // this.commentBankEdit = this.isEdit;
  }

  handleOnClassSelect(data: ClassData) {
    const classObj = structuredClone(data);
    if (data.uuid === "create") {
      classObj.code = "";
      this.classNameModel = "";
    } else {
      this.classNameModel = data.code;
    }
  }

  handleSelect(uuid: string, name: string) {
    this.showCriteriaTemplateOpen = false;
    this.handleSelectedCriteriaSheet(uuid, name);
  }

  handleDueDate(data: { dateTime: string; timezoneName: string }) {
    this.details.dueTimestamp = data.dateTime;
    this.details.timezoneName = data.timezoneName;
    this.$emit("onDetailsUpdate", this.details);
  }

  handleOpenCommentBank() {
    this.commentBankModalOpen = true;
  }

  handleEditView(action: string) {
    if (action === "Copy") {
      this.commentDetails = {
        ...this.commentDetails,
        name: `Copy of ${this.commentDetails.name}`,
      };

      this.commentBankCopy = true;
      this.commentBankEdit = false;
      this.commentBankView = false;
      this.commentBankPreview = false;
    } else if (action === "Add Bank") {
      console.log("SUCCESS ADD TO BANK");
    }
  }

  handleCommentBankData(uuid: string, data: CBList) {
    this.commentBankUuid = uuid;
    this.showCommentBankOpen = uuid !== "";
    this.commentDetails = data;
    // this.commentBankList = data;
    this.commentBankModalOpen = false;
    this.commentBankPreview = true;
  }

  handleCriteriaSheetData(uuid: string, isPreview = true) {
    //
    this.criteriaSheetData.uuid = uuid;
    this.showCriteriaTemplateOpen = uuid !== "";
    this.criteriaSheetModalOpen = false;
    this.criteriaSheetPreview = isPreview;
  }

  handleCriteriaSheetTemplate(isSave: boolean) {
    if (isSave) {
      this.criteriaSheetPreview = false;
    } else {
      this.showCriteriaTemplateOpen = false;
    }
  }

  handleEditCriteriaSheet(uuid: string) {
    this.criteriaSheetEdit = true;
    this.criteriaSheetPreview = false;
    this.handleCriteriaSheetData(uuid, false);
  }

  handleEditCommentBank(uuid: string, data: CBList) {
    this.commentBankEdit = true;
    this.commentBankView = false;
    this.commentBankPreview = false;
    this.handleCommentBankData(uuid, data);
  }

  handleCreateCommentBank() {
    this.commentBankView = true;
  }
}
</script>

<style scoped lang="scss">
.unit {
  position: absolute;
  top: 0;
  left: 0;
  right: 10px;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
  display: flex;
}

.invisible {
  visibility: hidden;
  padding-left: 25px;
}

.units-value {
  white-space: pre;
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
