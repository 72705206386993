<template>
  <GridItem
    v-for="(item, i) in layouts[pageIndex]"
    :key="item.id"
    :x="item.x"
    :y="item.y"
    :w="item.w"
    :h="item.h"
    :i="item.i"
    class="p-5 w-full rounded-lg border border-flohh-neutral-85 border-solid bg-white flex flex-col gap-5"
    @moved="handleBlockMoved"
    @mousedown="handleStartDrag"
    drag-allow-from=".vue-draggable-handle"
    drag-ignore-from=".no-drag"
  >
    <div class="vue-draggable-handle absolute -top-[5px] -right-[8px]">
      <span v-html="icons.dragIcon" class="text-lg"></span>
    </div>
    <div class="flex gap-5">
      <p class="text-flohh-text-title font-bold">
        {{ blocksBP[item.o.type].name || "Block" }}
      </p>
      <button
        type="button"
        @click="
          () => {
            removeBlockID = item.id;
            removeBlockSpace =
              item.o.numberOfItems * item.o.itemVerticalSpacing + blockSpacing;
            removeblockName = blocksBP[item.o.type].name || 'Block';
            removeBlockOpen = true;
          }
        "
        class="hover:scale-110"
      >
        <span v-html="icons.trashSmallRedIcon" />
      </button>
    </div>
    <TextAndLinesBlock
      v-if="item.o.type === 'line'"
      :blockData="item.o"
      :blockIndex="i"
      :pageIndex="pageIndex"
      :pageRemainingSpace="pageRemainingSpace"
      @onBlockContentUpdate="handleUpdateBlockContent"
    />
    <SpaceBlock
      v-if="item.o.type === 'space'"
      :blockData="item.o"
      :blockIndex="i"
      :pageIndex="pageIndex"
      :pageRemainingSpace="pageRemainingSpace"
      @onBlockContentUpdate="handleUpdateBlockContent"
    />
  </GridItem>

  <Dialog
    :visible="removeBlockOpen"
    modal
    :closable="false"
    :close-on-escape="true"
    :style="{ width: '20vw', minWidth: '300px' }"
    :pt="{
      header: { class: '!py-4 !pl-6' },
      content: { class: '!p-0' },
      footer: { class: '!p-0' },
    }"
  >
    <template #header>
      <h5
        class="text-default text-neutral-gray-500 flex font-bold items-center justify-start"
      >
        <i v-html="icons.trashBlack" class="mr-2"></i>Remove Block
      </h5>
    </template>
    <div class="border-t border-solid border-neutral-gray-500 py-4 px-6">
      <p class="text-sm text-neutral-gray-500">
        Are you sure you want to remove the selected {{ removeblockName }} on
        page {{ pageIndex + 1 }}?
      </p>
    </div>
    <template #footer>
      <div class="py-2 px-6 flex items-center justify-end">
        <Button
          label="Cancel"
          @click.prevent="removeBlockOpen = false"
          class="border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
        />
        <Button
          label="Remove"
          @click.prevent="handleRemoveBlock"
          class="border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
        />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Model, Vue, Prop } from "vue-facing-decorator";
import { icons } from "@/utils/icons";
import CoversheetBuilder from "./CoversheetBuilder.vue";
import { ILayoutItem } from "./CoversheetBuilder.vue";
import { Block, CoversheetPage } from "../type";
import { GridItem } from "grid-layout-plus";
import TextAndLinesBlock from "./Blocks/TextAndLinesBlock.vue";
import SpaceBlock from "./Blocks/SpaceBlock.vue";
import { COVERSHEET_BLOCKS, PER_BLOCK_SPACING } from "../data";
import Dialog from "primevue/dialog";
import Button from "primevue/button";

@Component({
  components: {
    CoversheetBuilder,
    GridItem,
    TextAndLinesBlock,
    SpaceBlock,
    Dialog,
    Button,
  },
})
export default class BuilderBlock extends Vue {
  icons = icons;
  blocksBP = COVERSHEET_BLOCKS;
  blockSpacing = PER_BLOCK_SPACING;

  @Model({
    default: false,
    type: Boolean,
  })
  isOpen!: boolean;

  @Prop({
    type: Array,
    default: [],
  })
  layouts!: ILayoutItem[][];

  @Prop({
    type: Array,
    default: [],
  })
  data!: CoversheetPage[];

  @Prop({
    type: Number,
    default: 0,
  })
  pageIndex!: number;

  @Prop({
    type: Number,
    default: 0,
  })
  pageRemainingSpace!: number;

  removeBlockOpen = false;
  removeblockName = "";
  removeBlockSpace = 0;
  removeBlockID!: string | number;

  isSaving = false;

  handleRemoveBlock() {
    this.$emit(
      "onRemoveBlock",
      this.pageIndex,
      this.removeBlockID,
      this.removeBlockSpace
    );
    this.removeBlockOpen = false;
  }

  handleUpdateBlockContent(
    block: Block,
    blockIndex: number,
    type: string,
    previousSpace: number,
    spaceConsumed: number
  ) {
    this.$emit(
      "onBlockContentUpdate",
      block,
      this.pageIndex,
      blockIndex,
      type,
      previousSpace,
      spaceConsumed
    );
  }

  handleBlockMoved(i: number, newX: number, newY: number) {
    // const pageLayouts = this.layouts[this.pageIndex];
    // const pageBlocks = this.data[this.pageIndex].blocks;
    // const oldIndex = pageLayouts.findIndex((item) => item.i === i.toString());
    // if (oldIndex === -1 || oldIndex === newY) {
    //   this.$emit("onBlockMoved", pageBlocks);
    //   return;
    // }
    // const movedBlock = pageBlocks.splice(oldIndex, 1)[0];
    // pageBlocks.splice(newY, 0, movedBlock);
    this.$emit("onBlockMoved", this.pageIndex, this.layouts);
  }

  handleStartDrag() {
    this.$emit("onStartDrag", this.pageIndex);
  }

  handleFillPageWithLines() {
    //
  }
}
</script>

<style scoped></style>
