<template>
  <div
    class="login-container flex py-[15px] items-center justify-center w-full max-w-[600px] px-5 mx-auto"
    :class="viewActivation ? 'flex flex-col justify-center h-full' : ''"
  >
    <div
      class="rounded-md flex flex-col justify-center items-center pb-0 px-6 md:px-10 pt-5 bg-white w-full"
    >
      <div
        class="h idden flex flex-col w-full items-center justify-center p-4 relative z-[1]"
      >
        <img
          src="@/assets/flohh-logo.svg"
          class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
        />
      </div>
      <label
        class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20 mt-1"
        for="isAgree"
        v-if="!hasShareCode"
        >Are you a teacher?

        <router-link to="/login" class="text-flohh-primary-pink underline">
          Sign in here
        </router-link>
      </label>
      <Divider />
      <template v-if="!viewActivation">
        <div class="pt-2 pb-6 text-center flex flex-col">
          <p class="text-[24px] color-flohh-neutral-20 font-bold">
            Student Log In
          </p>
          <p
            class="text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
            v-if="hasShareCode"
          >
            Need an account?
            <span
              class="text-flohh-primary-pink underline cursor-pointer"
              v-if="hasShareCode"
              @click="handleView"
              >Sign up here</span
            >
            .
          </p>
          <!-- 
        <p
          class="text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
        >
          Choose an option below to continue
        </p> -->
        </div>

        <div
          class="w-full md:px-14 lg:px-14 flex items-center justify-center flex-col"
          v-if="!hasShareCode"
        >
          <AppButton
            @click="handleGoogleAuth"
            type="transparent"
            class="flex w-full !gap-4 text-flohh-text-body font-flohh-font-medium leading-[1.2] text-center !justify-start text-[#333] h-10 mb-4"
          >
            <i><img src="@/assets/icon-google.svg" /></i>
            Continue with Google
          </AppButton>
          <AppButton
            @click="handleMicrosoftAuth"
            type="transparent"
            class="flex w-full !gap-4 text-flohh-text-body font-flohh-font-medium leading-[1.2] text-center !justify-start text-[#333] h-10"
          >
            <i><img src="@/assets/icon-microsoft.svg" /></i>
            Continue with Microsoft
          </AppButton>
        </div>
        <div class="md:px-14 w-full">
          <Divider align="center" type="solid" v-if="!hasShareCode">
            <span>or</span>
          </Divider>
          <!-- <p class="text-flohh-text-body pt-3">
        Don’t have an account yet? Choose an option below to continue
      </p> -->
          <p
            class="text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20 mb-7"
            v-if="!hasShareCode"
          >
            Enter your details and log in to continue
          </p>

          <form
            @submit.prevent="onSubmit"
            autocomplete="off"
            class="w-full pb-3"
          >
            <div
              class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
            >
              <p
                class="font-flohh-font-medium text-flohh-text-body color-flohh-neutral-20 mb-1"
              >
                Email
              </p>
              <FormTextInput
                type="email"
                v-model="form.email"
                name="email"
                placeholder="Enter Email"
              />
            </div>
            <div
              class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
            >
              <p
                class="font-flohh-font-medium text-flohh-text-body color-flohh-neutral-20 mb-1"
              >
                Password
              </p>
              <FormTextInput
                type="password"
                v-model="form.password"
                name="password"
                placeholder="Enter Password"
                iconRight="eyeActive"
              />
            </div>
            <div class="text-center my-6">
              <label
                class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20 text-center pb-5"
                for="isAgree"
                >Forgot your password?

                <span
                  @click="handleClickReset"
                  class="text-flohh-primary-pink underline cursor-pointer"
                >
                  Reset password
                </span>
              </label>
            </div>
            <div class="flex justify-end gap-2">
              <LoginActionComponent :isLoading="isLoading" :isStudent="true" />
            </div>
          </form>
        </div>
      </template>
      <template v-else>
        <div class="pt-2 pb-6 text-center max-w-2xl">
          <p class="text-flohh-text-title color-flohh-neutral-20 font-bold">
            Verify with OTP Code
          </p>
          <label
            class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
            >Enter the 6-digit verification code that was sent to your email.
          </label>
        </div>
        <ActivationComponent
          :verificationError="verificationError"
          :inputs="inputs"
          :handlePaste="handlePaste"
          :handleResend="handleResend"
          :handleVerify="handleVerify"
          :isVerifying="isVerifying"
        />
      </template>
    </div>
    <!-- <ActivationModalComponent
      v-model="visible"
      :onClose="closeModal"
      modalTitle="Student Activation"
      :verificationError="verificationError"
      :inputs="inputs"
      :handlePaste="handlePaste"
      :handleResend="handleResend"
      :handleVerify="handleVerify"
      :isVerifying="isVerifying"
    /> -->
  </div>

  <ModalUtility
    v-model="showInvalid"
    title="Failed"
    :draggable="false"
    width="450px"
    containerStyle=""
  >
    <template #content>
      <div class="flex flex-col items-center justify-center gap-4 p-6">
        <span
          ><svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_76_33458"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="80"
              height="80"
            >
              <rect width="80" height="80" fill="#D9D9D9"></rect>
            </mask>
            <g mask="url(#mask0_76_33458)">
              <path
                d="M39.9997 44.6666L23.6663 60.9999C23.0552 61.611 22.2775 61.9166 21.333 61.9166C20.3886 61.9166 19.6108 61.611 18.9997 60.9999C18.3886 60.3888 18.083 59.611 18.083 58.6666C18.083 57.7221 18.3886 56.9444 18.9997 56.3333L35.333 39.9999L18.9997 23.6666C18.3886 23.0555 18.083 22.2777 18.083 21.3333C18.083 20.3888 18.3886 19.611 18.9997 18.9999C19.6108 18.3888 20.3886 18.0833 21.333 18.0833C22.2775 18.0833 23.0552 18.3888 23.6663 18.9999L39.9997 35.3333L56.333 18.9999C56.9441 18.3888 57.7219 18.0833 58.6663 18.0833C59.6108 18.0833 60.3886 18.3888 60.9997 18.9999C61.6108 19.611 61.9163 20.3888 61.9163 21.3333C61.9163 22.2777 61.6108 23.0555 60.9997 23.6666L44.6663 39.9999L60.9997 56.3333C61.6108 56.9444 61.9163 57.7221 61.9163 58.6666C61.9163 59.611 61.6108 60.3888 60.9997 60.9999C60.3886 61.611 59.6108 61.9166 58.6663 61.9166C57.7219 61.9166 56.9441 61.611 56.333 60.9999L39.9997 44.6666Z"
                fill="#F59289"
              ></path>
            </g>
          </svg>
        </span>
        <p class="text-flohh-text-title font-flohh-font-bold text-center">
          Unable to Verify
        </p>
        <p class="text-flohh-text-body text-center">
          <span>{{ this.verificationError.message }}</span>
        </p>
      </div>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import ModalCardComponent from "@/components/Modal/ModalCardComponent.vue";
import { LoginInfo } from "@/components/Authentication/Login/types";
// import axios, { AxiosResponse } from "axios";
import LoginFormComponent from "@/components/Authentication/Login/LoginFormComponent.vue";
import LoginActionComponent from "@/components/Authentication/Login/LoginActionComponent.vue";
import Divider from "primevue/divider";
import { icons as AppIcons } from "@/utils/icons";
import TextInputComponent from "@/components/Input/TextInput/TextInputComponent.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import ErrorSpan from "@/components/utilities/ErrorSpan.vue";
import axios, { AxiosResponse } from "axios";
import { useToast } from "primevue/usetoast";
// import ActivationModalComponent from "@/components/Authentication/Registration/ActivationModalComponent.vue";
import { environment } from "@/environments/environment";
import { TELEMETRY_HEAD } from "@/utils/telemetry/data";
import ActivationComponent from "@/components/Authentication/Registration/ActivationComponent.vue";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
interface ClipboardEvent extends Event {
  clipboardData: DataTransfer;
}

@Component({
  components: {
    ModalCardComponent,
    LoginFormComponent,
    LoginActionComponent,
    TextInputComponent,
    FormTextInput,
    ErrorSpan,
    AppButton,
    Divider,
    ActivationComponent,
    ModalUtility,
  },
})
export default class StudentLoginView extends Vue {
  toast = useToast();
  //
  isTrue = true;
  form: LoginInfo = {
    email: "",
    password: "",
  };

  hasIcon = false;
  icon = AppIcons;
  classCode = "";
  isLoading = false;
  registrationAccessToken = "";
  visible = false;

  hasShareCode = false;
  shareCode = "";

  inputs: { value: string }[] = [
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
  ];

  verificationError = {
    show: false,
    message: "Invalid code",
  };
  isVerifying = false;

  BASE_URL = environment.apiEndpoint;
  REDIRECT_BASE_URL = "";
  googleCallbackUrl: string | null = "";
  microsoftCallbackUrl: string | null = "";

  viewActivation = false;
  showInvalid = false;

  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const shareCode = urlParams.get("share-code");
    if (shareCode) {
      this.hasShareCode = true;
      this.classCode = shareCode;
    } else {
      this.classCode = this.$route.query.code
        ? this.$route.query.code.toString()
        : "";
    }

    localStorage.clear();

    this.REDIRECT_BASE_URL = `${this.BASE_URL}/authentication/social`;
    const CALLBACK_BASE_URL = `${window.location.protocol}//${window.location.host}/authenticate`;
    this.googleCallbackUrl = `${CALLBACK_BASE_URL}/google?authenticatedAs=student`;
    this.microsoftCallbackUrl = `${CALLBACK_BASE_URL}/microsoft?authenticatedAs=student`;
  }

  handleGoogleAuth() {
    window.location.href = `${this.REDIRECT_BASE_URL}/google?authenticatingAs=student&successRedirectUrl=${this.googleCallbackUrl}&failedRedirectUrl=${this.googleCallbackUrl}`;
  }

  handleMicrosoftAuth() {
    window.location.href = `${this.REDIRECT_BASE_URL}/microsoft?authenticatingAs=student&successRedirectUrl=${this.microsoftCallbackUrl}&failedRedirectUrl=${this.microsoftCallbackUrl}`;
  }

  handlePaste(event: Event) {
    const codes = this.splitAndDisplay(event);
    if (codes) {
      codes.forEach((item, index) => {
        this.inputs[index].value = item;
      });
    }
  }

  splitAndDisplay(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const clipboardData = (event as ClipboardEvent).clipboardData;
    const pastedText = clipboardData.getData("text");

    const characters = pastedText.split("");

    event.preventDefault();
    return characters;
  }

  async handleVerify() {
    try {
      this.isVerifying = true;

      const code = this.inputs.map((item) => item.value).join("");
      ///authentication/otp/verify
      const allValuesNotEmpty = this.inputs.every((item) =>
        Boolean(item.value)
      );
      if (allValuesNotEmpty) {
        const payload = {
          code: code,
          type: "student_activation",
          token: this.registrationAccessToken,
        };
        const response: any = await axios
          .post("/authentication/otp/verify", payload, {
            headers: TELEMETRY_HEAD.accountVerification,
          })
          .then((response) => {
            this.isVerifying = false;
            localStorage.setItem("auth", JSON.stringify(response.data.data));
            const authorization = `Bearer ${response.data.data.accessToken}`;
            axios.defaults.headers.common["Authorization"] = authorization;
            this.isVerifying = false;

            if (response.data.ok) {
              localStorage.setItem(
                "authenticatedAs",
                response.data.data.authenticatedAs
              );
              window.location.href = "/student/dashboard";
            }
          })
          .catch((error) => {
            console.error(error);
            this.isVerifying = false;
            // this.showError(error.message);
            // this.verificationError.show = true;
            this.showInvalid = true;
            this.verificationError.message = error.response.data.details.info;
          });
      } else {
        this.isVerifying = false;
        this.showInvalid = true;
        // this.verificationError.show = true;
        this.verificationError.message = "Invalid code";
      }
    } catch (e) {
      this.isVerifying = false;
      //
    } finally {
      this.isVerifying = false;
      //
    }
  }

  async handleResend() {
    const payload = {
      type: "student_activation",
      token: this.registrationAccessToken,
    };

    const response: any = await axios
      .post("/authentication/otp/resend", payload)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 422) {
          this.showError(error.message);
        }
      });
    if (response.data.ok) {
      this.registrationAccessToken = response.data.data.accessToken;
      this.toast.add({
        severity: "success",
        detail: "Resent code in your email",
        life: 3000,
      });
    }
  }

  handleClickReset() {
    localStorage.setItem("redirectPath", "student");
    this.$router.push({
      path: "/forgot-password",
    });
  }
  /**
   * onSubmit
   */
  public async onSubmit() {
    this.isLoading = true;
    const { email, password } = this.form;
    const payload = {
      email,
      password,
      userType: "student",
      authenticatingAs: "student",
    };

    try {
      const response: AxiosResponse = await axios.post(
        "authentication/login",
        payload
      );
      if (response.data.ok) {
        if (response.data.data.type === "activation") {
          this.verificationError.show = false;
          this.verificationError.message = "Invalid code";
          // this.visible = true;
          this.viewActivation = true;
          this.inputs = [
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
          ];
          this.registrationAccessToken = response.data.data.accessToken;
        } else {
          this.isLoading = false;
          localStorage.setItem("auth", JSON.stringify(response.data.data));
          const authorization = `Bearer ${response.data.data.accessToken}`;
          axios.defaults.headers.common["Authorization"] = authorization;
          localStorage.setItem(
            "authenticatedAs",
            response.data.data.authenticatedAs
          );
          if (this.classCode) {
            axios
              .post(`student/class/invite/${this.classCode}`)
              .then((response) => {
                //
              })
              .catch((error) => {
                console.error(error);
                return;
              });
          }
          window.location.href = "/student/dashboard";
        }
      } else {
        console.error("student err", response.data.message);
        this.showError(response.data.message);
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      if (error instanceof ReferenceError) {
        this.showError(error.message);
        console.error("student err", error.message);
      } else {
        // throw error;
      }
    }
  }

  handleView() {
    this.$router.push({
      path: "/student/registration",
      query: this.classCode ? { code: this.classCode } : undefined,
    });
  }

  private showError(message: string) {
    this.toast.add({
      severity: "error",
      detail: message,
      life: 3000,
    });
  }

  closeModal() {
    this.visible = false;
    this.isLoading = false;
  }
}
</script>

<style scoped lang="scss"></style>
