import { CreateSubmission } from "@/models/Submission";
import { TELEMETRY_HEAD } from "@/utils/telemetry/data";
import axios from "axios";

interface IAttachNewStudent {
  firstName: string;
  lastName: string;
  email?: string;
}

const STUDENT_SUBMISSION_BASE_URL = "student/submissions";
const SUBMISSION_BASE_URL = "/submissions";

export default class SubmissionServive {
  createSubmission(submission: CreateSubmission) {
    return axios.post(SUBMISSION_BASE_URL, submission, {
      headers: TELEMETRY_HEAD.createSubmission,
    });
  }

  getSubmission(uuid: string) {
    return axios.get(`${SUBMISSION_BASE_URL}/${uuid}`);
  }

  studentCreateSubmission(submission: CreateSubmission) {
    return axios.post(`${STUDENT_SUBMISSION_BASE_URL}`, submission, {
      headers: TELEMETRY_HEAD.studentCreateSubmission,
    });
  }

  getSubmissionsFiles(assignmentUuid: string) {
    return axios.get(`${SUBMISSION_BASE_URL}/${assignmentUuid}/download`, {
      responseType: "arraybuffer",
      headers: TELEMETRY_HEAD.download,
    });
  }

  attachNewStudentSubmission(
    submissionUuid: string,
    payload: IAttachNewStudent
  ) {
    return axios.post(
      `${SUBMISSION_BASE_URL}/${submissionUuid}/attach-student`,
      payload,
      {
        headers: TELEMETRY_HEAD.attachNewStudentSubmission,
      }
    );
  }

  attachStudentSubmission(submissionUuid: string, studentUuid: string) {
    return axios.post(
      `${SUBMISSION_BASE_URL}/${submissionUuid}/attach-student/${studentUuid}`,
      {},
      {
        headers: TELEMETRY_HEAD.attachStudentSubmission,
      }
    );
  }

  detachStudentSubmission(submissionUuid: string, studentUuid: string) {
    return axios.post(
      `${SUBMISSION_BASE_URL}/${submissionUuid}/detach-student/${studentUuid}`,
      {},
      {
        headers: TELEMETRY_HEAD.detachStudentSubmission,
      }
    );
  }

  getSelectedSubmissionFiles(assignmentUuid: string, uuids: string) {
    return axios.get(`/submissions/${assignmentUuid}/download?uuid=${uuids}`, {
      responseType: "arraybuffer",
      headers: TELEMETRY_HEAD.download,
    });
  }

  updateSubmissionStatus(submissionUuid: string, action: "mark" | "unmark") {
    return axios.post(`${SUBMISSION_BASE_URL}/${submissionUuid}/${action}`);
  }

  getDemoSubmission() {
    return axios.get(`${SUBMISSION_BASE_URL}/demo`);
  }

  downloadSubmissionsAsSingleDoc(
    assignmentUuid: string,
    payload: Record<string, string | string[]>
  ) {
    return axios.post(
      `${SUBMISSION_BASE_URL}/assignment/${assignmentUuid}/bulk-download`,
      { ...payload }
    );
  }
}
