<template>
  <div class="bg-white rounded-lg m-3 p-8">
    <h6 class="text-flohh-h6 font-flohh-font-bold">Reports</h6>
    <div class="py-6">
      <hr />
    </div>
    <div class="classes-data-table w-full">
      <div class="pt-5 rounded-lg overflow-hidden">
        <div class="flex justify-between mb-4 w-full gap-5 flex-wrap">
          <div class="flex gap-2">
            <FormInputDropdown
              v-model="template"
              :options="templates"
              placeholder="Select Report Template"
              optionLabel="label"
              optionValue="value"
              class="min-w-[250px]"
            />
            <InputDateComponent
              v-model="dateRange"
              placeholder="Select Date Range"
              selectionMode="range"
              :maxDate="new Date()"
              class="min-w-[250px]"
            />
            <AppButton
              type="submit"
              @click="handleGenerateReport"
              label="Generate Report"
              iconLeft="generateIcon"
              class="min-w-[190px]"
              :loading="generating"
              :disabled="generating"
            />
          </div>
          <FormTextInput
            type="text"
            name="search"
            v-model="searchText"
            placeholder="Search..."
            @input="handleSearch"
            :iconLeft="'searchBlack'"
            class="!w-[250px] !bg-[#fff]"
          />
        </div>
        <DataTable
          :value="data"
          paginator
          showGridlines
          :rows="10"
          dataKey="uuid"
          tableStyle="min-width: 50rem;"
        >
          <Column header="Report Title" sortable style="width: 100px">
            <template #body="{ data }">
              <div className="capitalize">
                {{ getMetricName(data.type) }}
              </div>
            </template>
          </Column>
          <Column header="Date Generated" sortable style="width: 100px">
            <template #body="{ data }">
              {{ formatDate(data.createdAt, "HH:mm - DD MMM YYYY") }}
            </template> </Column
          ><Column header="Status" sortable style="width: 50px">
            <template #body="{ data }">
              <div className="capitalize">
                {{ data.status }}
              </div>
            </template>
          </Column>
          <Column style="max-width: 10px; min-width: 10px; width: 10px">
            <template #body="{ data }">
              <button
                v-if="data.media"
                class="mx-auto block"
                @click.prevent="handleDownload(data)"
              >
                <span v-html="icon.iconDownload" /></button
            ></template>
          </Column>
          <Column style="max-width: 10px; min-width: 10px; width: 10px">
            <template #body="{ data }">
              <div class="flex items-center justify-center">
                <button
                  class="btn-event h-[24px] w-[24px] flex items-center justify-center p-[2px] cursor-pointer opacity-80"
                  @click="
                    (event) => {
                      toggleMenu(event, data.uuid);
                    }
                  "
                >
                  <span
                    v-html="icon.ellepsisVerticalBlack"
                    class="ml-[16px] mr-[8px] flex items-center justify-center [&>svg]:!w-[auto] [&>svg]:!h-[100%]"
                  ></span>
                </button>
                <Menu
                  :model="data.media ? tableActions : tableActions.slice(1, 2)"
                  :popup="true"
                  aria-controls="overlay_menu"
                  :ref="'menu-' + data.uuid"
                >
                  <template #item="{ item }">
                    <button
                      class="btn-event h-[42px] flex items-center justify-start cursor-pointer w-full"
                      @click="handleAction(item.event, data)"
                    >
                      <span
                        v-html="eventIcons[item.icon]"
                        class="ml-[16px] mr-[8px] flex items-center justify-center"
                        v-if="item.icon"
                      ></span>
                      <span class="text-sm pr-[16px] block">{{
                        item.label
                      }}</span>
                    </button>
                  </template>
                </Menu>
              </div>
            </template>
          </Column>
          <template #empty v-if="!isFetching"> No Reports Found. </template>
          <template #empty v-else>
            <div class="flex justify-center items-center w-full">
              <ProgressLoader /></div
          ></template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Menu from "primevue/menu";
import { EVENT_ICONS } from "./data";
import { IMenuRef, Reports } from "./types";
import { useToast } from "primevue/usetoast";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import FormTextInput from "../Layout/Forms/FormTextInput.vue";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import emitter from "@/config/emitter";
import { formatDate } from "@/utils/formatter";

import InputDateComponent from "@/components/utilities/InputDateComponent.vue";
import FormInputDropdown from "../Layout/Forms/FormInputDropdown.vue";
import SuperAdminService from "@/services/SuperAdminService";
import MediaService from "@/services/MediaService";

interface ITemplate {
  label: string;
  value: string;
}

@Component({
  components: {
    DataTable,
    Column,
    Menu,
    AppButton,
    FormTextInput,
    ProgressLoader,
    InputDateComponent,
    FormInputDropdown,
  },
})
export default class SuperAdminReportsComponent extends Vue {
  superadminService: SuperAdminService = new SuperAdminService();
  mediaService: MediaService = new MediaService();
  icon = AppIcons;
  toast = useToast();
  eventBus = emitter;

  searchText = "";

  eventIcons: Record<string, string> = EVENT_ICONS;

  isFetching = true;
  downloading = false;
  generating = false;

  dateRange: string[] = [];
  template = "";

  templates: ITemplate[] = [
    {
      label: "All Metrics",
      value: "all_metrics",
    },
    {
      label: "Teacher Metrics",
      value: "teacher_metrics",
    },
    {
      label: "Platform Activity Metrics",
      value: "platform_activity_metrics",
    },
    {
      label: "Daily Teacher Activity Metrics",
      value: "daily_teacher_activity_metrics",
    },
    {
      label: "Daily Student Activity Metrics",
      value: "daily_student_activity_metrics",
    },
    {
      label: "Top School & Countries",
      value: "top_school_and_countries",
    },
    {
      label: "School High Level Metrics",
      value: "school_high_level_metrics",
    },
    {
      label: "Teacher Signup & Verification",
      value: "teacher_signup_and_verification",
    },
  ];

  tableActions = [
    {
      label: "Download",
      icon: "ICON_DOWNLOAD",
      event: "download",
    },
    {
      label: "Delete",
      icon: "ICON_TRASH",
      event: "delete",
    },
  ];

  data: Reports[] = [];
  dataClone: Reports[] = [];

  async fetchReports() {
    this.isFetching = true;
    try {
      const response = await this.superadminService.getMetricReports();
      if (response.data.ok) {
        const data = response.data.data.sort((a: Reports, b: Reports) => {
          const dateComparison =
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
          if (dateComparison !== 0) {
            return dateComparison;
          }
          return a.type.localeCompare(b.type);
        });

        this.dataClone = data;
        this.data = data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isFetching = false;
    }
  }

  async handleDownload(data: Reports) {
    this.downloading = true;
    try {
      const response = await this.mediaService.downloadMetricReport(data.media);
      if (response.data) {
        const startDate = formatDate(data.startDate, "DD MMM YYYY");
        const endDate = formatDate(data.endDate, "DD MMM YYYY");
        const metricName = this.getMetricName(data.type).toUpperCase();
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const el = document.createElement("a");
        const href = URL.createObjectURL(blob);
        el.setAttribute(
          "download",
          `${metricName} (${startDate} - ${endDate})`
        );
        el.href = href;
        el.setAttribute("target", "_blank");
        el.click();
        URL.revokeObjectURL(href);
      }
    } catch (error) {
      this.showToast(
        "error",
        "Download Error",
        "Unable to download metric report"
      );
      console.error(error);
    } finally {
      this.downloading = false;
    }
  }

  async handleDelete(uuid: string) {
    try {
      const response = await this.superadminService.deleteMetricReport(uuid);
      if (response.data.ok) {
        const reports = structuredClone(this.dataClone);
        const data = reports.filter((report: Reports) => report.uuid !== uuid);

        this.dataClone = data;
        this.data = data;

        this.showToast(
          "success",
          "Metric Report Deleted",
          "The metric report was successfully deleted"
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  async handleGenerateReport() {
    this.generating = true;
    try {
      const isFormValid = this.validateForm();

      if (!isFormValid) {
        this.showToast(
          "error",
          "Invalid",
          "Please enter a valid template and date range"
        );
        return;
      }

      const payload = {
        type: this.template,
        startDate: formatDate(this.dateRange[0], "YYYY-MM-DD"),
        endDate: formatDate(this.dateRange[1], "YYYY-MM-DD"),
      };

      const response = await this.superadminService.generateMetricReport(
        payload
      );
      if (response.data.ok) {
        const data = response.data.data;

        this.dataClone = [data, ...this.data];
        this.data = [data, ...this.data];

        this.showToast(
          "success",
          "Metric Report Generated",
          "The metric report was successfully generated"
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.generating = false;
    }
  }

  validateForm() {
    if (!this.dateRange.length) {
      return false;
    }

    const startDate = new Date(this.dateRange[0]);
    const endDate = new Date(this.dateRange[1]);

    if (startDate > endDate) {
      return false;
    }

    return this.template && this.dateRange[0] && this.dateRange[1];
  }

  mounted() {
    this.fetchReports();
  }

  showToast(
    severity: "success" | "info" | "warn" | "error" | undefined,
    summary: string,
    detail: string
  ) {
    this.toast.add({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  }

  handleAction(action: string, data: Reports) {
    if (action === "download") {
      this.handleDownload(data);
    } else if (action === "delete") {
      this.handleDelete(data.uuid);
    }
  }

  toggleMenu(event: Event, index: number) {
    const ref = this.$refs["menu-" + index] as IMenuRef | undefined;
    if (ref) {
      ref.toggle(event);
    }
  }

  handleSearch(e: Event) {
    const input = e.target as HTMLInputElement;
    const text = input.value;

    if (text) {
      const data = structuredClone(this.dataClone);
      this.data = data.filter((o: Reports) => {
        return (
          o.uuid?.toLowerCase().includes(text.toLowerCase()) ||
          o.startDate?.toLowerCase().includes(text.toLowerCase()) ||
          o.endDate?.toLowerCase().includes(text.toLowerCase())
        );
      });
    } else {
      this.data = this.dataClone;
    }
  }

  formatDate(date: string, format: string) {
    return formatDate(date, format);
  }

  getMetricName(type: string) {
    return type.split("_").join(" ");
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
$flohh-light-pink: #f4cddc;
$flohh-neutral-35: #595959;
.classes-data-table {
  .btn-event {
    margin-right: 5px;
    svg,
    img {
      height: 100%;
      width: 100%;
    }
  }
  .p-datatable-wrapper {
    border-radius: 8px;
  }
  .p-datatable {
    .p-sortable-column:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }
    .p-paginator-bottom {
      background: #fff;
    }
    .p-datatable-thead {
      > tr {
        > th {
          background: $flohh-light-pink;
        }
      }
    }
    .p-datatable-tbody {
      > tr.p-highlight {
        background-color: $flohh-light-pink !important;
        color: $flohh-neutral-35 !important;
        > td {
          padding: 10px 15px;
          vertical-align: middle;
        }
      }
    }
    .p-sortable-column {
      &:not(.p-highlight) {
        &:hover {
          background: $flohh-light-pink;
        }
      }
    }
  }
  .p-datatable {
    .p-datatable-tbody {
      > tr {
        &:not(.p-highlight) {
          background-color: #f2f2f2 !important;
          &:hover {
            background: $flohh-light-pink !important;
          }
        }
      }
    }
  }
  .p-paginator {
    .p-paginator-pages {
      .p-paginator-page.p-highlight {
        background: $flohh-light-pink;
        border-color: $flohh-light-pink;
      }
    }
  }
  .p-link {
    &:focus {
      box-shadow: none !important;
    }
  }
  .p-autocomplete-dd .p-autocomplete-input {
    box-shadow: none !important;
    border: 1px solid #ced4da !important;
    border-right-width: 0 !important;
    font-size: 14px;
  }
  .p-autocomplete-dd .p-autocomplete-dropdown {
    background-color: #fff;
    color: #333;
    box-shadow: none !important;
    border: 1px solid #ced4da !important;
    border-left-width: 0 !important;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  th.p-sortable-column:focus {
    outline: 0 !important;
    box-shadow: 0 !important;
  }
}
.p-menu {
  width: auto !important;
  padding: 0 !important;
}

.p-column-filter-buttonbar .p-button {
  color: #555 !important;
  background-color: #a3d7be !important;
  border-color: #a3d7be !important;
}
</style>
