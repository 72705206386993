<template>
  <div
    class="login-container flex h-screen items-center justify-center w-full max-w-[600px] px-5"
  >
    <div
      class="rounded-md flex flex-col justify-center items-center pb-0 px-6 md:px-10 pt-5 bg-white w-full"
    >
      <div
        class="h idden flex flex-col w-full items-center justify-center p-4 relative z-[1]"
      >
        <img
          src="@/assets/flohh-logo.svg"
          class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
        />
      </div>

      <label
        class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20 mt-1"
        for="isAgree"
        v-if="!isSuperAdmin"
        >Are you a student?

        <router-link
          to="student/login"
          class="text-flohh-primary-pink underline"
        >
          Sign in here
        </router-link>
      </label>
      <Divider />
      <template v-if="!viewActivation">
        <div class="pt-2 pb-6 text-center">
          <p class="text-flohh-text-title color-flohh-neutral-20 font-bold">
            {{ isSuperAdmin ? "Super Admin Login" : "Log In" }}
          </p>
          <label
            class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
            for="isAgree"
            v-if="!isSuperAdmin"
            >Need an account?

            <router-link
              to="/register"
              class="text-flohh-primary-pink underline"
            >
              Sign up here
            </router-link>
          </label>
        </div>
        <form
          @submit.prevent="onSubmit"
          autocomplete="off"
          class="w-full md:px-8"
        >
          <div
            class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
          >
            <p
              class="font-flohh-font-medium text-flohh-text-body color-flohh-neutral-20 mb-1"
            >
              Email
            </p>
            <FormTextInput
              type="email"
              v-model="form.email"
              name="email"
              placeholder="Enter Email"
            />
          </div>
          <div
            class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
          >
            <p
              class="font-flohh-font-medium text-flohh-text-body color-flohh-neutral-20 mb-1"
            >
              Password
            </p>
            <FormTextInput
              type="password"
              v-model="form.password"
              name="password"
              placeholder="Enter Password"
              iconRight="eyeActive"
            />
          </div>
          <div class="text-center my-6">
            <label
              class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20 text-center pb-5"
              for="isAgree"
              >Forgot your password?

              <span
                @click="handleClickReset"
                class="text-flohh-primary-pink underline cursor-pointer"
              >
                Reset password
              </span>
            </label>
          </div>

          <LoginActionComponent
            :handleView="handleView"
            :isSuperAdmin="isSuperAdmin"
            :isLoading="isLoading"
          />
        </form>
      </template>
      <template v-else>
        <div class="pt-2 pb-6 text-center max-w-2xl">
          <p class="text-flohh-text-title color-flohh-neutral-20 font-bold">
            Verify with OTP Code
          </p>
          <label
            class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
            >Enter the 6-digit verification code that was sent to your email.
          </label>
        </div>
        <ActivationComponent
          :verificationError="verificationError"
          :inputs="inputs"
          :handlePaste="handlePaste"
          :handleResend="handleResend"
          :handleVerify="handleVerify"
          :isVerifying="isVerifying"
        />
      </template>
    </div>
  </div>

  <ModalUtility
    v-model="showInvalid"
    title="Failed"
    :draggable="false"
    width="450px"
    containerStyle=""
  >
    <template #content>
      <div class="flex flex-col items-center justify-center gap-4 p-6">
        <span
          ><svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_76_33458"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="80"
              height="80"
            >
              <rect width="80" height="80" fill="#D9D9D9"></rect>
            </mask>
            <g mask="url(#mask0_76_33458)">
              <path
                d="M39.9997 44.6666L23.6663 60.9999C23.0552 61.611 22.2775 61.9166 21.333 61.9166C20.3886 61.9166 19.6108 61.611 18.9997 60.9999C18.3886 60.3888 18.083 59.611 18.083 58.6666C18.083 57.7221 18.3886 56.9444 18.9997 56.3333L35.333 39.9999L18.9997 23.6666C18.3886 23.0555 18.083 22.2777 18.083 21.3333C18.083 20.3888 18.3886 19.611 18.9997 18.9999C19.6108 18.3888 20.3886 18.0833 21.333 18.0833C22.2775 18.0833 23.0552 18.3888 23.6663 18.9999L39.9997 35.3333L56.333 18.9999C56.9441 18.3888 57.7219 18.0833 58.6663 18.0833C59.6108 18.0833 60.3886 18.3888 60.9997 18.9999C61.6108 19.611 61.9163 20.3888 61.9163 21.3333C61.9163 22.2777 61.6108 23.0555 60.9997 23.6666L44.6663 39.9999L60.9997 56.3333C61.6108 56.9444 61.9163 57.7221 61.9163 58.6666C61.9163 59.611 61.6108 60.3888 60.9997 60.9999C60.3886 61.611 59.6108 61.9166 58.6663 61.9166C57.7219 61.9166 56.9441 61.611 56.333 60.9999L39.9997 44.6666Z"
                fill="#F59289"
              ></path>
            </g>
          </svg>
        </span>
        <p class="text-flohh-text-title font-flohh-font-bold text-center">
          Unable to Verify
        </p>
        <p class="text-flohh-text-body text-center">
          <span>{{ this.verificationError.message }}</span>
        </p>
      </div>
    </template>
  </ModalUtility>
  <!-- <ActivationModalComponent
    v-model="visible"
    :onClose="closeModal"
    :verificationError="verificationError"
    :inputs="inputs"
    modalTitle="Teacher Activation"
    :handlePaste="handlePaste"
    :handleResend="handleResend"
    :handleVerify="handleVerify"
    :isVerifying="isVerifying"
  /> -->
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue, Watch } from "vue-facing-decorator";
import LoginFormComponent from "@/components/Authentication/Login/LoginFormComponent.vue";
import LoginActionComponent from "@/components/Authentication/Login/LoginActionComponent.vue";
import { LoginInfo } from "./types";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import ErrorSpan from "@/components/utilities/ErrorSpan.vue";
import Divider from "primevue/divider";

import TextInputComponent from "@/components/Input/TextInput/TextInputComponent.vue";
import axios, { AxiosResponse } from "axios";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { setAuthDispatch } from "@/store/auth/auth-dispatch";
import InputTextComponent from "@/components/utilities/InputTextComponent.vue";

import ModalUtility from "@/components/utilities/ModalUtility.vue";
// import ActivationModalComponent from "../Registration/ActivationModalComponent.vue";
import { TELEMETRY_HEAD } from "@/utils/telemetry/data";
import ActivationComponent from "../Registration/ActivationComponent.vue";
interface ClipboardEvent extends Event {
  clipboardData: DataTransfer;
}

@Component({
  components: {
    LoginFormComponent,
    LoginActionComponent,
    TextInputComponent,
    AppButton,
    FormTextInput,
    ErrorSpan,
    Divider,
    ModalUtility,
    InputTextComponent,
    // ActivationModalComponent,
    ActivationComponent,
  },
})
export default class LoginComponent extends Vue {
  toast = useToast();

  @Prop({
    type: Function,
    required: true,
  })
  private updateView!: () => void;

  @Prop({
    type: Boolean,
    required: false,
  })
  isSuperAdmin = false;

  visible = false;

  form: LoginInfo = {
    email: "",
    password: "",
  };
  icon = icons;

  loginResponse!: any;
  isLoading = false;
  isVerifying = false;
  viewActivation = false;
  showInvalid = false;
  // @Ref() readonly inputFields!: HTMLInputElement[];
  // inputFields = ref();

  inputs: { value: string }[] = [
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
  ];

  verificationError = {
    show: false,
    message: "Invalid code ",
  };

  @Watch("viewActivation")
  viewActivationWatcher(newValue: any) {
    if (newValue === false) {
      this.isLoading = false;
    }
  }

  handleClickReset() {
    localStorage.setItem("redirectPath", "teacher");
    this.$router.push({
      path: "/forgot-password",
    });
  }

  handlePaste(event: Event) {
    const codes = this.splitAndDisplay(event);
    if (codes) {
      codes.forEach((item, index) => {
        this.inputs[index].value = item;
      });
    }
  }

  splitAndDisplay(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const clipboardData = (event as ClipboardEvent).clipboardData;
    const pastedText = clipboardData.getData("text");

    const characters = pastedText.split("");

    event.preventDefault();
    return characters;
  }

  async handleVerify() {
    try {
      this.isVerifying = true;

      const code = this.inputs.map((item) => item.value).join("");
      ///authentication/otp/verify
      const allValuesNotEmpty = this.inputs.every((item) =>
        Boolean(item.value)
      );
      if (allValuesNotEmpty) {
        const payload = {
          code: code,
          type: "teacher_activation",
          token: this.loginResponse.data.data.accessToken,
        };
        const response: any = await axios
          .post("/authentication/otp/verify", payload, {
            headers: TELEMETRY_HEAD.accountVerification,
          })
          .catch((error) => {
            console.error(error);
            this.isVerifying = false;
            // this.showError(error.message);
            // this.verificationError.show = true;
            this.verificationError.message = error.response.data.details.info;
            this.showInvalid = true;
          });
        this.isVerifying = false;
        if (response && response.data.ok) {
          this.visible = false;
          // this.viewActivation = false;
          localStorage.setItem("auth", JSON.stringify(response.data.data));
          const authorization = `Bearer ${response.data.data.accessToken}`;
          axios.defaults.headers.common["Authorization"] = authorization;
          localStorage.setItem(
            "authenticatedAs",
            response.data.data.authenticatedAs
          );
          if (this.isSuperAdmin) {
            this.handleRedirect("/superadmin/teacher");
          } else {
            this.handleRedirect("/dashboard");
          }
        }
        // const response = this.registrationResponse;
      } else {
        // this.verificationError.show = true;
        this.showInvalid = true;
        this.verificationError.message = "Invalid code";
        this.isVerifying = false;
      }
    } catch (error: any) {
      this.isVerifying = false;
      console.error(error);
      //
    } finally {
      this.isVerifying = false;
    }
  }

  handleRedirect(link: string) {
    window.location.href = link;
  }

  async handleResend() {
    const payload = {
      type: "teacher_activation",
      token: this.loginResponse.data.data.accessToken,
    };

    const response: any = await axios
      .post("/authentication/otp/resend", payload)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 422) {
          this.showError(error.message);
        }
      });
    if (response.data.ok) {
      this.toast.add({
        severity: "success",
        detail: "Resent code in your email",
        life: 3000,
      });
    }
  }

  /**
   * onSubmit
   */
  public async onSubmit() {
    this.isLoading = true;
    const { email, password } = this.form;
    const payload = {
      email,
      password,
      userType: this.isSuperAdmin ? "super admin" : "teacher",
      authenticatingAs: this.isSuperAdmin ? "super admin" : "teacher",
    };

    try {
      const response: AxiosResponse = await axios.post(
        "authentication/login",
        payload,
        {
          headers: TELEMETRY_HEAD.teacherLogin,
        }
      );
      if (response.data.ok) {
        if (response.data.data.type === "activation") {
          // this.verificationError.show = false;
          this.showInvalid = false;

          this.verificationError.message = "";
          this.visible = true;
          this.viewActivation = true;
          this.loginResponse = response;
          this.inputs = [
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
          ];
        } else {
          localStorage.setItem("auth", JSON.stringify(response.data.data));
          this.$store.dispatch(setAuthDispatch, response.data.data);
          const authorization = `Bearer ${response.data.data.accessToken}`;
          axios.defaults.headers.common["Authorization"] = authorization;
          localStorage.setItem("onboarding", "hide");
          localStorage.setItem(
            "authenticatedAs",
            response.data.data.authenticatedAs
          );
          if (this.isSuperAdmin) {
            this.handleRedirect("/superadmin/teacher");
          } else {
            const shareCode = sessionStorage.getItem("shareCode");
            const sharePage = sessionStorage.getItem("sharePage");
            if (shareCode && sharePage) {
              this.handleRedirect(`${sharePage}?share-code=${shareCode}`);
            } else {
              this.handleRedirect("/dashboard");
            }
          }
        }
      } else {
        console.error("Error", response.data.message);
        this.showError(response.data.message);
      }
    } catch (error) {
      if (error instanceof ReferenceError) {
        this.showError(error.message);
        console.error(error.message);
      }
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  }

  public handleView() {
    // You can perform some logic here and then call the parent function
    this.updateView();
  }

  private showError(message: string) {
    this.toast.add({
      severity: "error",
      detail: message,
      life: 3000,
    });

    this.isLoading = false;
  }

  closeModal() {
    this.visible = false;
    this.isLoading = false;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$browser-context: 16; // Default
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}
.login-wrap {
  background-color: #f4d5de;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 454px;
  margin-bottom: 80px;
  p {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  form {
    width: 100%;
    max-width: 313px;
  }
}

.text-input {
  margin-bottom: 15px;
}
</style>
