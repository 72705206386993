import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cb0dfa66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-[30px] bg-white rounded-lg mb-5" }
const _hoisted_2 = { class: "w-full overflow-hidden flex flex-col gap-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssignmentListComponent = _resolveComponent("AssignmentListComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AssignmentListComponent, {
        title: "Assignments in Progress",
        assignments: _ctx.assignments
      }, null, 8, ["assignments"])
    ])
  ]))
}