<template>
  <div
    class="py-0 w-full max-w-[960px] px-5"
    :class="viewActivation ? 'flex flex-col justify-center h-full' : ''"
  >
    <div
      class="rounded-md flex flex-col justify-center items-center pb-0 px-0 lg:px-6 md:px-10 pt-5 bg-white w-full"
    >
      <div
        class="flex flex-col w-full items-center justify-center p-4 relative z-[1]"
      >
        <img
          src="@/assets/flohh-logo.svg"
          class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
        />
      </div>
      <Divider />
      <template v-if="!viewActivation">
        <div class="pt-2 pb-6 text-center max-w-2xl">
          <p class="text-flohh-text-title color-flohh-neutral-20 font-bold">
            Join Your Teacher's Flohh Class
          </p>
          <label
            class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
            >Add your details below to join your teacher's Flohh class so you
            can receive updates about assignments and get your feedback. Enter
            your details and log in to&nbsp;continue
          </label>
        </div>

        <section class="self-start w-full mx-auto py-10 px-0 lg:px-8">
          <div
            class="pb-8"
            v-if="!tokenCode && classDetails?.class && classDetails?.assignment"
          >
            <h4
              class="text-flohh-text-title font-flohh-font-bold text-flohh-neutral-20"
            >
              Assignment Information
            </h4>
            <div
              class="p-5 border border-solid border-flohh-neutral-85 rounded-lg mt-5 flex flex-col"
            >
              <div class="flex justify-between w-full mt-[1.25rem] gap-2">
                <p class="text-flohh-text-body text-flohh-neutral-35 w-[30%]">
                  Class Name
                </p>
                <p
                  class="text-flohh-text-body font-flohh-font-bold text-flohh-neutral-35 w-[70%] text-right"
                >
                  {{ classDetails?.class }}
                </p>
              </div>
              <Divider />
              <div class="flex justify-between w-full gap-2">
                <p class="text-flohh-text-body text-flohh-neutral-35 w-[30%]">
                  Assignment Title
                </p>
                <p
                  class="text-flohh-text-body font-flohh-font-bold text-flohh-neutral-35 w-[70%] text-right"
                >
                  {{ classDetails?.assignment }}
                </p>
              </div>
            </div>
          </div>

          <h4
            class="text-flohh-text-title font-flohh-font-bold text-flohh-neutral-20 pb-6"
          >
            Student Information
          </h4>
          <form @submit.prevent="onSubmit" autocomplete="off" class="w-full">
            <div class="flex flex-col lg:flex-row gap-5">
              <div
                class="col-span-1 w-full flex justify-start flex-col items-start md:mb-4"
              >
                <p class="font-semibold text-flohh-text-body mb-1">
                  First Name
                </p>
                <FormTextInput
                  type="text"
                  v-model="form.firstName"
                  placeholder="Enter Firstname"
                  name="firstName"
                />

                <ErrorSpan v-if="errMsg.firstName">
                  {{ errMsg.firstName }}
                </ErrorSpan>
              </div>
              <div
                class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
              >
                <p class="font-semibold text-flohh-text-body mb-1">Last Name</p>
                <FormTextInput
                  type="text"
                  v-model="form.lastName"
                  placeholder="Enter Lastname"
                  name="lastName"
                />

                <ErrorSpan v-if="errMsg.lastName">
                  {{ errMsg.lastName }}
                </ErrorSpan>
              </div>
            </div>

            <div class="flex flex-col lg:flex-row gap-5" v-if="!tokenCode">
              <div
                class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
              >
                <p class="font-semibold text-flohh-text-body mb-1">Email</p>
                <FormTextInput
                  type="email"
                  v-model="form.email"
                  placeholder="Enter Email"
                  name="email"
                />

                <ErrorSpan v-if="errMsg.email">
                  {{ errMsg.email }}
                </ErrorSpan>
              </div>
            </div>

            <div class="flex flex-col lg:flex-row gap-5">
              <div
                class="col-span-1 w-full flex justify-start flex-col items-start md:mb-4"
              >
                <p class="font-semibold text-flohh-text-body mb-1">Password</p>
                <FormTextInput
                  type="password"
                  placeholder="Enter Password"
                  v-model="form.password"
                  name="password"
                  iconRight="eyeActive"
                />
                <ErrorSpan v-if="errMsg.password">
                  {{ errMsg.password }}
                </ErrorSpan>
              </div>
              <div
                class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
              >
                <p class="font-semibold text-flohh-text-body mb-1">
                  Confirm Password
                </p>
                <FormTextInput
                  type="password"
                  placeholder="Confirm Password"
                  v-model="form.confirmPassword"
                  name="confirmPassword"
                  iconRight="eyeActive"
                />
                <ErrorSpan v-if="errMsg.confirmPassword">
                  {{ errMsg.confirmPassword }}
                </ErrorSpan>
              </div>
            </div>

            <div class="flex justify-center items-center mt-6 mb-10 w-full">
              <Checkbox
                v-model="isAgree"
                name="isAgree"
                :value="true"
                :pt="{
                  input: {
                    class: '!border-flohh-neutral-85 !border-solid !border-2',
                  },
                }"
              />
              <label
                class="ml-2 text-flohh-text-caption font-medium color-flohh-neutral-20"
                for="isAgree"
                >I agree to
                <router-link to="#" class="underline text-flohh-primary-pink"
                  >Flohh’s Terms & Conditions</router-link
                ></label
              >
            </div>
            <div class="flex items-center justify-center mb-14">
              <CaptchaCheckbox theme="light" v-model="isVerified" />
            </div>
            <RegistrationActionComponent
              :handleView="handleView"
              :isLoading="isLoading"
              :isVerified="isVerified"
            />
          </form>
        </section>
      </template>
      <template v-else>
        <div class="pt-2 pb-6 text-center max-w-2xl">
          <p class="text-flohh-text-title color-flohh-neutral-20 font-bold">
            Verify with OTP Code
          </p>
          <label
            class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
            >Enter the 6-digit verification code that was sent to your email.
          </label>
        </div>
        <ActivationComponent
          :verificationError="verificationError"
          :inputs="inputs"
          :handlePaste="handlePaste"
          :handleResend="handleResend"
          :handleVerify="handleVerify"
          :isVerifying="isVerifying"
        />
      </template>
    </div>
    <!-- <ActivationModalComponent
      v-model="visible"
      :onClose="closeModal"
      modalTitle="Student Activation"
      :verificationError="verificationError"
      :inputs="inputs"
      :handlePaste="handlePaste"
      :handleResend="handleResend"
      :handleVerify="handleVerify"
      :isVerifying="isVerifying"
    /> -->
  </div>

  <ModalUtility
    v-model="showInvalid"
    title="Failed"
    :draggable="false"
    width="450px"
    containerStyle=""
  >
    <template #content>
      <div class="flex flex-col items-center justify-center gap-4 p-6">
        <span
          ><svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_76_33458"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="80"
              height="80"
            >
              <rect width="80" height="80" fill="#D9D9D9"></rect>
            </mask>
            <g mask="url(#mask0_76_33458)">
              <path
                d="M39.9997 44.6666L23.6663 60.9999C23.0552 61.611 22.2775 61.9166 21.333 61.9166C20.3886 61.9166 19.6108 61.611 18.9997 60.9999C18.3886 60.3888 18.083 59.611 18.083 58.6666C18.083 57.7221 18.3886 56.9444 18.9997 56.3333L35.333 39.9999L18.9997 23.6666C18.3886 23.0555 18.083 22.2777 18.083 21.3333C18.083 20.3888 18.3886 19.611 18.9997 18.9999C19.6108 18.3888 20.3886 18.0833 21.333 18.0833C22.2775 18.0833 23.0552 18.3888 23.6663 18.9999L39.9997 35.3333L56.333 18.9999C56.9441 18.3888 57.7219 18.0833 58.6663 18.0833C59.6108 18.0833 60.3886 18.3888 60.9997 18.9999C61.6108 19.611 61.9163 20.3888 61.9163 21.3333C61.9163 22.2777 61.6108 23.0555 60.9997 23.6666L44.6663 39.9999L60.9997 56.3333C61.6108 56.9444 61.9163 57.7221 61.9163 58.6666C61.9163 59.611 61.6108 60.3888 60.9997 60.9999C60.3886 61.611 59.6108 61.9166 58.6663 61.9166C57.7219 61.9166 56.9441 61.611 56.333 60.9999L39.9997 44.6666Z"
                fill="#F59289"
              ></path>
            </g>
          </svg>
        </span>
        <p class="text-flohh-text-title font-flohh-font-bold text-center">
          Unable to Verify
        </p>
        <p class="text-flohh-text-body text-center">
          <span>{{ this.verificationError.message }}</span>
        </p>
      </div>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import ModalCardComponent from "@/components/Modal/ModalCardComponent.vue";
import RegistrationFormComponent from "@/components/Authentication/Registration/RegistrationFormComponent.vue";
import RegistrationActionComponent from "@/components/Authentication/Registration/RegistrationActionComponent.vue";
import {
  IRegistrationInfo,
  TRegistrationInfo,
} from "@/components/Authentication/Registration/types";
import { validateEmail, validatePassword } from "@/utils/helper";
import ErrorSpan from "@/components/utilities/ErrorSpan.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import Checkbox from "primevue/checkbox";
import Divider from "primevue/divider";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import { icons as AppIcons } from "@/utils/icons";
import axios, { AxiosResponse } from "axios";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
// import ActivationModalComponent from "@/components/Authentication/Registration/ActivationModalComponent.vue";
import { Checkbox as CaptchaCheckbox } from "vue-recaptcha";
import ActivationComponent from "@/components/Authentication/Registration/ActivationComponent.vue";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
interface IFormData extends IErrorMessage {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

interface IErrorMessage {
  [key: string]: string;
}
interface ClipboardEvent extends Event {
  clipboardData: DataTransfer;
}

interface IClass {
  class: string;
  teacher?: string;
  assignment?: string;
}

@Component({
  components: {
    ModalCardComponent,
    RegistrationFormComponent,
    RegistrationActionComponent,
    ErrorSpan,
    Checkbox,
    CaptchaCheckbox,
    FormTextInput,
    AppButton,
    Divider,
    AppIcons,
    ActivationComponent,
    ModalUtility,
  },
})
export default class StudentRegistrationView extends Vue {
  toast = useToast();

  @Prop({
    type: Function,
    required: true,
  })
  private updateView!: () => void;

  isAgree = false;
  isLoading = false;
  route = "/register/onboarding";

  registrationAccessToken = "";

  form: IRegistrationInfo = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  validationMessage!: TRegistrationInfo;
  visible = false;
  isVerifying = false;

  icon = AppIcons;

  errMsg: IFormData = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  validationTimeout!: number;

  classDetails!: IClass;
  inviteCode = "";
  tokenCode = "";

  otpVerified = false;

  inputs: { value: string }[] = [
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
  ];

  verificationError = {
    show: false,
    message: "Invalid code",
  };

  isVerified = false;

  viewActivation = false;
  showInvalid = false;

  @Watch("visible")
  visibleWatcher(newValue: any) {
    if (this.registrationAccessToken && !newValue && !this.otpVerified) {
      window.location.href = "/student/login";
    }
  }

  handlePaste(event: Event) {
    const codes = this.splitAndDisplay(event);
    if (codes) {
      codes.forEach((item, index) => {
        this.inputs[index].value = item;
      });
    }
  }

  splitAndDisplay(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const clipboardData = (event as ClipboardEvent).clipboardData;
    const pastedText = clipboardData.getData("text");

    const characters = pastedText.split("");

    event.preventDefault();
    return characters;
  }

  async handleVerify() {
    this.isVerifying = true;
    try {
      const code = this.inputs.map((item) => item.value).join("");
      ///authentication/otp/verify
      const allValuesNotEmpty = this.inputs.every((item) =>
        Boolean(item.value)
      );
      if (allValuesNotEmpty) {
        const payload = {
          code: code,
          type: "student_activation",
          token: this.registrationAccessToken,
        };
        const response: any = await axios
          .post("/authentication/otp/verify", payload)
          .then((response) => {
            localStorage.setItem("auth", JSON.stringify(response.data.data));
            const authorization = `Bearer ${response.data.data.accessToken}`;
            axios.defaults.headers.common["Authorization"] = authorization;
            this.isVerifying = false;

            if (response.data.ok) {
              this.otpVerified = true;
              localStorage.setItem(
                "authenticatedAs",
                response.data.data.authenticatedAs
              );
              window.location.href = "/student/dashboard";
            }
          })
          .catch((error) => {
            this.isVerifying = false;
            // this.showError(error.message);
            // this.verificationError.show = true;
            this.showInvalid = true;
            this.verificationError.message = error.response.data.details.info;
          });
      } else {
        // this.verificationError.show = true;
        this.showInvalid = true;
        this.verificationError.message = "Invalid code";
      }
    } catch (e) {
      this.isVerifying = false;
      //
    } finally {
      this.isVerifying = false;
      //
    }
  }

  async handleResend() {
    const payload = {
      type: "student_activation",
      token: this.registrationAccessToken,
    };

    const response: any = await axios
      .post("/authentication/otp/resend", payload)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 422) {
          this.showError(error.message);
        }
      });
    if (response.data.ok) {
      this.registrationAccessToken = response.data.data.accessToken;
      this.toast.add({
        severity: "success",
        detail: "Resent code in your email",
        life: 3000,
      });
    }
  }
  handleValidation(data: TRegistrationInfo) {
    this.validationMessage = data;
  }

  areAllValuesEmpty<T extends Record<string, string>>(obj: T): boolean {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== "") {
        return false;
      }
    }
    return true;
  }

  mounted() {
    this.inviteCode = this.$route.query.code
      ? this.$route.query.code.toString()
      : "";

    this.tokenCode = this.$route.query.token
      ? this.$route.query.token.toString()
      : "";

    if (this.inviteCode || this.tokenCode) {
      this.getClassDetails();
    } else {
      if (localStorage.getItem("auth")) {
        this.$router.push({
          path: "/student/dashboard",
        });
      } else {
        this.$router.push({
          path: "/student/login",
        });
      }
    }
  }

  async getClassDetails() {
    let endpoint = "";
    if (this.inviteCode) {
      endpoint = `class/invite-code/${this.inviteCode}`;
    } else {
      endpoint = `users/activate?activationToken=${this.tokenCode}&role=student`;
    }

    const response = await axios.get(endpoint);
    let className = "";
    let teacherName = "";
    if (this.inviteCode) {
      const { code, teacher } = response.data.data;
      teacherName = teacher
        ? teacher.profile.firstName && teacher.profile.lastName
          ? `${teacher.profile.firstName} ${teacher.profile.lastName}`
          : ""
        : "";
      className = code;
    } else {
      const data = response.data.data;
      className = data.class.code;
      teacherName =
        data.class.teacher.profile.firstName +
        " " +
        data.class.teacher.profile.lastName;

      this.form.firstName = data.firstName;
      this.form.lastName = data.lastName;
    }

    this.classDetails = {
      class: className,
      teacher: teacherName,
    };
  }

  @Watch("form.firstName")
  formFirstNameWatcher(newValue: any) {
    this.onInput("firstName", newValue);
  }
  @Watch("form.lastName")
  formLastNameWatcher(newValue: any) {
    this.onInput("lastName", newValue);
  }
  @Watch("form.email")
  formEmailWatcher(newValue: any) {
    this.onInput("email", newValue);
  }
  @Watch("form.password")
  formPasswordWatcher(newValue: any) {
    this.onInput("password", newValue);
  }
  @Watch("form.confirmPassword")
  formConfirmPasswordWatcher(newValue: any) {
    this.onInput("confirmPassword", newValue);
  }
  onInput(name: string, value: string) {
    if (name) {
      if (value) {
        this.errMsg[name] = "";

        if (name.toLocaleLowerCase().search("email") > -1) {
          this.errMsg[name] = validateEmail(value);
        } else if (name.toLocaleLowerCase().search("password") > -1) {
          this.errMsg[name] = validatePassword(value);
        } else {
          //
        }

        if (this.form.firstName.length === 1) {
          this.errMsg.firstName =
            "Enter a valid firstname. Should be atleast 3 characters";
        }

        if (this.form.lastName.length === 1) {
          this.errMsg.lastName =
            "Enter a valid firstname. Should be atleast 3 characters";
        }

        if (this.form.password && this.form.confirmPassword) {
          const validPassword =
            validatePassword(this.form.password) &&
            validatePassword(this.form.confirmPassword);
          const doPasswordsMatch =
            this.form.password === this.form.confirmPassword;
          if (!validPassword && !doPasswordsMatch) {
            this.errMsg.confirmPassword =
              "Password and Confirm Password should match";
          } else {
            this.errMsg.confirmPassword = validPassword;
          }
        }
      } else {
        this.errMsg[name] = "Field is required";
      }
    }
    this.handleValidation(this.errMsg);
    // }, 200);
  }

  /**
   * onSubmit
   */
  public async onSubmit() {
    this.isLoading = true;
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
    }: IRegistrationInfo = this.form;

    const payload = {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
    };

    try {
      const allValid: boolean = this.areAllValuesEmpty(this.validationMessage);
      const allEmpty: boolean = this.areAllValuesEmpty(payload);
      const isChecked = Array.isArray(this.isAgree) ? this.isAgree[0] : false;
      if (allValid && !allEmpty && isChecked) {
        if (this.isVerified) {
          this.isLoading = true;

          let acceptedPayload = {};
          const endpoint = this.inviteCode
            ? `/class/invite/${this.inviteCode}`
            : `/users/activate?activationToken=${this.tokenCode}&role=student`;

          if (this.inviteCode) {
            acceptedPayload = {
              email: payload.email,
              name: `${payload.firstName} ${payload.lastName}`,
              firstName: payload.firstName,
              lastName: payload.lastName,
              password: payload.password,
            };
          } else {
            acceptedPayload = {
              name: `${payload.firstName} ${payload.lastName}`,
              firstName: payload.firstName,
              lastName: payload.lastName,
              password: payload.password,
            };
          }
          const response = await axios.post(endpoint, acceptedPayload);
          if (response.data.ok) {
            if (this.inviteCode) {
              // this.verificationError.show = false;
              this.showInvalid = false;
              this.verificationError.message = "Invalid code";
              this.registrationAccessToken = response.data.data.accessToken;
              // this.visible = true;
              this.viewActivation = true;
              this.inputs = [
                { value: "" },
                { value: "" },
                { value: "" },
                { value: "" },
                { value: "" },
                { value: "" },
              ];
            } else {
              this.$router.push({
                path: "/student/success",
              });
            }
          } else {
            console.error(response.data.message);
            this.showError(response.data.message);
          }
          this.isLoading = false;
        } else {
          this.showError("Please verify that you're not a robot.");
        }
      } else {
        // alert("Please complete the fields.");
        this.showError("Please complete the fields.");
        this.isLoading = false;
      }
    } catch (error) {
      if (error instanceof ReferenceError) {
        console.error(error.message);
      }
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  }

  public handleView() {
    // You can perform some logic here and then call the parent function
    // this.updateView();
    this.$router.push("/register");
  }

  private showError(message: string) {
    this.toast.add({
      severity: "error",
      detail: message,
      life: 3000,
    });
  }

  closeModal() {
    this.visible = false;
    this.isLoading = false;
  }
}
</script>

<style scoped lang="scss">
$browser-context: 16; // Default
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

.registration-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}
.registration-wrap {
  background-color: #f4d5de;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 926px;
  margin-bottom: 80px;
  h4 {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  form {
    width: 100%;
  }
}
</style>
