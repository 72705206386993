<script lang="ts">
import { Vue, Component, Prop, Model, Watch } from "vue-facing-decorator";
import { icons as AppIcons } from "../../../utils/icons";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import ConfirmDialog from "primevue/confirmdialog";
import emitter from "@/config/emitter";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import { IUserNotifcation } from "@/modules/UserNotifications/Store/types";
import { Vue3Lottie } from "vue3-lottie";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import Banner from "./Banner.vue";
import Teleport from "vue";
import { routes } from "@/router";
import { AssignmentDashboard } from "@/store/dashboard/dashboardTypes";
import DashboardService from "@/services/DashboardService";
import { PendingActions } from "@/store/dashboard/dashboardTypes";

interface IRouterConfig {
  name: string;
  params?: Record<string, string>;
  query?: Record<string, string>;
}

@Component({
  components: {
    ConfirmDialog,
    ModalUtility,
    ProgressLoader,
    Vue3Lottie,
    AppButton,
    Banner,
    Teleport,
  },
})
export default class DynamicOverlay extends Vue {
  private dashboardService: DashboardService = new DashboardService();

  @Model({
    type: Boolean,
    default: false,
  })
  visible!: boolean;

  @Prop({
    type: Boolean,
    required: false,
  })
  isSuperAdmin!: boolean;

  @Prop({
    type: Boolean,
    required: false,
  })
  overlayLoading!: boolean;

  @Prop({
    type: String,
    required: true,
  })
  role!: string;

  @Prop({
    type: Array,
    required: true,
  })
  pendingActions!: PendingActions[];

  icons = AppIcons;
  portalRoutes = routes;

  confirm = useConfirm();
  toast = useToast();
  eventBus = emitter;

  @Prop({
    type: Object,
    required: true,
  })
  userNotification!: IUserNotifcation;

  allAssignments: AssignmentDashboard[] = [];
  latestClassUuid = "";
  latestAssignmentUuid = "";
  isLoading = true;

  fetchingData = false;

  showAnimation = false;
  // animationType: string[] = [
  //   "https://lottie.host/embed/b2ad2f89-2393-42e2-8db3-5108c5176cac/CcDs93IHw2.json",
  //   "https://lottie.host/embed/914cbe7c-b28d-43b2-b860-871b56962258/bWdS3CNqg0.json",
  //   "https://lottie.host/embed/914cbe7c-b28d-43b2-b860-871b56962258/bWdS3CNqg0.json",
  // ];
  animationType: string[] = [
    "/lottie-confetti.json",
    "/lottie-balloons.json",
    "/lottie-balloons.json",
  ];
  animationIndex: number | null = null;

  attachmentType = "link";
  attachment = "";

  ctaIcons: Record<string, string> = {
    forwardArrowIcon: "arrowForwardBlackIcon",
    checkIcon: "checkBlack",
    informationIcon: "infoCircleBlack",
  };

  @Watch("overlayLoading")
  overlayLoadingWatcher(flag: boolean) {
    this.isLoading = flag;
  }

  @Watch("visible")
  async visibleWatcher(value: boolean) {
    if (
      value &&
      this.userNotification &&
      this.userNotification.ctaLinkType === "internal" &&
      this.allAssignments.length === 0
    ) {
      try {
        this.fetchingData = true;
        const response = await this.dashboardService.getTeacherDashboard();
        if (response.data.ok) {
          const responseData = response.data.data;
          if (responseData.length > 0) {
            const lastIndex = responseData.length - 1;
            this.allAssignments = responseData;

            this.latestAssignmentUuid = responseData[lastIndex].uuid;
            this.latestClassUuid = responseData[lastIndex].class.uuid;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.fetchingData = false;
      }
    }
  }

  @Watch("userNotification", {
    deep: true,
  })
  userNotificationWatch(data: IUserNotifcation, oldValue: IUserNotifcation) {
    if (data.animationType !== "none") {
      this.showAnimation = true;
      if (data.animationType === "confetti") {
        this.animationIndex = 0;
      } else if (data.animationType === "balloons") {
        this.animationIndex = 1;
      } else {
        this.animationIndex = null;
      }
    } else {
      this.showAnimation = false;
    }

    if (data && data.resources?.length) {
      this.attachment = "";
      this.handleAttachment(data.resources[0]);
    } else {
      this.attachment = "";
    }
  }
  mounted() {
    if (this.userNotification && this.userNotification.resources?.length) {
      this.handleAttachment(this.userNotification.resources[0]);
    }

    this.isLoading = false;
  }

  beforeUnmount() {
    //
  }

  handleCTAClick() {
    if (this.userNotification.ctaLinkType === "external") {
      window.open(this.userNotification.ctaLink, "_blank")?.focus();
    } else {
      const routeName = this.userNotification.ctaLink;
      if (routeName === "Marking") {
        this.handleVisitMarking(routeName);
      } else if (routeName === "BulkUpload") {
        this.handleVisitBulkUpload(routeName);
      } else if (routeName === "BulkUploadSplit") {
        this.handleVisitBulkUploadSplit(routeName);
      } else if (routeName === "Classes") {
        this.handleVisitClasses(routeName);
      } else if (routeName === "EditAssignment") {
        this.handleVisitEditAssignment(routeName);
      } else {
        this.$router.push({ name: routeName });
      }
    }
  }

  handleVisitMarking(routeName: string) {
    //
  }

  handleVisitBulkUpload(routeName: string) {
    this.$router.push({ name: routeName, params: { type: "paper" } });
  }

  handleVisitBulkUploadSplit(routeName: string) {
    let routerConfig: IRouterConfig = { name: routeName };

    const latestBulkUpload = this.pendingActions.find(
      (pendingAction: PendingActions) =>
        pendingAction.type === "bulkUpload" &&
        pendingAction.data.status === "pending"
    );
    if (latestBulkUpload) {
      routerConfig = {
        ...routerConfig,
        params: { type: "paper", id: latestBulkUpload.data.assignment },
      };
    } else {
      routerConfig = {
        ...routerConfig,
        params: { type: "paper" },
      };
      this.showToast(
        "You don't have assignments that has pending bulk upload",
        "warn"
      );
    }

    this.$router.push(routerConfig);
  }

  handleVisitClasses(routeName: string) {
    let routerConfig: IRouterConfig = { name: routeName };

    if (this.latestClassUuid) {
      routerConfig = {
        ...routerConfig,
        params: { id: this.latestClassUuid },
      };
    } else {
      this.showToast(
        "You don't have existing class, please create one first",
        "warn"
      );
      routerConfig.name = "ClassesList";
    }

    this.$router.push(routerConfig);
  }

  handleVisitEditAssignment(routeName: string) {
    let routerConfig: IRouterConfig = { name: routeName };

    if (this.latestAssignmentUuid) {
      routerConfig = {
        ...routerConfig,
        params: { id: this.latestAssignmentUuid },
      };
    } else {
      this.showToast(
        "You don't have existing assignment, please create one first",
        "warn"
      );
      routerConfig.name = "CreateAssignment";
    }

    this.$router.push(routerConfig);
  }

  handleClose() {
    this.visible = false;
    this.showAnimation = false;
    this.$emit("handleNextAlert");
  }

  handleAttachment(data: any) {
    //
    if (Object.prototype.hasOwnProperty.call(data, "attachmentType")) {
      this.attachmentType = data.attachmentType;
      this.attachment = data.content;
    } else {
      if (Object.prototype.hasOwnProperty.call(data, "link")) {
        this.attachmentType = "link";
        this.attachment = data.link;
      } else {
        this.attachmentType = "media";
        this.attachment = `data:image/png;base64,${data.data}`;
      }
    }

    console.log(this.attachmentType);
  }

  loadVideo(link: any) {
    let src = "";

    const url: any = link;
    if (typeof url !== "string") {
      console.error("Expected a string URL.");
      return "";
    }

    if (url.includes("youtube.com/watch") || url.includes("youtu.be/")) {
      const youtubeId =
        url.split("v=")[1]?.split("&")[0] || url.split("youtu.be/")[1];
      if (youtubeId) {
        src = `https://www.youtube.com/embed/${youtubeId}`;
      } else {
        // alert("Invalid YouTube URL");
      }
    } else if (url.includes("loom.com")) {
      const loomId = url.split("/").pop();
      src = `https://www.loom.com/embed/${loomId}`;
    } else {
      // alert("Please enter a valid YouTube or Loom URL");
      return;
    }
    return src;
  }

  showToast(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }
}
</script>
<template>
  <ModalUtility
    v-if="userNotification && userNotification.viewAction === 'modal'"
    v-model="visible"
    width="800px"
    :title="userNotification?.title"
    @onClose="visible = false"
    :draggable="false"
    containerStyle="!w-full !max-w-[800px] "
    maskClass="top-zindex"
  >
    <template #header>
      <div
        class="w-full h-[76px] bg-flohh-primary-pink rounded-t-[4px] sm:py-0 flex flex-col justify-center items-start bg-[url('@/assets/modal-header-bg.png')] bg-repeat !bg-contain z-[1]"
      >
        <div
          class="absolute top-50 right-50 px-4 flex flex-col items-start justify-center w-full"
        >
          <h1 class="text-flohh-h6 font-flohh-font-bold text-flohh-neutral-20">
            {{ userNotification?.title }}
          </h1>
          <!-- Use for teleportation don't remove this tag -->
          <p id="modal-subtitle-teleport" class="font-semibold"></p>
          <!-- end of teleportation -->
        </div>
        <div class="btn-close-wrap absolute top-[10px] right-[10px]">
          <button class="p-2 text-white rounded-full" @click="handleClose">
            <img src="@/assets/close.svg" class="w-4" />
          </button>
        </div>
      </div>
    </template>
    <template #content>
      <div
        class="px-[28px] py-[30px] h-auto max-h-[80vh] min-h-[200px] overflow-auto"
      >
        <div v-if="!isLoading" class="flex flex-col gap-[23px]">
          <p
            class="text-[18px] font-bold leading-[1.5] dynamic-overlay-body"
            v-if="userNotification?.body"
          >
            <span v-html="userNotification?.body"></span>
          </p>

          <div
            class="flex justify-center items-center"
            v-if="attachmentType === 'link'"
          >
            <div
              class="relative overflow-hidden w-full pt-[56.25%]"
              v-if="attachment"
            >
              <iframe
                :src="loadVideo(attachment)"
                frameborder="0"
                allowfullscreen
                class="absolute !w-full !h-full top-0 left-0"
              ></iframe>
            </div>
          </div>
          <div
            class="flex justify-center items-center"
            v-if="attachmentType === 'media'"
          >
            <img :src="attachment" class="w-[50%] h-auto" />
          </div>
        </div>
        <div
          class="flex w-full items-center justify-center min-h-[400px] h-auto"
          v-else
        >
          <div class="relative top-[-20px]">
            <ProgressLoader />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        v-if="userNotification.useCta"
        class="flex pt-5 border-t border-solid border-flohh-neutral-85 justify-end"
      >
        <AppButton
          @click.prevent="handleCTAClick"
          type="submit"
          :label="userNotification.ctaButtonText"
          :iconLeft="ctaIcons[userNotification.ctaButtonIcon]"
          :disabled="fetchingData"
          :loading="fetchingData"
        /></div
    ></template>
  </ModalUtility>

  <Teleport
    to="#banners-wrapper"
    v-if="
      visible && userNotification && userNotification.viewAction === 'banner'
    "
  >
    <Banner :show="visible" @onHide="handleClose">
      <p class="text-flohh-text-subtitle dynamic-overlay-body">
        <span v-html="userNotification.body" /></p
    ></Banner>
  </Teleport>

  <div
    class="fixed top-0 left-0 z-[99999] pointer-events-none"
    v-if="showAnimation && animationIndex !== null && !isLoading"
  >
    <Vue3Lottie
      class="w-screen h-screen fixed top-0 left-[-50%]"
      :animationLink="animationType[animationIndex]"
      v-if="animationIndex === 1"
    ></Vue3Lottie>
    <Vue3Lottie
      class="w-screen h-screen fixed left-[-30%] top-0"
      :animationLink="animationType[animationIndex]"
      v-if="animationIndex === 1"
    ></Vue3Lottie>
    <Vue3Lottie
      class="w-screen h-screen fixed top-0 left-0"
      :animationLink="animationType[animationIndex]"
    ></Vue3Lottie>
    <Vue3Lottie
      class="w-screen h-screen fixed left-[30%] top-0"
      :animationLink="animationType[animationIndex]"
      v-if="animationIndex === 1"
    ></Vue3Lottie>
    <Vue3Lottie
      class="w-screen h-screen fixed left-[50%] top-0"
      :animationLink="animationType[animationIndex]"
      v-if="animationIndex === 1"
    ></Vue3Lottie>
  </div>
</template>

<style lang="scss">
.dynamic-overlay-body {
  a {
    text-decoration: underline;
  }

  ul {
    list-style-type: disc;
    padding: 20px;
  }

  li {
    list-style-type: disc;
  }
}
</style>
