import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full bg-flohh-neutral-95 pt-3 pb-10 px-5 border-flohh-neutral-85 border-dashed border rounded-lg mb-5" }
const _hoisted_2 = { class: "flex gap-9 justify-center mb-3" }
const _hoisted_3 = { class: "cursor-pointer flex items-center flex-col hover:scale-110 active:scale-100 transition-all" }
const _hoisted_4 = { class: "mb-1 rounded-[50px] bg-flohh-neutral-100 w-[40px] h-[40px] flex justify-center items-center border-flohh-neutral-85 border border-solid" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "cursor-pointer flex items-center flex-col hover:scale-110 active:scale-100 transition-all" }
const _hoisted_7 = { class: "mb-1 rounded-[50px] bg-flohh-neutral-100 w-[40px] h-[40px] flex justify-center items-center border-flohh-neutral-85 border border-solid" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "mb-1 rounded-[50px] bg-flohh-neutral-100 w-[40px] h-[40px] flex justify-center items-center border-flohh-neutral-85 border border-solid" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "mb-1 rounded-[50px] bg-flohh-neutral-100 w-[40px] h-[40px] flex justify-center items-center border-flohh-neutral-85 border border-solid" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 0,
  class: "flex justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_DefaultFileUploader = _resolveComponent("DefaultFileUploader")!
  const _component_GoogleDriveFileUploader = _resolveComponent("GoogleDriveFileUploader")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AppTypographyText, {
        label: _ctx.title,
        variant: "bd",
        type: "subtitle",
        class: "mb-3"
      }, null, 8, ["label"]),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.visibility.localUpload)
          ? (_openBlock(), _createBlock(_component_DefaultFileUploader, _mergeProps({
              key: 0,
              selectedFiles: _ctx.selectedFilesModel,
              "onUpdate:selectedFiles": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFilesModel) = $event))
            }, { ..._ctx.localFileUploaderConfig }, {
              useSlot: true,
              multiple: _ctx.multiple
            }), {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("span", {
                      innerHTML: _ctx.icons.uploadBlack
                    }, null, 8, _hoisted_5)
                  ]),
                  _createVNode(_component_AppTypographyText, {
                    label: "Upload",
                    type: "small"
                  })
                ])
              ]),
              _: 1
            }, 16, ["selectedFiles", "multiple"]))
          : _createCommentVNode("", true),
        (_ctx.visibility.gDriveUpload)
          ? (_openBlock(), _createBlock(_component_GoogleDriveFileUploader, _mergeProps({
              key: 1,
              selectedFiles: _ctx.selectedFilesModel,
              "onUpdate:selectedFiles": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedFilesModel) = $event))
            }, { ..._ctx.gDriveFileUploaderConfig }, {
              useSlot: true,
              multiple: _ctx.multiple
            }), {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", {
                      innerHTML: _ctx.icons.googleDrive
                    }, null, 8, _hoisted_8)
                  ]),
                  _createVNode(_component_AppTypographyText, {
                    label: "Drive",
                    type: "small"
                  })
                ])
              ]),
              _: 1
            }, 16, ["selectedFiles", "multiple"]))
          : _createCommentVNode("", true),
        (_ctx.visibility.youtubeUpload)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "cursor-pointer flex items-center flex-col hover:scale-110 active:scale-100 transition-all",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleClickYoutubeUpload && _ctx.handleClickYoutubeUpload(...args)))
            }, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", {
                  innerHTML: _ctx.icons.youtubeIcon
                }, null, 8, _hoisted_10)
              ]),
              _createVNode(_component_AppTypographyText, {
                label: "Youtube",
                type: "small"
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.visibility.linkUpload)
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              class: "cursor-pointer flex items-center flex-col hover:scale-110 active:scale-100 transition-all",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleClickUploadLink && _ctx.handleClickUploadLink(...args)))
            }, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("span", {
                  innerHTML: _ctx.icons.iconLink
                }, null, 8, _hoisted_12)
              ]),
              _createVNode(_component_AppTypographyText, {
                label: "Link",
                type: "small"
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.supportedFileTypes)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createVNode(_component_AppTypographyText, {
              label: _ctx.supportedFileTypes,
              type: "caption",
              class: "mb-3"
            }, null, 8, ["label"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}