<template>
  <section
    class="min-h-[calc(100vh_-_80px)] overflow-hidden relative flex flex-col gap-4"
  >
    <template v-if="!onboardingComplete && !loadingDashboard">
      <div
        v-if="!demoMarkingDone"
        class="flex flex-col p-[34px] bg-white rounded-lg"
      >
        <h6 class="text-[24px] leading-[1.5] text-[#333] font-bold">
          Hi {{ firstName }}!
        </h6>
        <div class="flex gap-4 items-center">
          <p class="text-[18px] leading-[1.5] text-[#333] font-bold">
            Welcome to Flohh
          </p>
          <button @click="isWelcomeModalOpen = true">
            <span v-html="icons.infoCircleBlack" />
          </button>
        </div>
      </div>
      <DashboardHeaderComponent
        v-else
        :firstName="firstName"
        :handleSubmissionModal="handleSubmissionModal"
        :showWelcomeVideoToggle="true" />
      <div class="flex flex-col p-[34px] bg-white rounded-lg">
        <OnboardingTasksComponent
          :onboardingChecklist="onboardingChecklist"
          :assignments="assignments"
        />
      </div>
      <DashboardAssignmentComponent :assignments="assignments"
    /></template>
    <template v-if="onboardingComplete && !loadingDashboard">
      <DashboardHeaderComponent
        :firstName="firstName"
        :handleSubmissionModal="handleSubmissionModal"
      />
      <DashboardAssignmentComponent
        v-if="!loadingDashboard"
        :assignments="assignments"
      />
      <DashboardJourneyComponent />
    </template>

    <template v-if="loadingDashboard">
      <div
        class="w-full flex items-center justify-center h-[calc(100vh_-_120px)]"
      >
        <ProgressLoader label="Preparing Dashboard" />
      </div>
    </template>
  </section>

  <ModalUtility v-model="isOpenAssignment" title="Create an assignment">
    <template #content>
      <div class="bg-white">
        <CreateAssignment
          @onClickDone="isOpenAssignment = false"
          :maximize="true"
        />
      </div>
    </template>
  </ModalUtility>

  <GenerateSubmissionLinkModal v-model="submissionModalOpen" />

  <WelcomeModal v-model="isWelcomeModalOpen" :firstName="firstName" />
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { GridLayout, GridItem } from "grid-layout-plus";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import CreateClassButton from "../CreateClassPopup/GroupPopup/CreateClassButton.vue";
import KanbanCellComponent from "./KanbanCellComponent.vue";

import { getTeacherUuid } from "@/config/auth";
import ModalUtility from "../utilities/ModalUtility.vue";
import CreateAssignment from "../Assignment/CreateAssignment/CreateAssignment.vue";
import emitter from "@/config/emitter";
import {
  AssignmentDashboard,
  StudentDashboard,
  PendingActions,
} from "@/store/dashboard/dashboardTypes";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import axios, { AxiosResponse } from "axios";
import AppCardButton from "@/components/Layout/Buttons/AppCardButton.vue";
import AssignmentCardComponent from "@/components/Assignments/utilities/AssignmentCardComponent.vue";
import OnboardingVideoComponent from "./OnboardingVideoComponent.vue";
import OnboardingTasksComponent from "./OnboardingTasksComponent.vue";
import DashboardHeaderComponent from "./DashboardHeaderComponent.vue";
import DashboardAssignmentComponent from "./DashboardAssignmentComponent.vue";
import DashboardJourneyComponent from "./DashboardJourneyComponent.vue";
import GenerateSubmissionLinkModal from "@/components/Classes/SubmissionLinkModal/GenerateSubmissionLinkModal.vue";
import ProgressLoader from "../utilities/ProgressLoader.vue";
import DashboardService from "@/services/DashboardService";
import { setClassess } from "@/store/class/class-dispatch";
import WelcomeModal from "./WelcomeModal.vue";

@Component({
  components: {
    GridLayout,
    GridItem,
    KanbanCellComponent,
    AppButton,
    ModalUtility,
    CreateAssignment,
    CreateClassButton,
    AppTypographyText,
    AppCardButton,
    AssignmentCardComponent,
    OnboardingVideoComponent,
    OnboardingTasksComponent,
    DashboardHeaderComponent,
    DashboardAssignmentComponent,
    DashboardJourneyComponent,
    GenerateSubmissionLinkModal,
    ProgressLoader,
    WelcomeModal,
  },
})
export default class DashboardComponent extends Vue {
  private dashboardService = new DashboardService();

  newTeacher = true;
  loadingDashboard = true;

  isTeacher = true;
  submissionModalOpen = false;

  icons = AppIcons;
  assignments: AssignmentDashboard[] | StudentDashboard[] = [];

  isWelcomeModalOpen = false;
  isOpenAssignment = false;
  eventBus = emitter;
  pendingActions: PendingActions[] = [];
  demoMarkingDone = true;
  firstName = "";
  onboardingComplete = true;

  onboardingChecklist: PendingActions[] = [];
  onboardingChecklistTypes = [
    "onboardingDemo",
    "onboardingUploadSubmission",
    "onboardingMarkSubmission",
    "onboardingReturnSubmission",
  ];

  hideUpdateModal = false;

  handleClickAssignment() {
    this.isOpenAssignment = true;
  }

  showNoListMessage = false;

  hideModal = false;

  get assignmentState() {
    return this.$store.state.assignment.selectedAssignment;
  }

  unmounted() {
    const storageKey = "ShowOnboardingView";
    localStorage.removeItem(storageKey);
  }

  async mounted() {
    this.loadingDashboard = true;
    const teacherData = localStorage.getItem("teacher");
    const hideDashboardModal = localStorage.getItem("HIDE_DASHBOARD_MODAL");

    if (hideDashboardModal) {
      this.hideModal = true;
    }

    if (teacherData) {
      this.firstName = JSON.parse(teacherData).profile.firstName;
    }
    this.isTeacher =
      this.$route.path === "/dashboard" || this.$route.path === "/dashboard/";

    this.eventBus.on("RELOAD_DASHBOARD", () => {
      location.reload();
    });

    this.eventBus.on("HIDE_DASHBOARD_MODAL", (flag: any) => {
      this.hideModal = flag;
    });

    this.eventBus.on("REFRESH_DASHBOARD_ASSIGNMENTS", async () => {
      this.loadingDashboard = true;
      await this.getTeachersClasses();
      await this.getAssignmentData();
      this.loadingDashboard = false;
    });

    await this.getPendingActions();
    this.handleOpenWelcomeModal();

    await this.getTeachersClasses();
    await this.getAssignmentData();

    this.eventBus.on("UPDATE_MY_ACCOUNT", () => {
      const updatedTeacherData = localStorage.getItem("teacher");
      if (updatedTeacherData) {
        this.firstName = JSON.parse(updatedTeacherData).profile.firstName;
      }
    });

    this.loadingDashboard = false;
  }

  async getPendingActions() {
    try {
      const authenticatedAs = localStorage.getItem("authenticatedAs");

      const response: AxiosResponse = await axios.get(
        `/session?role=${authenticatedAs}`
      );
      if (response.data.ok) {
        const pendingActions = response.data.data.pendingActions;
        if (pendingActions && pendingActions.length > 0) {
          this.pendingActions = pendingActions;
          this.handleGetOnboardingChecklist(pendingActions);
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      console.error;
    }
  }

  handleGetOnboardingChecklist(pendingActions: PendingActions[]) {
    const onboardingChecklist: PendingActions[] = [];
    pendingActions.forEach((item: PendingActions) => {
      if (this.onboardingChecklistTypes.includes(item.type)) {
        onboardingChecklist.push(item);

        if (item.type === "onboardingDemo") {
          this.demoMarkingDone = false;
        }

        if (item.status !== "fulfilled") {
          this.onboardingComplete = false;
        }
      }
    });
    this.onboardingChecklist = onboardingChecklist;
  }

  async getTeachersClasses() {
    const params: { teacher: string; code?: string } = {
      teacher: getTeacherUuid(),
    };
    await this.$store.dispatch(setClassess, params);
  }

  async getAssignmentData() {
    try {
      const res = await this.dashboardService.getTeacherDashboard();
      if (res.data.ok) {
        this.assignments = res.data.data;
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingDashboard = false;
    }
  }

  handleOpenWelcomeModal() {
    let modalOpen = false;

    const welcomeModalOpened = localStorage.getItem("welcomeModalOpened");

    const markedSubmissions = this.pendingActions?.filter(
      (item: PendingActions) =>
        (item.type === "onboardingDemo" ||
          item.type === "onboardingMarkSubmission") &&
        item.status !== "fulfilled"
    );

    if (markedSubmissions.length === 2 && !welcomeModalOpened) {
      modalOpen = true;
    } else {
      modalOpen = false;
    }

    this.isWelcomeModalOpen = modalOpen;
  }

  handleSubmissionModal() {
    this.submissionModalOpen = !this.submissionModalOpen;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
