import { RouteRecordRaw } from "vue-router";
const UserNotificationsModule = () => import("./UserNotificationsModule.vue");

const moduleRoute: RouteRecordRaw = {
  path: "/user-notifications",
  component: UserNotificationsModule,
  props: true,
  children: [
    {
      path: "",
      name: "User Notifications View",
      component: () => import("./Views/UserNotificationsView.vue"),
      meta: {
        title: "User Notifications",
        restriction: ["super admin"],
      },
    },
    {
      path: "/user-notifications/create",
      name: "User Notification Create",
      component: () => import("./Views/UserNotificationsCreate.vue"),
      meta: {
        title: "User Notifications Editor",
        restriction: ["super admin"],
      },
    },
    {
      path: "/user-notifications/:id",
      name: "User Notification Edit",
      props: true,
      component: () => import("./Views/UserNotificationsCreate.vue"),
      meta: {
        title: "User Notifications Editor",
        restriction: ["super admin"],
      },
    },
  ],
};

export default moduleRoute;
