<template>
  <div class="w-full h-[200px] relative">
    <div class="h-full" id="froala-editor"></div>
    <div
      v-if="disabled"
      class="absolute w-full h-full bg-gray-400 bg-opacity-40 top-0 z-50 rounded-lg"
    ></div>
  </div>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue, Emit, Watch } from "vue-facing-decorator";
import FroalaEditor from "froala-editor";
import { API_KEY } from "@/config/froala";
import { icons } from "./icons";
import { RouteRecordRaw } from "vue-router";
import { FroalaPresetLink } from "@/components/Froala/type";

@Component({ components: {} })
export default class FroalaEditorComponent extends Vue {
  icons = icons;
  @Prop({
    type: Object,
    default: "",
    required: false,
  })
  config!: object;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Model({
    type: String,
    default: "",
  })
  content!: string;

  @Prop({
    type: String,
    default: "",
  })
  placeholder!: string;

  @Prop({
    type: Array,
    default: [],
  })
  presetLinks!: [];

  editor!: FroalaEditor;

  initEditor() {
    // FroalaEditor.DefineIcon("bold", {
    //   template: "svg",
    //   SVG_CONTENT: icons.bold,
    // });

    FroalaEditor.DefineIcon("clear", { NAME: "remove", SVG_KEY: "remove" });
    FroalaEditor.RegisterCommand("clear", {
      title: "Clear All",
      focus: false,
      undo: true,
      refreshAfterCallback: true,
      callback: function () {
        this.html.set("");
        this.events.focus();
      },
    });

    const editor = new FroalaEditor("div#froala-editor", {
      ...this.config,
      height: "70%",
      attribution: false,
      apiKey: API_KEY,
      key: API_KEY,
      quickInsertEnabled: false,
      charCounterCount: false,
      events: {
        initialized: () => {
          editor.html.set(this.content);
        },
        contentChanged: this.debounce(() => {
          const content = editor.html.get();
          this.checkContent(
            !content.trim() || content === "<p><br></p>",
            content
          );
        }, 300),
      },
      placeholderText: this.placeholder || "Start typing something...",
      linkList: this.presetLinks,
    });
    this.editor = editor;
  }

  debounce<T extends (...args: any[]) => void>(fn: T, delay: number): T {
    let timer!: any;
    return function (this: any, ...args: Parameters<T>) {
      clearTimeout(timer);
      timer = setTimeout(() => fn.apply(this, args), delay);
    } as T;
  }

  @Watch("content")
  contentWatcher(value: string) {
    if (!value) {
      this.editor.html.set("");
    }
  }

  checkContent(isEmpty: boolean, value: string) {
    if (isEmpty) {
      this.content = "";
      return;
    }
    this.content = value;
  }

  mounted() {
    this.initEditor();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.fr-element.fr-view {
  a {
    text-decoration: underline;
  }
}
</style>
