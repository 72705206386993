<script lang="ts">
import Textarea from "primevue/textarea";
import { Vue, Component, Model, Prop } from "vue-facing-decorator";

@Component({ components: { Textarea } })
export default class FormTextareaInput extends Vue {
  @Model({
    type: String,
    default: "",
  })
  value!: string;

  @Prop({
    type: String,
    default: "Input text",
  })
  placeholder!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  @Prop({
    type: String,
    default: "input",
  })
  name!: string;

  @Prop({
    type: Number,
    default: 5,
  })
  rows!: number;

  @Prop({
    type: Number,
    default: 30,
  })
  cols!: number;

  @Prop({
    type: Boolean,
    default: true,
  })
  autoResize!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  background!: boolean;

  @Prop({
    type: String,
  })
  customClass!: string;
}
</script>
<template>
  <Textarea
    v-model="value"
    :placeholder="placeholder"
    :autoResize="autoResize"
    :rows="rows"
    :cols="cols"
    :readonly="readonly"
    :disabled="disabled"
    :class="[
      'w-full text-flohh-text-body font-flohh-font-medium',
      {
        'p-invalid': invalid,
        'bg-flohh-neutral-95': background,
      },
      customClass,
    ]"
  />
</template>
