import { Block } from "./type";

export const FIRST_PAGE_MARGIN_TOP = 260;
export const OTHER_PAGE_MARGIN_TOP = 80;
export const FIRST_PAGE_DEFAULT_REMAINING_SPACE = 501;
export const OTHER_PAGE_DEFAULT_REMAINING_SPACE = 681;
export const PER_BLOCK_SPACING = 30;
export const ITEM_VERTICAL_SPACING = 25;

export const COVERSHEET_BLOCKS: Record<string, Block> = {
  line: {
    type: "line",
    name: "Text & Lines Block",
    itemVerticalSpacing: ITEM_VERTICAL_SPACING,
    numberOfItems: 3,
    heading: "",
  },
  space: {
    type: "space",
    name: "Space Block",
    itemVerticalSpacing: ITEM_VERTICAL_SPACING,
    numberOfItems: 1,
    heading: "",
  },
  //   checkbox: {
  //     type: "checkbox",
  //     name: "Checkbox Block",
  //     itemVerticalSpacing: 25,
  //     numberOfItems: 0,
  //     heading: "",
  //     itemSpecs: [
  //       {
  //         type: "line",
  //         text: "",
  //       },
  //       {
  //         type: "line",
  //         text: "",
  //       },
  //       {
  //         type: "line",
  //         text: "",
  //       },
  //     ],
  //   },
};
