<template>
  <div class="px-2 flex gap-10 mb-5">
    <div class="flex gap-2 items-center">
      <FormRadioButton
        v-model="selectedUploadMethod"
        name="fileUpload"
        inputId="fileUpload"
        :value="0"
      />
      <label
        for="fileUpload"
        class="font-flohh-font-medium text-flohh-text-body cursor-pointer"
        :class="selectedUploadMethod === 0 ? 'text-flohh-primary-pink' : ''"
        >File Upload</label
      >
    </div>
    <div class="flex gap-2 items-center">
      <FormRadioButton
        v-model="selectedUploadMethod"
        name="googleDriveUpload"
        inputId="googleDriveUpload"
        :value="1"
      />
      <label
        for="googleDriveUpload"
        class="font-flohh-font-medium text-flohh-text-body cursor-pointer"
        :class="selectedUploadMethod === 1 ? 'text-flohh-primary-pink' : ''"
        >Google Drive</label
      >
    </div>
  </div>
  <div>
    <DefaultFileUploader
      v-if="selectedUploadMethod === 0"
      v-model:selectedFiles="selectedFiles"
      :fileSizeLimit="32"
      acceptedFiles=".pdf,.docx,.doc"
      supportedFileTypes="Supported file types: PDF & Microsoft Word"
      :progress="uploadProgress"
      @onRemoveFile="handleRemoveFile"
    /><GoogleDriveFileUploader
      v-else-if="selectedUploadMethod === 1"
      v-model:selectedFiles="selectedFiles"
      :fileSizeLimit="32"
      acceptedFiles="application/pdf,application/vnd.google-apps.document"
      supportedFileTypes="Supported file types: PDF & Google Doc"
      :progress="uploadProgress"
      @onRemoveFile="handleRemoveFile"
    />
  </div>
  <!-- <ErrorSpan v-if="fileError">{{ fileError }}</ErrorSpan> -->
</template>

<script lang="ts">
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import FormRadioButton from "@/components/Layout/Forms/FormRadioButton.vue";
import DefaultFileUploader from "@/components/utilities/DefaultFileUploader.vue";
import GoogleDriveFileUploader from "@/components/utilities/GoogleDriveFileUploader.vue";
import ErrorSpan from "@/components/utilities/ErrorSpan.vue";

import { Vue, Component, Watch } from "vue-facing-decorator";
@Component({
  components: {
    AppTypographyText,
    FormRadioButton,
    DefaultFileUploader,
    GoogleDriveFileUploader,
    ErrorSpan,
  },
})
export default class AssignmentUploader extends Vue {
  //
  selectedUploadMethod = 0;
  uploadProgress = 0;
  selectedFiles!: File[];

  @Watch("selectedFiles")
  selectedFilesWatcher(value: File[]) {
    this.$emit("onSelect", value);
  }

  handleRemoveFile() {
    //
  }
}
</script>

<style scoped></style>
