import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FormErrorSpan = _resolveComponent("FormErrorSpan")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Dropdown, {
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      options: _ctx.countries,
      optionLabel: "name",
      optionValue: "iso2",
      filter: true,
      virtualScrollerOptions: { itemSize: 46 },
      placeholder: _ctx.isFetching ? 'Fetching Countries...' : 'Select a Country',
      filterPlaceholder: "Search country",
      class: _normalizeClass(['w-full', { 'p-invalid': _ctx.error && _ctx.required }]),
      disabled: _ctx.isFetching
    }, null, 8, ["modelValue", "options", "placeholder", "class", "disabled"]),
    (_ctx.error)
      ? (_openBlock(), _createBlock(_component_FormErrorSpan, { key: 0 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.error), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}