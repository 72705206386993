import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_2 = { class: "text-flohh-text-subtitle" }
const _hoisted_3 = {
  key: 0,
  class: "text-flohh-text-subtitle"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 1,
  class: "text-flohh-text-subtitle"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "flex items-center gap-2" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 0,
  class: "text-flohh-text-subtitle"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "flex gap-3" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = { class: "text-flohh-text-subtitle" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { class: "flex items-center gap-2" }
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = {
  key: 0,
  class: "text-flohh-text-subtitle"
}
const _hoisted_21 = {
  key: 1,
  class: "text-flohh-text-subtitle"
}
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "flex gap-3" }
const _hoisted_24 = ["onClick"]
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = ["onClick"]
const _hoisted_27 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Banner = _resolveComponent("Banner")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (!_ctx.routesExcluded.includes(_ctx.routeName))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Banner, {
          show: _ctx.getStartedBannerShow,
          onOnHide: _ctx.handleHideGetStartedBanner
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_2, [
              _createTextVNode(" New around here? Learn how to get the most out of Flohh with our "),
              _createElementVNode("button", {
                class: "underline hover:text-black",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleOpenQuickStartGuide && _ctx.handleOpenQuickStartGuide(...args)))
              }, " quick-start guide"),
              _createTextVNode(". ")
            ])
          ]),
          _: 1
        }, 8, ["show", "onOnHide"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bulkUploads, (item, index) => {
          return (_openBlock(), _createBlock(_component_Banner, {
            key: index,
            show: !_ctx.getBulkUploadBanner(item.data.assignment),
            onOnHide: () => _ctx.handleHideBulkUploadBanner(item.data.assignment)
          }, {
            default: _withCtx(() => [
              (item.data.status === 'pending')
                ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
                    _createTextVNode(" You have pending bulk upload for "),
                    _createElementVNode("button", {
                      class: "underline hover:text-black",
                      onClick: () => _ctx.handleGoToSplittingPage(item.data.assignment)
                    }, _toDisplayString(item.data.assignmentName), 9, _hoisted_4),
                    _createTextVNode(". ")
                  ]))
                : (_openBlock(), _createElementBlock("p", _hoisted_5, [
                    _createTextVNode(" Processing bulk upload for "),
                    _createElementVNode("button", {
                      class: "underline hover:text-black",
                      onClick: () => _ctx.handleGoToSplittingPage(item.data.assignment)
                    }, _toDisplayString(item.data.assignmentName), 9, _hoisted_6),
                    _createTextVNode(". ")
                  ]))
            ]),
            _: 2
          }, 1032, ["show", "onOnHide"]))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coversheets, (item) => {
          return _withDirectives((_openBlock(), _createElementBlock("div", {
            key: item.uuid,
            class: "flex items-center justify-between px-4 py-4 mx-4 mt-4 rounded-lg bg-flohh-secondary-blue"
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", {
                innerHTML: _ctx.icons.infoCircleBlack
              }, null, 8, _hoisted_8),
              (item.status === 'fulfilled')
                ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
                    _createTextVNode(" Your worksheet for " + _toDisplayString(item.description.split("assignment")[1].trimStart()) + " is ready to ", 1),
                    _createElementVNode("button", {
                      class: "underline hover:text-black",
                      onClick: () => _ctx.handleDownloadPDF(item.data.media)
                    }, " download", 8, _hoisted_10),
                    _createTextVNode(". ")
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_11, [
              (item.status === 'fulfilled')
                ? _withDirectives((_openBlock(), _createElementBlock("button", {
                    key: 0,
                    onClick: ($event: any) => (_ctx.removeBanner(item.uuid))
                  }, [
                    _createElementVNode("span", {
                      innerHTML: _ctx.icons.trashBlack
                    }, null, 8, _hoisted_13)
                  ], 8, _hoisted_12)), [
                    [
                      _directive_tooltip,
                      { value: 'Remove banner' },
                      void 0,
                      { left: true }
                    ]
                  ])
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                onClick: ($event: any) => (_ctx.handleHideCoversheetBanner(item.uuid))
              }, [
                _createElementVNode("span", {
                  innerHTML: _ctx.icons.closeBlack
                }, null, 8, _hoisted_15)
              ], 8, _hoisted_14)
            ])
          ])), [
            [_vShow, !_ctx.getCoversheetBanner(item.uuid)]
          ])
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.announcements, (item) => {
          return (_openBlock(), _createBlock(_component_Banner, {
            key: item.uuid,
            show: !_ctx.getAnnouncementBanner(item.uuid),
            allowRemove: true,
            onOnHide: () => _ctx.handleHideAnnouncementBanner(item.uuid),
            onOnRemove: () => _ctx.removeBanner(item.uuid)
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_16, [
                _createElementVNode("span", {
                  innerHTML: item.data.body
                }, null, 8, _hoisted_17)
              ])
            ]),
            _: 2
          }, 1032, ["show", "onOnHide", "onOnRemove"]))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pdfDocuments, (item) => {
          return _withDirectives((_openBlock(), _createElementBlock("div", {
            key: item.uuid,
            class: "flex items-center justify-between px-4 py-4 mx-4 mt-4 rounded-lg bg-flohh-secondary-blue"
          }, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("span", {
                innerHTML: _ctx.icons.infoCircleBlack
              }, null, 8, _hoisted_19),
              (item.status === 'pending')
                ? (_openBlock(), _createElementBlock("p", _hoisted_20, " The combined submissions file is now processing for download. "))
                : (_openBlock(), _createElementBlock("p", _hoisted_21, [
                    _createTextVNode(" The combined submissions file is ready for download. "),
                    _createElementVNode("button", {
                      class: "underline hover:text-black",
                      onClick: () => _ctx.handleDownloadPDF(item.data.mediaResult)
                    }, " Download now", 8, _hoisted_22),
                    _createTextVNode(". ")
                  ]))
            ]),
            _createElementVNode("div", _hoisted_23, [
              (item.status === 'fulfilled')
                ? _withDirectives((_openBlock(), _createElementBlock("button", {
                    key: 0,
                    onClick: ($event: any) => (_ctx.removeBanner(item.uuid))
                  }, [
                    _createElementVNode("span", {
                      innerHTML: _ctx.icons.trashBlack
                    }, null, 8, _hoisted_25)
                  ], 8, _hoisted_24)), [
                    [
                      _directive_tooltip,
                      { value: 'Remove banner' },
                      void 0,
                      { left: true }
                    ]
                  ])
                : _createCommentVNode("", true),
              _withDirectives((_openBlock(), _createElementBlock("button", {
                onClick: ($event: any) => (_ctx.handleHidePDFDownload(item.uuid))
              }, [
                _createElementVNode("span", {
                  innerHTML: _ctx.icons.closeBlack
                }, null, 8, _hoisted_27)
              ], 8, _hoisted_26)), [
                [
                  _directive_tooltip,
                  { value: 'Hide banner' },
                  void 0,
                  { left: true }
                ]
              ])
            ])
          ])), [
            [_vShow, !_ctx.getPDFDownloadsBanner(item.uuid)]
          ])
        }), 128))
      ]))
    : _createCommentVNode("", true)
}