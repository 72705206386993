<script lang="ts">
import Dropdown from "primevue/dropdown";
import { Vue, Component, Model, Prop, Watch } from "vue-facing-decorator";
import { Countries } from "@/utils/coutries/type";
import TeacherService from "@/services/TeacherService";
import FormErrorSpan from "@/components/Layout/Forms/FormErrorSpan.vue";

@Component({ components: { Dropdown, FormErrorSpan } })
export default class SelectCountry extends Vue {
  private teacherService: TeacherService = new TeacherService();
  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Model({
    type: String,
    required: true,
  })
  value!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  required!: boolean;

  @Prop({
    type: String,
    default: false,
  })
  error!: string;

  countries: Countries[] = [];
  isFetching = true;

  @Watch("value")
  valueWatcher(value: string) {
    const selectedCountry = this.countries.find(
      (country: Countries) => country.iso2 === value
    );
    if (selectedCountry) {
      this.$emit("onCountryUpdate", selectedCountry.states);
    }
  }

  async fetchCountries() {
    this.isFetching = true;
    try {
      const response = await this.teacherService.getCountries();
      if (response.data.ok) {
        this.countries = response.data.data;
        if (this.value) {
          // For Countries that is using name
          const selectedCountry = this.countries.find(
            (country: Countries) =>
              country.iso2 === this.value || country.name === this.value
          );
          if (selectedCountry) this.value = selectedCountry.iso2;
        }
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isFetching = false;
    }
  }

  mounted() {
    this.fetchCountries();
  }
}
</script>
<template>
  <Dropdown
    v-model="value"
    :options="countries"
    optionLabel="name"
    optionValue="iso2"
    :filter="true"
    :virtualScrollerOptions="{ itemSize: 46 }"
    :placeholder="isFetching ? 'Fetching Countries...' : 'Select a Country'"
    filterPlaceholder="Search country"
    :class="['w-full', { 'p-invalid': error && required }]"
    :disabled="isFetching"
  />
  <FormErrorSpan v-if="error">
    {{ error }}
  </FormErrorSpan>
</template>
