<template>
  <ModalUtility v-model="isOpen" :fullScreen="true" :draggable="false">
    <template #header>
      <div
        class="w-full h-[76px] bg-flohh-primary-pink rounded-t-[4px] sm:py-0 flex flex-col justify-center items-start bg-[url('@/assets/modal-header-bg.png')] bg-repeat !bg-contain z-[1]"
      >
        <div
          class="absolute top-50 right-50 px-4 flex flex-col items-start justify-center w-full"
        >
          <h1
            class="text-flohh-h6 font-flohh-font-bold text-flohh-neutral-20 truncate w-[95%]"
          >
            Worksheet Builder
          </h1>
        </div>
        <div
          class="absolute right-[60px] top-[18px] flex justify-end items-center gap-[8px]"
        >
          <AppButton
            type="submit"
            @click.prevent="handleSaveCoversheet"
            :loading="isSaving"
            :disabled="isSaving"
          >
            <template #icon_left>
              <span
                v-html="icons.checkBlack"
                class="mr-2"
                v-if="!isSaving"
              ></span>
            </template>
            Save
          </AppButton>
        </div>

        <div class="btn-close-wrap absolute top-[10px] right-[10px]">
          <button class="p-2 text-white rounded-full" @click="handleCloseModal">
            <img src="@/assets/close.svg" class="w-4" />
          </button>
        </div>
      </div>
    </template>
    <template #content>
      <CoversheetBuilder
        v-if="coversheetData"
        :coversheetData="coversheetData"
        :className="className"
        :assignmentName="assignmentName"
        :classUuid="classUuid"
        :assignmentUuid="assignmentUuid"
        @onCloseModal="handleCloseModal"
        @onEdited="handleEdited"
        @onSaving="handleIsSaving"
      /> </template
  ></ModalUtility>

  <Dialog
    :visible="isWarningModalOpen"
    modal
    :closable="false"
    :close-on-escape="true"
    :style="{ width: '20vw', minWidth: '300px' }"
    :pt="{
      header: { class: '!py-4 !pl-6' },
      content: { class: '!p-0' },
      footer: { class: '!p-0' },
    }"
  >
    <template #header>
      <h5
        class="text-default text-neutral-gray-500 flex font-bold items-center justify-start"
      >
        <i v-html="icons.trashBlack" class="mr-2"></i>Unsaved Changes
      </h5>
    </template>
    <div class="border-t border-solid border-neutral-gray-500 py-4 px-6">
      <p class="text-sm text-neutral-gray-500">
        Are you sure you want to exit without saving? Any changes you've made
        will be lost.
      </p>
    </div>
    <template #footer>
      <div class="py-2 px-6 flex items-center justify-end">
        <Button
          label="Cancel"
          @click.prevent="isWarningModalOpen = false"
          class="border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
        />
        <Button
          label="Exit"
          @click.prevent="
            () => {
              isOpen = false;
              isWarningModalOpen = false;
            }
          "
          class="border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
        />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { Component, Model, Vue, Prop } from "vue-facing-decorator";
import { icons } from "@/utils/icons";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import CoversheetBuilder from "./CoversheetBuilder.vue";
import emitter from "@/config/emitter";
import { AssignmentCoverSheet } from "@/store/assignment/assignmentTypes";
import Dialog from "primevue/dialog";
import Button from "primevue/button";

@Component({
  components: {
    AppButton,
    ModalUtility,
    CoversheetBuilder,
    Dialog,
    Button,
  },
})
export default class CoverSheetBuilderModal extends Vue {
  icons = icons;
  eventBus = emitter;

  @Model({
    default: false,
    type: Boolean,
  })
  isOpen!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  className!: string;

  @Prop({
    type: String,
    default: "",
  })
  assignmentName!: string;

  @Prop({
    type: String,
    default: "",
  })
  classUuid!: string;

  @Prop({
    type: String,
    default: "",
  })
  assignmentUuid!: string;

  @Prop({
    type: Array,
    default: [],
  })
  coversheetData!: AssignmentCoverSheet;

  isWarningModalOpen = false;
  isSaving = false;
  isEdited = false;

  handleCloseModal() {
    if (this.isEdited) {
      this.isWarningModalOpen = true;
    } else {
      this.isOpen = false;
    }
  }

  handleEdited(value: boolean) {
    this.isEdited = value;
  }

  handleIsSaving(value: boolean) {
    this.isSaving = value;
  }

  handleSaveCoversheet() {
    this.eventBus.emit("SAVE_COVERSHEET");
  }
}
</script>

<style scoped></style>
