<script lang="ts">
import { Vue, Model, Component, Emit, Ref, Prop } from "vue-facing-decorator";
import SelectCountry from "@/components/utilities/SelectCountry.vue";
import SelectState from "@/components/utilities/SelectState.vue";
import UpdatePasswords from "../Credentials/UpdatePasswords.vue";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { EditMyAccount } from "../types";
import { States } from "@/utils/coutries/type";

@Component({
  components: {
    SelectCountry,
    SelectState,
    Button,
    UpdatePasswords,
    InputText,
  },
})
export default class EditMyAccountForm extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  userProfile!: EditMyAccount;

  @Model({
    type: String,
    default: "",
    name: "firstName",
  })
  firstNameModel!: string;

  @Model({
    type: String,
    default: "",
    name: "lastName",
  })
  lastNameModel!: string;

  @Model({
    type: String,
    default: "",
    name: "email",
  })
  emailModel!: string;

  @Model({
    type: String,
    default: "",
    name: "phoneNumber",
  })
  phoneNumberModel!: string;

  @Model({
    type: String,
    default: "",
    name: "name",
  })
  nameModel!: string;

  @Model({
    type: String,
    default: "",
    name: "state",
  })
  stateModel!: string;

  @Model({
    type: String,
    default: "",
    name: "country",
  })
  countryModel!: string;

  @Model({
    type: String,
    default: "",
    name: "currentPassword",
  })
  currentPasswordModel!: string;

  @Model({
    type: String,
    default: "",
    name: "newPassword",
  })
  newPasswordModel!: string;

  @Model({
    type: String,
    default: "",
    name: "confirmPassword",
  })
  confirmPasswordModel!: string;

  @Model({
    type: [Array, String],
    default: () => [],
    name: "profile",
  })
  profileImage!: File[];

  @Model({
    type: String,
    default: "",
    name: "imageUrl",
  })
  imageUrlModel!: string;

  @Emit("onSaveProfile")
  handleSaveProfile() {
    return;
  }

  @Emit("onSaveSchool")
  handleSaveSchool() {
    return;
  }

  @Emit("onSaveCredentials")
  handleSaveCredential() {
    return;
  }

  @Emit("onChangeProfilePicture")
  handleChangeProfilePicture() {
    return;
  }

  @Ref
  profileUploader!: HTMLInputElement;

  isEditProfile = false;
  isEditSchool = false;
  isEditCredential = false;
  states: States[] = [];

  handleClickEditProfile(type: string): void {
    if (type.toLowerCase() === "save") {
      this.handleSaveProfile();
    }
    this.isEditProfile = !this.isEditProfile;
  }

  handleClickEditSchool(type: string) {
    if (type.toLowerCase() === "save") {
      this.handleSaveSchool();
    }
    this.isEditSchool = !this.isEditSchool;
  }

  handleClickEditCredential(type: string): void {
    if (type.toLowerCase() === "save") {
      this.handleSaveCredential();
    }
    this.isEditCredential = !this.isEditCredential;
  }

  onChange() {
    if (this.profileUploader && this.profileUploader.files) {
      this.profileImage = Array.from(this.profileUploader.files);
      const file = this.profileUploader.files[0];

      if (file) {
        // Use FileReader to read the image file and set it as the imageURL
        const reader = new FileReader();
        reader.onload = () => {
          this.imageUrlModel = reader.result as string;
        };
        reader.readAsDataURL(file);
      }
    }
  }

  handleClickUploadProfile() {
    this.profileUploader.click();
  }

  handleCountryUpdate(states: States[]) {
    this.states = states;
  }
}
</script>
<template>
  <div class="max-h-[260px] w-full">
    <div class="flex justify-start w-full mt-5">
      <h4 class="font-semibold">Personal Details</h4>
    </div>
    <div class="flex flex-col px-6 pt-4 pb-6">
      <div
        class="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xxl:grid-cols-2"
      >
        <div class="flex flex-col items-start justify-start col-span-2">
          <p class="mb-1 text-sm font-semibold">Email</p>
          <InputText
            type="email"
            size="small"
            class="w-full cursor-not-allowed"
            name="email"
            :value="emailModel"
            readonly
          />
        </div>
        <div class="flex flex-col items-start justify-start col-span-1">
          <p class="mb-1 text-sm font-semibold">First name</p>
          <InputText
            type="text"
            size="small"
            class="w-full"
            name="firstName"
            v-model="firstNameModel"
          />
        </div>
        <div class="flex flex-col items-start justify-start col-span-1">
          <p class="mb-1 text-sm font-semibold">Last name</p>
          <InputText
            type="text"
            size="small"
            class="w-full"
            name="lastName"
            v-model="lastNameModel"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-start w-full">
      <h4 class="font-semibold">School Details</h4>
    </div>
    <div class="flex flex-col px-6 pt-4 pb-6">
      <div
        class="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xxl:grid-cols-3"
      >
        <div class="flex flex-col items-start justify-start col-span-1">
          <p class="mb-1 text-sm font-semibold">School</p>
          <InputText
            type="text"
            size="small"
            class="w-full"
            name="school_name"
            v-model="nameModel"
          />
        </div>
        <div class="flex flex-col items-start justify-start col-span-1">
          <p class="mb-1 text-sm font-semibold">Country</p>
          <SelectCountry
            v-model="countryModel"
            @onCountryUpdate="handleCountryUpdate"
          />
        </div>
        <div class="flex flex-col items-start justify-start col-span-1">
          <p class="mb-1 text-sm font-semibold">State/province</p>
          <SelectState v-model="stateModel" :states="states" />
        </div>
      </div>
    </div>
    <div class="flex justify-start w-full">
      <h4 class="font-semibold">Login credentials</h4>
    </div>
    <div class="mt-5">
      <UpdatePasswords :userProfile="userProfile" />
    </div>
  </div>
</template>
