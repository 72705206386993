<template>
  <div class="flex w-full">
    <div
      class="bg-white rounded-l-lg my-3 pl-[40px] pr-[33px] py-[47px] relative w-[75%]"
    >
      <MainPanelComponent
        :isEdit="isEdit"
        :progress="uploadProgress"
        :error="error"
        :isSaving="savingAssignment"
        @onTabChange="handleTabChange"
        @onDetailsUpdate="handleTitleAndInstruction"
        @onClickSave="handleClickSaveAssignment(true)"
        @onClickAssign="handleToggleAssignModal(true)"
        @onClickUnassign="saveAssignment(false)"
      />
    </div>
    <div
      class="sticky bg-white w-[25%] h-auto min-h-[calc(100vh_-_80px)] top-[80px] border-x border-solid border-flohh-neutral-85 my-3"
    >
      <SidePanelComponent
        :isEdit="isEdit"
        @onDetailsUpdate="handleMoreDetails"
        :selectedCriteriaSheet="selectedCriteriaSheet"
        :selectedCommentBank="selectedCommentBank"
        :handleSelectedCriteriaSheet="handleSelectedCriteriaSheet"
        :handleSelectedCommentBank="handleSelectedCommentBank"
        :error="error"
        :assignmentUuid="assignmentUuid"
        :handleDetachCommentBank="handleSelectedCommentBank"
        :handleDetachCriteriaSheet="handleSelectedCriteriaSheet"
      />
    </div>
  </div>

  <ModalUtility
    v-model="isAssignModalOpen"
    width="35vw"
    title="Assign to class?"
    @onClose="
      savingAssignment
        ? handleToggleAssignModal(true)
        : handleToggleAssignModal(false)
    "
  >
    <template #content>
      <div class="px-[28px] py-[30px]">
        <div class="flex gap-1 mb-1">
          <AppTypographyText variant="bd" type="body" label="Class name:" />
          <AppTypographyText
            variant="rg"
            type="body"
            :label="
              selectedClassInfo
                ? selectedClassInfo.code
                : sidePanelData.className
            "
          />
        </div>
        <div class="flex gap-1 mb-1">
          <AppTypographyText variant="bd" type="body" label="Subject:" />
          <AppTypographyText
            variant="rg"
            type="body"
            :label="selectedClassInfo ? selectedClassInfo.subject : '-'"
          />
        </div>
        <div class="flex gap-1 mb-1">
          <AppTypographyText
            variant="bd"
            type="body"
            label="Number of Students:"
          />
          <AppTypographyText
            variant="rg"
            type="body"
            :label="
              selectedClassInfo && selectedClassInfo.students
                ? selectedClassInfo.students.length
                : 0
            "
          />
        </div>
        <div class="flex gap-1 mb-5">
          <AppTypographyText variant="bd" type="body" label="Year Level:" />
          <AppTypographyText
            variant="rg"
            type="body"
            :label="selectedClassInfo ? selectedClassInfo.yearLevel : '-'"
          />
        </div>
        <AppTypographyText
          variant="rg"
          type="body"
          label="Students will see the assignment in their dashboard immediately."
        />
      </div>
    </template>
    <template #footer>
      <div class="flex pt-5 border-t border-solid border-flohh-neutral-85">
        <div class="flex-1 flex justify-start items-center">
          <AppButton @click="handleToggleAssignModal(false)" text blackLabel>
            Keep Editing
          </AppButton>
        </div>
        <div class="flex-1 flex justify-end items-center gap-x-4">
          <AppButton
            type="submit"
            @click="handleClickAssignFromModal()"
            :disabled="savingAssignment"
            :loading="savingAssignment"
          >
            <template #icon_left>
              <span v-html="icons.checkBlack"></span>
            </template>
            {{ isEdit ? "Save" : "Assign" }}
          </AppButton>
        </div>
      </div>
    </template>
  </ModalUtility>

  <UnsavedWarningModal
    v-if="$store.state.assignment.warningModal.isOpen"
    :redirectTo="$store.state.assignment.warningModal.redirectTo"
    :modalType="$store.state.assignment.warningModal.modalType"
    @onCloseSaveModal="handleWarningModalState"
    @onLeave="handleLeave"
    @onStay="handleStay"
    @onSaveAndClose="handleSaveAndClose"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import SidePanelComponent from "./AssignmentForm/SidePanelComponent.vue";
import MainPanelComponent from "./AssignmentForm/MainPanelComponent.vue";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import { TitleAndInstructions, MoreDetails, ErrorProps } from "./type";
import MediaService from "@/services/MediaService";
import { InitializeMedia } from "@/utils/initializer";
import { arrayBufferToFile, fileToBase64 } from "@/utils/helper";
import { AxiosProgressEvent, AxiosRequestConfig } from "axios";
import { getPspdfkitLicenseKey } from "@/utils/EnvironmentUtils";
import PSPDFKit from "pspdfkit";
import AssignmentService from "@/services/AssignmentService";
import { ClassData } from "@/store/class/classTypes";
import { AssignmentDetails } from "@/models/Assignment";
import { useToast } from "primevue/usetoast";
import emitter from "@/config/emitter";
import UnsavedWarningModal from "./utilities/UnsavedWarningModal.vue";
import { router } from "@/router/index";
import CriteriaSheetService from "@/services/CriteriaSheetService";
import { IAttachedResource } from "@/models/CriteriaSheet";
import CommentService from "@/services/CommentService";
import { AssignmentData } from "@/store/assignment/assignmentTypes";
import { getCurrentISODateTime } from "@/utils/formatter";
import {
  setSelectedAssignment,
  setWarningModal,
} from "@/store/assignment/assignment-dispatch";

@Component({
  components: {
    AppButton,
    MainPanelComponent,
    SidePanelComponent,
    AppTypographyText,
    ModalUtility,
    UnsavedWarningModal,
  },
})
export default class AssignmentFormComponent extends Vue {
  private mediaService: MediaService = new MediaService();
  private assignmentService: AssignmentService = new AssignmentService();
  private criteriaSheetService = new CriteriaSheetService();
  private commentService = new CommentService();

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  @Prop({
    type: String,
    required: false,
  })
  assignmentUuid!: string;

  toast = useToast();
  PSPDFKIT_LICENSE_KEY = getPspdfkitLicenseKey();
  icons = icons;
  currentTab = 0;
  eventBus = emitter;
  savingAssignment = false;
  defaultTimeStamp = "";

  error: ErrorProps = {
    class: "",
    assignment: "",
    dueTimestamp: "",
    duration: "",
  };

  selectedClassInfo!: ClassData;
  isAssignModalOpen = false;
  uploadProgress = 0;

  mainPanelData: TitleAndInstructions = {
    title: "",
    fileInstruction: null,
    textInstruction: "",
  };

  sidePanelData: MoreDetails = {
    classUuid: "",
    className: "",
    dueTimestamp: "",
    duration: 0,
    timezoneName: "",
  };

  defaultCriteriaSheetUuid = "";
  selectedCriteriaSheet = {
    uuid: "",
    name: "",
  };

  defaultCommentBankUuid = "";
  selectedCommentBank = {
    uuid: "",
    name: "",
  };

  hasCriteriaSheet = false;
  hasCommentBank = false;

  saveAndClose = false;
  saveAndCloseRedirectTo = "";

  handleWarningModalState(
    open = false,
    skip = false,
    redirect = "",
    type = ""
  ) {
    this.$store.dispatch(setWarningModal, {
      isOpen: open,
      skipWarning: skip,
      redirectTo: redirect,
      modalType: type,
    });
  }

  @Watch("savingAssignment")
  creatingAssignmentWatcher(value: boolean) {
    if (value) {
      const fieldsEmpty = setInterval(() => {
        if (
          !this.sidePanelData.classUuid &&
          !this.sidePanelData.dueTimestamp &&
          !this.sidePanelData.duration &&
          !this.mainPanelData.fileInstruction &&
          !this.mainPanelData.textInstruction &&
          !this.mainPanelData.title
        ) {
          this.savingAssignment = false;
          clearInterval(fieldsEmpty);
        }
      }, 500);
    }
  }

  @Watch("mainPanelData.title")
  titleWatcher(newValue: string, oldValue: string) {
    if (oldValue && !newValue && !this.savingAssignment) {
      this.error.assignment = "Assignment name is required";
    } else {
      if (newValue.length < 3 && newValue.length !== 0) {
        this.error.assignment =
          "Assignment name must be at least 3 characters long";
      } else {
        this.error.assignment = "";
      }
    }
  }

  @Watch("sidePanelData.className")
  mainPanelDataWatcher(newValue: string, oldValue: string) {
    if (oldValue && !newValue && !this.savingAssignment) {
      this.error.class =
        "Please input a class name or select a class from the dropdown list above";
    } else {
      this.error.class = "";
    }
  }

  @Watch("sidePanelData.dueTimestamp")
  dueTimestampWatcher(newValue: string, oldValue: string) {
    if (oldValue && !newValue && !this.savingAssignment) {
      this.error.dueTimestamp = "Due date and time is required";
    } else {
      this.error.dueTimestamp = "";
    }
  }

  @Watch("sidePanelData.duration")
  durationWatcher(newValue: string, oldValue: string) {
    if (oldValue && !newValue && !this.savingAssignment) {
      this.error.duration = "Duration is required";
    } else {
      this.error.duration = "";
    }
  }

  assignmentPayload: AssignmentDetails = {
    title: "",
    assignmentType: "wysiwyg",
    class: "",
    className: "",
    subject: "",
    component: "",
    dueDate: "",
    dueTime: "",
    dueTimestamp: "",
    timezoneName: "",
    estimatedTimeToMark: {
      unit: "minutes",
      value: 0,
    },
    visible: false,
  };

  docTypes = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
  ];
  currentDateTime = getCurrentISODateTime();

  resetAllFields() {
    return {
      title: "",
      fileInstruction: null,
      textInstruction: "",
      classUuid: "",
      className: "",
      dueTimestamp: "",
      duration: 0,
      timezoneName: "",
    };
  }

  beforeUnmount() {
    this.$store.dispatch(setSelectedAssignment, this.resetAllFields());
    this.handleWarningModalState(false);
  }

  mounted() {
    router.beforeEach((to, from, next) => {
      if (from.name === "CreateAssignment" || from.name === "EditAssignment") {
        const details = this.$store.state.assignment.selectedAssignmentDetails;
        const skipWarning =
          this.$store.state.assignment.warningModal.skipWarning;

        const isFormEdited = this.handleFormEditedCheck(from.name, details);
        const isRequiredFieldsFilled = this.handleRequiredFieldsCheck(details);

        if (skipWarning || (!isFormEdited && !isRequiredFieldsFilled)) {
          this.handleWarningModalState(false, true);
          next(true);
          return;
        }

        if (isFormEdited && !isRequiredFieldsFilled) {
          this.handleWarningModalState(true, false, to.fullPath, "stay");
          next(false);
          return;
        }

        if (isFormEdited) {
          this.handleWarningModalState(
            true,
            false,
            to.fullPath,
            "save and close"
          );
          next(false);
          return;
        }
      }
      next(true);
    });

    if (this.isEdit) {
      this.handleFetchAttachedCriteriaSheet(this.assignmentUuid);
      this.handleFetchAttachedCommentBank(this.assignmentUuid);
    }
  }

  handleFormEditedCheck(
    page: "CreateAssignment" | "EditAssignment",
    details: Record<string, any>
  ) {
    if (page === "EditAssignment") {
      const selectedAssignment: AssignmentData = structuredClone(
        this.$store.state.assignment.selectedAssignment
      );

      if (selectedAssignment.title !== details.title) {
        return true;
      }

      if (selectedAssignment.assignmentType === "document") {
        if (!details.fileInstruction) {
          return true;
        }
        if (details.fileInstruction.length === 0) {
          return true;
        }
        if (
          selectedAssignment.component.fileName !==
          details.fileInstruction[0].name
        ) {
          return true;
        }
        if (details.title.textInstruction) {
          return true;
        }
      } else {
        if (selectedAssignment.component.content !== details.textInstruction) {
          return true;
        }
      }

      if (selectedAssignment.class.uuid !== details.classUuid) {
        return true;
      }

      if (details.classUuid === "create") {
        return true;
      }

      if (selectedAssignment.dueTimestamp !== details.dueTimestamp) {
        return true;
      }

      if (
        selectedAssignment.estimatedTimeToMark.value.toString() !==
        details.duration
      ) {
        return true;
      }

      const formDetailsState =
        this.$store.state.assignment.selectedAssignmentDetails;

      if (formDetailsState.isCommentBankEdited) {
        return true;
      }

      if (formDetailsState.isCriteriaSheetEdited) {
        return true;
      }
    } else {
      const formDetailsState =
        this.$store.state.assignment.selectedAssignmentDetails;
      if (
        details.classUuid !== "create" ||
        details.className ||
        details.duration ||
        details.textInstruction ||
        details.fileInstruction ||
        details.title ||
        formDetailsState.isCriteriaSheetEdited ||
        formDetailsState.isCommentBankEdited
      ) {
        return true;
      }
    }

    return false;
  }

  handleRequiredFieldsCheck(details: Record<string, any>) {
    if (!details.title) {
      return false;
    }

    if (
      !details.classUuid ||
      (details.classUuid === "create" && !details.className)
    ) {
      return false;
    }

    if (!details.duration) {
      return false;
    }

    return true;
  }

  async handleFetchAttachedCriteriaSheet(assignmentUuid: string) {
    try {
      const response =
        await this.criteriaSheetService.getCriteriaSheetAssignment(
          assignmentUuid
        );
      if (response.data.ok) {
        const responseData = response.data.data;
        if (responseData.length > 0) {
          // One assignment per one criteria sheet, can be changed in the future
          this.hasCriteriaSheet = true;
          this.handleSelectedCriteriaSheet(
            responseData[0].sheetDetails.uuid,
            responseData[0].sheetDetails.name
          );
          this.defaultCriteriaSheetUuid = responseData[0].sheetDetails.uuid;
        } else {
          this.hasCriteriaSheet = false;
        }
      } else {
        throw new Error();
      }
    } catch (err) {
      console.error(err);
    }
  }

  async handleFetchAttachedCommentBank(assignmentUuid: string) {
    try {
      const response = await this.commentService.getCommentBank({
        assignmentUuid,
      });
      if (response.data.ok) {
        const responseData = response.data.data;
        if (responseData.length > 0) {
          // One assignment per one comment bank, can be changed in the future
          this.hasCommentBank = true;
          this.handleSelectedCommentBank(
            responseData[0].uuid,
            responseData[0].name
          );
          this.defaultCommentBankUuid = responseData[0].uuid;
        } else {
          this.hasCommentBank = false;
        }
      } else {
        throw new Error();
      }
    } catch (err) {
      console.error(err);
    }
  }

  handleTitleAndInstruction(value: TitleAndInstructions) {
    this.mainPanelData = value;

    this.$store.dispatch(setSelectedAssignment, {
      ...this.sidePanelData,
      ...value,
    });
  }

  handleMoreDetails(value: MoreDetails) {
    if (!this.defaultTimeStamp) {
      this.defaultTimeStamp = value.dueTimestamp;
    }

    this.sidePanelData = value;

    this.$store.dispatch(setSelectedAssignment, {
      ...this.mainPanelData,
      ...value,
    });
  }

  async handleCreateAssignment() {
    try {
      const response = await this.assignmentService.postAssignment(
        this.assignmentPayload
      );
      if (response.data.ok) {
        if (this.selectedCriteriaSheet.uuid) {
          const isSuccess = this.handleAttachCriteriaSheet(
            response.data.data.uuid,
            this.selectedCriteriaSheet.uuid
          );
          if (!isSuccess) {
            this.showToastMessage(
              "error",
              "Attachment Failed",
              "Failed to attach criteria sheet to the assignment"
            );
          }
        }
        if (this.selectedCommentBank.uuid) {
          const isSuccess = this.handleAttachCommentBank(
            response.data.data.uuid,
            this.selectedCommentBank.uuid
          );
          if (!isSuccess) {
            this.showToastMessage(
              "error",
              "Attachment Failed",
              "Failed to attach comment bank to the assignment"
            );
          }
        }
        this.eventBus.emit("CLEAR_FIELDS");
        this.handleToggleAssignModal(false);
        this.selectedCriteriaSheet = { uuid: "", name: "" };
        this.selectedCommentBank = { uuid: "", name: "" };
        this.showToastMessage(
          "success",
          "Success",
          "Assignment created successfully"
        );
        return {
          classId: response.data.data.class.uuid,
          assignmentId: response.data.data.uuid,
        };
      } else {
        throw new Error();
      }
    } catch (err) {
      console.error(err);
      return {
        classId: "",
        assignmentId: "",
      };
    }
  }

  async handleUpdateAssignment(uuid: string) {
    try {
      const response = await this.assignmentService.updateAssignment(
        uuid,
        this.assignmentPayload
      );
      if (response.data.ok) {
        if (this.selectedCriteriaSheet.uuid && !this.hasCriteriaSheet) {
          const isSuccess = this.handleAttachCriteriaSheet(
            response.data.data.uuid,
            this.selectedCriteriaSheet.uuid
          );
          if (!isSuccess) {
            this.showToastMessage(
              "error",
              "Attachment Failed",
              "Failed to attach criteria sheet to the assignment"
            );
          }
        }
        if (this.selectedCommentBank.uuid && !this.hasCommentBank) {
          const isSuccess = this.handleAttachCommentBank(
            response.data.data.uuid,
            this.selectedCommentBank.uuid
          );
          if (!isSuccess) {
            this.showToastMessage(
              "error",
              "Attachment Failed",
              "Failed to attach comment bank to the assignment"
            );
          }
        }
        this.showToastMessage(
          "success",
          "Success",
          "Assignment updated successfully"
        );
        return response.data.data.class.uuid;
      } else {
        throw new Error();
      }
    } catch (err) {
      console.error(err);
      return "";
    } finally {
      this.savingAssignment = false;
    }
  }

  async handleAttachCriteriaSheet(
    assignmentUuid: string,
    criteriaSheetUuid: string
  ) {
    try {
      const criteriaSheetAttachePayload: IAttachedResource = {
        targetType: "assignment",
        targetId: assignmentUuid,
        resourceType: "criteria sheet",
        resourceId: criteriaSheetUuid,
      };
      const response =
        await this.criteriaSheetService.attachedCriteriaSheetAssignment(
          criteriaSheetAttachePayload
        );
      if (response.data.ok) {
        return true;
      } else {
        throw new Error();
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async handleAttachCommentBank(
    assignmentUuid: string,
    commentBankUuid: string
  ) {
    try {
      const commentBankAttachPayload: IAttachedResource = {
        targetType: "assignment",
        targetId: assignmentUuid,
        resourceType: "comment bank",
        resourceId: commentBankUuid,
      };
      const response = await this.commentService.attachCommentBankAssignment(
        commentBankAttachPayload
      );
      if (response.data.ok) {
        return true;
      } else {
        throw new Error();
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  handleLeave(redirectTo: string) {
    this.handleWarningModalState(false, true);
    this.$router.push({
      path: redirectTo,
    });
  }

  handleStay() {
    this.showToastMessage(
      "error",
      "Fill In Required Fields",
      "Save failed. Fill in empty fields and try again."
    );
    this.handleWarningModalState(false, false);
    this.handleFormValidation();
  }

  async handleSaveAndClose(redirectTo: string) {
    this.saveAndClose = true;
    this.saveAndCloseRedirectTo = redirectTo;
    const isFormValid = this.handleFormValidation();
    if (isFormValid) {
      const classes = this.$store.state.teacherClass.classes;
      this.selectedClassInfo = await classes.find(
        (item: ClassData) => item.uuid === this.sidePanelData.classUuid
      );
      this.saveAssignment(true);
      return;
    }

    this.handleWarningModalState(false, false);
  }

  handleTabChange(tab: number) {
    this.currentTab = tab;
  }

  handleToggleAssignModal(value: boolean) {
    if (value) {
      const isFormValid = this.handleFormValidation();
      if (isFormValid) {
        const classes = this.$store.state.teacherClass.classes;
        this.selectedClassInfo = classes.find(
          (item: ClassData) => item.uuid === this.sidePanelData.classUuid
        );
        this.isAssignModalOpen = value;
      }
    } else {
      this.isAssignModalOpen = value;
    }
  }

  handleFormValidation() {
    const errorClone = {
      class: "",
      assignment: "",
      instruction: "",
      dueTimestamp: "",
      duration: "",
    };
    const details = {
      ...this.mainPanelData,
      ...this.sidePanelData,
    };
    let isFormValid = true;

    if (details.classUuid === "create" && !details.className) {
      errorClone.class = "Class name is required";
      isFormValid = false;
    }

    if (!details.dueTimestamp) {
      errorClone.dueTimestamp = "Due date and time is required";
      isFormValid = false;
    }

    if (!details.duration) {
      errorClone.duration = "Duration is required";
      isFormValid = false;
    }

    if (!details.title) {
      errorClone.assignment = "Assignment name is required";
      isFormValid = false;
    }

    if (details.title.length < 3) {
      errorClone.assignment =
        "Assignment name must be at least 3 characters long";
      isFormValid = false;
    }

    this.error = errorClone;
    return isFormValid;
  }

  handleClickSaveAssignment(visible: boolean) {
    this.saveAndClose = false;
    const isFormValid = this.handleFormValidation();
    if (!isFormValid) {
      return;
    }
    this.saveAssignment(visible);
  }

  async saveAssignment(visible = true) {
    this.handleWarningModalState(false, false);
    try {
      if (!visible) {
        this.saveAndClose = false;
        const isFormValid = this.handleFormValidation();
        if (!isFormValid) {
          return;
        }
      }
      this.savingAssignment = true;
      const details = {
        ...this.mainPanelData,
        ...this.sidePanelData,
      };
      let isWysiwyg = !this.currentTab ? true : false;

      if (details.textInstruction || details.fileInstruction) {
        if (isWysiwyg) {
          this.assignmentPayload.component = details.textInstruction;
        } else {
          if (details.fileInstruction) {
            const mediaResponse = await this.onPostMedia(
              details.fileInstruction
            );
            if (mediaResponse) {
              this.assignmentPayload.assignmentType = "document";
              this.assignmentPayload.component = mediaResponse.accessToken;
            }
          }
        }
      } else {
        if (!this.isEdit) {
          delete this.assignmentPayload.component;
        }
      }

      const splittedTimeStamp = details.dueTimestamp.split("T");
      const dueDate = splittedTimeStamp[0];
      const dueTime = splittedTimeStamp[1].includes("+")
        ? splittedTimeStamp[1].split("+")[0]
        : splittedTimeStamp[1].split("-")[0];

      if (details.classUuid === "create") {
        this.assignmentPayload.className = details.className;
        delete this.assignmentPayload.class;
      } else {
        this.assignmentPayload.class = details.classUuid;
        this.assignmentPayload.className = details.className;
      }

      this.assignmentPayload.title = details.title;
      this.assignmentPayload.subject = this.selectedClassInfo
        ? this.selectedClassInfo.subject
        : "";
      this.assignmentPayload.dueTime = dueTime;
      this.assignmentPayload.dueDate = dueDate;
      this.assignmentPayload.dueTimestamp = details.dueTimestamp;
      this.assignmentPayload.timezoneName = details.timezoneName;
      this.assignmentPayload.estimatedTimeToMark = {
        unit: "minutes",
        value: Number(details.duration),
      };
      this.assignmentPayload.visible = visible;

      let classUuid = "";
      let assignmentUuid = "";
      if (this.isEdit) {
        const uuid = await this.handleUpdateAssignment(this.assignmentUuid);
        classUuid = uuid;
        assignmentUuid = this.assignmentUuid;
      } else {
        const { classId, assignmentId } = await this.handleCreateAssignment();
        classUuid = classId;
        assignmentUuid = assignmentId;
      }

      if (classUuid && assignmentUuid) {
        this.eventBus.emit("EVENT_TRIGGER", "AM001");
        this.handleWarningModalState(false, true);
        if (this.saveAndClose) {
          this.$router.push({
            path: this.saveAndCloseRedirectTo,
          });
        } else {
          this.$router.push({
            name: "Classes",
            params: {
              id: classUuid,
            },
            query: {
              tab: "assignments",
              assignment: assignmentUuid,
            },
          });
        }
      } else {
        this.isAssignModalOpen = false;
        this.savingAssignment = false;
      }
    } catch (err) {
      console.error(err);
    }
  }

  async onPostMedia(files: File[]) {
    try {
      this.uploadProgress = 0;
      // this.loadingMedia = true;
      const config: AxiosRequestConfig = {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          if (progressEvent.total !== undefined) {
            this.uploadProgress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
          }
        },
      };

      let file = structuredClone(files[0]);

      if (this.docTypes.includes(file.type)) {
        const fileBase64 = await fileToBase64(file);

        const pspdfkitResponse = await PSPDFKit.convertToPDF({
          document: `data:application/pdf;base64,${fileBase64}`,
          licenseKey: this.PSPDFKIT_LICENSE_KEY,
          container: ".pdf-preview-container",
        });

        if (pspdfkitResponse) {
          let convertedFile = arrayBufferToFile(
            pspdfkitResponse,
            file.name.replace(/\.[^/.]+$/, ".pdf")
          );

          file = structuredClone(convertedFile);
        }
      }

      const payload = InitializeMedia(file, "assignment");
      const response = await this.mediaService.postMedia(payload, config);
      if (response.data.ok) {
        return response.data.data;
      } else {
        console.error(response.data.message);
      }
      return "";
    } catch (error) {
      console.error(error);
    } finally {
      // this.loadingMedia = false;
    }
  }

  handleClickAssignFromModal() {
    this.saveAndClose = false;
    this.saveAssignment(true);
  }

  handleSelectedCriteriaSheet(uuid: string, name: string) {
    this.selectedCriteriaSheet = {
      uuid,
      name,
    };

    this.bankEditedChecker(this.hasCriteriaSheet, "criteria sheet", uuid);
  }

  handleSelectedCommentBank(uuid: string, name: string) {
    this.selectedCommentBank = {
      uuid,
      name,
    };

    this.bankEditedChecker(this.hasCommentBank, "comment", uuid);
  }

  bankEditedChecker(
    hasBank: boolean,
    bankType: "comment" | "criteria sheet",
    uuid: string
  ) {
    let isEdited = false;
    const formDetailsState = structuredClone(
      this.$store.state.assignment.selectedAssignmentDetails
    );
    if (this.isEdit) {
      if (hasBank && !uuid) {
        if (bankType === "comment") {
          this.hasCommentBank = false;
        } else {
          this.hasCriteriaSheet = false;
        }
        isEdited = false;
      } else if (!hasBank && uuid) {
        isEdited = true;
      }
    } else {
      if (uuid) {
        isEdited = true;
      }
    }

    if (bankType === "comment") {
      formDetailsState.isCommentBankEdited = isEdited;
    } else {
      formDetailsState.isCriteriaSheetEdited = isEdited;
    }
    this.$store.dispatch(setSelectedAssignment, formDetailsState);
  }

  showToastMessage(
    severity: "error" | "success",
    summary: string,
    message: string
  ) {
    this.toast.add({
      severity: severity,
      summary: summary,
      detail: message,
      life: 5000,
    });
  }
}
</script>

<style scoped lang="scss"></style>
./type
