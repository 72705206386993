import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "vue-draggable-handle absolute -top-[5px] -right-[8px]" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "flex gap-5" }
const _hoisted_4 = { class: "text-flohh-text-title font-bold" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "text-default text-neutral-gray-500 flex font-bold items-center justify-start" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "border-t border-solid border-neutral-gray-500 py-4 px-6" }
const _hoisted_10 = { class: "text-sm text-neutral-gray-500" }
const _hoisted_11 = { class: "py-2 px-6 flex items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextAndLinesBlock = _resolveComponent("TextAndLinesBlock")!
  const _component_SpaceBlock = _resolveComponent("SpaceBlock")!
  const _component_GridItem = _resolveComponent("GridItem")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layouts[_ctx.pageIndex], (item, i) => {
      return (_openBlock(), _createBlock(_component_GridItem, {
        key: item.id,
        x: item.x,
        y: item.y,
        w: item.w,
        h: item.h,
        i: item.i,
        class: "p-5 w-full rounded-lg border border-flohh-neutral-85 border-solid bg-white flex flex-col gap-5",
        onMoved: _ctx.handleBlockMoved,
        onMousedown: _ctx.handleStartDrag,
        "drag-allow-from": ".vue-draggable-handle",
        "drag-ignore-from": ".no-drag"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", {
              innerHTML: _ctx.icons.dragIcon,
              class: "text-lg"
            }, null, 8, _hoisted_2)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.blocksBP[item.o.type].name || "Block"), 1),
            _createElementVNode("button", {
              type: "button",
              onClick: 
          () => {
            _ctx.removeBlockID = item.id;
            _ctx.removeBlockSpace =
              item.o.numberOfItems * item.o.itemVerticalSpacing + _ctx.blockSpacing;
            _ctx.removeblockName = _ctx.blocksBP[item.o.type].name || 'Block';
            _ctx.removeBlockOpen = true;
          }
        ,
              class: "hover:scale-110"
            }, [
              _createElementVNode("span", {
                innerHTML: _ctx.icons.trashSmallRedIcon
              }, null, 8, _hoisted_6)
            ], 8, _hoisted_5)
          ]),
          (item.o.type === 'line')
            ? (_openBlock(), _createBlock(_component_TextAndLinesBlock, {
                key: 0,
                blockData: item.o,
                blockIndex: i,
                pageIndex: _ctx.pageIndex,
                pageRemainingSpace: _ctx.pageRemainingSpace,
                onOnBlockContentUpdate: _ctx.handleUpdateBlockContent
              }, null, 8, ["blockData", "blockIndex", "pageIndex", "pageRemainingSpace", "onOnBlockContentUpdate"]))
            : _createCommentVNode("", true),
          (item.o.type === 'space')
            ? (_openBlock(), _createBlock(_component_SpaceBlock, {
                key: 1,
                blockData: item.o,
                blockIndex: i,
                pageIndex: _ctx.pageIndex,
                pageRemainingSpace: _ctx.pageRemainingSpace,
                onOnBlockContentUpdate: _ctx.handleUpdateBlockContent
              }, null, 8, ["blockData", "blockIndex", "pageIndex", "pageRemainingSpace", "onOnBlockContentUpdate"]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["x", "y", "w", "h", "i", "onMoved", "onMousedown"]))
    }), 128)),
    _createVNode(_component_Dialog, {
      visible: _ctx.removeBlockOpen,
      modal: "",
      closable: false,
      "close-on-escape": true,
      style: { width: '20vw', minWidth: '300px' },
      pt: {
      header: { class: '!py-4 !pl-6' },
      content: { class: '!p-0' },
      footer: { class: '!p-0' },
    }
    }, {
      header: _withCtx(() => [
        _createElementVNode("h5", _hoisted_7, [
          _createElementVNode("i", {
            innerHTML: _ctx.icons.trashBlack,
            class: "mr-2"
          }, null, 8, _hoisted_8),
          _createTextVNode("Remove Block ")
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_Button, {
            label: "Cancel",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.removeBlockOpen = false), ["prevent"])),
            class: "border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
          }),
          _createVNode(_component_Button, {
            label: "Remove",
            onClick: _withModifiers(_ctx.handleRemoveBlock, ["prevent"]),
            class: "border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
          }, null, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("p", _hoisted_10, " Are you sure you want to remove the selected " + _toDisplayString(_ctx.removeblockName) + " on page " + _toDisplayString(_ctx.pageIndex + 1) + "? ", 1)
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}